import React, {createContext, useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {AlertVariant} from "../../_snowpack/pkg/@patternfly/react-core.js";
import useRequiredContext from "../../utils/useRequiredContext.js";
import {AlertPanel} from "./AlertPanel.js";
export const AlertContext = createContext(void 0);
export const useAlerts = () => useRequiredContext(AlertContext);
export const AlertProvider = ({children}) => {
  const {t} = useTranslation();
  const [alerts, setAlerts] = useState([]);
  const createId = () => new Date().getTime();
  const hideAlert = (key) => {
    setAlerts((alerts2) => [...alerts2.filter((el) => el.key !== key)]);
  };
  const addAlert = (message, variant = AlertVariant.success, description) => {
    const key = createId();
    setTimeout(() => hideAlert(key), 8e3);
    setAlerts([{key, message, variant, description}, ...alerts]);
  };
  const addError = (message, error) => {
    addAlert(t(message, {
      error: "response" in error ? error.response?.data?.errorMessage || error.response?.data?.error : error
    }), AlertVariant.danger);
  };
  return /* @__PURE__ */ React.createElement(AlertContext.Provider, {
    value: {addAlert, addError}
  }, /* @__PURE__ */ React.createElement(AlertPanel, {
    alerts,
    onCloseAlert: hideAlert
  }), children);
};
