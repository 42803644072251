import React, {useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {AlertVariant} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {useAdminClient, useFetch} from "../../context/auth/AdminClient.js";
import {useAlerts} from "../../components/alert/Alerts.js";
import {
  mapRoles,
  RoleMapping
} from "../../components/role-mapping/RoleMapping.js";
export const ServiceAccount = ({client}) => {
  const {t} = useTranslation("clients");
  const adminClient = useAdminClient();
  const {addAlert, addError} = useAlerts();
  const [hide, setHide] = useState(false);
  const [serviceAccount, setServiceAccount] = useState();
  useFetch(() => adminClient.clients.getServiceAccountUser({
    id: client.id
  }), (serviceAccount2) => setServiceAccount(serviceAccount2), []);
  const loader = async () => {
    const serviceAccount2 = await adminClient.clients.getServiceAccountUser({
      id: client.id
    });
    const id = serviceAccount2.id;
    const assignedRoles = (await adminClient.users.listRealmRoleMappings({id})).map((role) => ({role}));
    const effectiveRoles = (await adminClient.users.listCompositeRealmRoleMappings({id})).map((role) => ({role}));
    const clients = await adminClient.clients.find();
    const clientRoles = (await Promise.all(clients.map(async (client2) => {
      const clientAssignedRoles = (await adminClient.users.listClientRoleMappings({
        id,
        clientUniqueId: client2.id
      })).map((role) => ({role, client: client2}));
      const clientEffectiveRoles = (await adminClient.users.listCompositeClientRoleMappings({
        id,
        clientUniqueId: client2.id
      })).map((role) => ({role, client: client2}));
      return mapRoles(clientAssignedRoles, clientEffectiveRoles, hide);
    }))).flat();
    return [...mapRoles(assignedRoles, effectiveRoles, hide), ...clientRoles];
  };
  const assignRoles = async (rows) => {
    try {
      const realmRoles = rows.filter((row) => row.client === void 0).map((row) => row.role).flat();
      adminClient.users.addRealmRoleMappings({
        id: serviceAccount?.id,
        roles: realmRoles
      });
      await Promise.all(rows.filter((row) => row.client !== void 0).map((row) => adminClient.users.addClientRoleMappings({
        id: serviceAccount?.id,
        clientUniqueId: row.client.id,
        roles: [row.role]
      })));
      addAlert(t("roleMappingUpdatedSuccess"), AlertVariant.success);
    } catch (error) {
      addError("clients:roleMappingUpdatedError", error);
    }
  };
  return serviceAccount ? /* @__PURE__ */ React.createElement(RoleMapping, {
    name: client.clientId,
    id: serviceAccount.id,
    type: "service-account",
    loader,
    save: assignRoles,
    onHideRolesToggle: () => setHide(!hide)
  }) : null;
};
