import React from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {FormGroup, PageSection, Switch} from "../_snowpack/pkg/@patternfly/react-core.js";
import {FormAccess} from "../components/form-access/FormAccess.js";
import {HelpItem} from "../components/help-enabler/HelpItem.js";
import {FormPanel} from "../components/scroll-form/FormPanel.js";
import {Controller, useForm} from "../_snowpack/pkg/react-hook-form.js";
export const RealmSettingsLoginTab = ({
  save,
  realm,
  refresh
}) => {
  const {t} = useTranslation("realm-settings");
  const form = useForm({mode: "onChange"});
  const updateSwitchValue = (onChange, value, name) => {
    save({...realm, [name]: value});
    onChange(value);
    refresh();
  };
  return /* @__PURE__ */ React.createElement(PageSection, {
    variant: "light"
  }, /* @__PURE__ */ React.createElement(FormPanel, {
    className: "kc-login-screen",
    title: "Login screen customization"
  }, /* @__PURE__ */ React.createElement(FormAccess, {
    isHorizontal: true,
    role: "manage-realm"
  }, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("userRegistration"),
    fieldId: "kc-user-reg",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: t("userRegistrationHelpText"),
      fieldLabelId: "realm-settings:userRegistration"
    }),
    hasNoPaddingTop: true
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "registrationAllowed",
    defaultValue: realm.registrationAllowed,
    control: form.control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "kc-user-reg-switch",
      "data-testid": "user-reg-switch",
      name: "registrationAllowed",
      label: t("common:on"),
      labelOff: t("common:off"),
      isChecked: value,
      onChange: (value2) => {
        updateSwitchValue(onChange, value2, "registrationAllowed");
      }
    })
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("forgotPassword"),
    fieldId: "kc-forgot-pw",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "realm-settings:forgotPasswordHelpText",
      fieldLabelId: "realm-settings:forgotPassword"
    }),
    hasNoPaddingTop: true
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "resetPasswordAllowed",
    defaultValue: realm.resetPasswordAllowed,
    control: form.control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "kc-forgot-pw-switch",
      "data-testid": "forgot-pw-switch",
      name: "resetPasswordAllowed",
      label: t("common:on"),
      labelOff: t("common:off"),
      isChecked: value,
      onChange: (value2) => {
        updateSwitchValue(onChange, value2, "resetPasswordAllowed");
      }
    })
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("rememberMe"),
    fieldId: "kc-remember-me",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "realm-settings:rememberMeHelpText",
      fieldLabelId: "realm-settings:rememberMe"
    }),
    hasNoPaddingTop: true
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "rememberMe",
    defaultValue: realm.rememberMe,
    control: form.control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "kc-remember-me-switch",
      "data-testid": "remember-me-switch",
      name: "rememberMe",
      label: t("common:on"),
      labelOff: t("common:off"),
      isChecked: value,
      onChange: (value2) => {
        updateSwitchValue(onChange, value2, "rememberMe");
      }
    })
  })))), /* @__PURE__ */ React.createElement(FormPanel, {
    className: "kc-email-settings",
    title: "Email settings"
  }, /* @__PURE__ */ React.createElement(FormAccess, {
    isHorizontal: true,
    role: "manage-realm"
  }, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("emailAsUsername"),
    fieldId: "kc-email-as-username",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "realm-settings:emailAsUsernameHelpText",
      fieldLabelId: "realm-settings:emailAsUsername"
    }),
    hasNoPaddingTop: true
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "registrationEmailAsUsername",
    defaultValue: realm.registrationEmailAsUsername,
    control: form.control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "kc-email-as-username-switch",
      "data-testid": "email-as-username-switch",
      name: "registrationEmailAsUsername",
      label: t("common:on"),
      labelOff: t("common:off"),
      isChecked: value,
      onChange: (value2) => {
        updateSwitchValue(onChange, value2, "registrationEmailAsUsername");
      }
    })
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("loginWithEmail"),
    fieldId: "kc-login-with-email",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "realm-settings:loginWithEmailHelpText",
      fieldLabelId: "realm-settings:loginWithEmail"
    }),
    hasNoPaddingTop: true
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "loginWithEmailAllowed",
    defaultValue: realm.loginWithEmailAllowed,
    control: form.control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "kc-login-with-email-switch",
      "data-testid": "login-with-email-switch",
      name: "loginWithEmailAllowed",
      label: t("common:on"),
      labelOff: t("common:off"),
      isChecked: value,
      onChange: (value2) => {
        updateSwitchValue(onChange, value2, "loginWithEmailAllowed");
      }
    })
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("duplicateEmails"),
    fieldId: "kc-duplicate-emails",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "realm-settings:duplicateEmailsHelpText",
      fieldLabelId: "realm-settings:duplicateEmails"
    }),
    hasNoPaddingTop: true
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "duplicateEmailsAllowed",
    defaultValue: realm.duplicateEmailsAllowed,
    control: form.control,
    render: ({onChange}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "kc-duplicate-emails-switch",
      "data-testid": "duplicate-emails-switch",
      label: t("common:on"),
      labelOff: t("common:off"),
      name: "duplicateEmailsAllowed",
      isChecked: form.getValues().duplicateEmailsAllowed && !form.getValues().loginWithEmailAllowed && !form.getValues().registrationEmailAsUsername,
      onChange: (value) => {
        updateSwitchValue(onChange, value, "duplicateEmailsAllowed");
      },
      isDisabled: form.getValues().loginWithEmailAllowed || form.getValues().registrationEmailAsUsername
    })
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("verifyEmail"),
    fieldId: "kc-verify-email",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "realm-settings:verifyEmailHelpText",
      fieldLabelId: "realm-settings:verifyEmail"
    }),
    hasNoPaddingTop: true
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "verifyEmail",
    defaultValue: realm.verifyEmail,
    control: form.control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "kc-verify-email-switch",
      "data-testid": "verify-email-switch",
      name: "verifyEmail",
      label: t("common:on"),
      labelOff: t("common:off"),
      isChecked: value,
      onChange: (value2) => {
        updateSwitchValue(onChange, value2, "verifyEmail");
      }
    })
  })))));
};
