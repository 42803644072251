import i18n from "./_snowpack/pkg/i18next.js";
import {initReactI18next} from "./_snowpack/pkg/react-i18next.js";
import common from "./common-messages.js";
import help from "./common-help.js";
import dashboard from "./dashboard/messages.js";
import clients from "./clients/messages.js";
import clientsHelp from "./clients/help.js";
import clientScopes from "./client-scopes/messages.js";
import clientScopesHelp from "./client-scopes/help.js";
import groups from "./groups/messages.js";
import realm from "./realm/messages.js";
import roles from "./realm-roles/messages.js";
import users from "./user/messages.js";
import usersHelp from "./user/help.js";
import sessions from "./sessions/messages.js";
import events from "./events/messages.js";
import realmSettings from "./realm-settings/messages.js";
import realmSettingsHelp from "./realm-settings/help.js";
import authentication from "./authentication/messages.js";
import authenticationHelp from "./authentication/help.js";
import userFederation from "./user-federation/messages.js";
import userFederationHelp from "./user-federation/help.js";
import identityProviders from "./identity-providers/messages.js";
import identityProvidersHelp from "./identity-providers/help.js";
import dynamicLabels from "./components/dynamic/labels.js";
export const DEFAULT_LOCALE = "en";
const initOptions = {
  defaultNS: "common",
  resources: {
    en: {
      ...common,
      ...help,
      ...dashboard,
      ...clients,
      ...clientsHelp,
      ...clientScopes,
      ...clientScopesHelp,
      ...groups,
      ...realm,
      ...roles,
      ...groups,
      ...users,
      ...usersHelp,
      ...sessions,
      ...userFederation,
      ...events,
      ...realmSettings,
      ...realmSettingsHelp,
      ...authentication,
      ...authenticationHelp,
      ...identityProviders,
      ...identityProvidersHelp,
      ...userFederation,
      ...userFederationHelp,
      ...dynamicLabels
    }
  },
  lng: DEFAULT_LOCALE,
  fallbackLng: DEFAULT_LOCALE,
  interpolation: {
    escapeValue: false
  }
};
i18n.use(initReactI18next).init(initOptions);
export default i18n;
