import React from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {Controller, useFormContext} from "../../_snowpack/pkg/react-hook-form.js";
import {FormGroup, NumberInput} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {HelpItem} from "../../components/help-enabler/HelpItem.js";
export const DisplayOrder = () => {
  const {t} = useTranslation("identity-providers");
  const {control} = useFormContext();
  return /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("displayOrder"),
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "identity-providers-help:displayOrder",
      fieldLabelId: "identity-providers:displayOrder"
    }),
    fieldId: "kc-display-order"
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "config.guiOrder",
    control,
    defaultValue: 0,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(NumberInput, {
      value,
      "data-testid": "displayOrder",
      min: 0,
      onMinus: () => onChange(Number.parseInt(value) - 1),
      onChange,
      onPlus: () => onChange(Number.parseInt(value) + 1),
      inputName: "input",
      inputAriaLabel: t("displayOrder"),
      minusBtnAriaLabel: t("common:minus"),
      plusBtnAriaLabel: t("common:plus")
    })
  }));
};
