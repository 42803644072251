import React, {useState} from "../_snowpack/pkg/react.js";
import {KeycloakSpinner} from "../components/keycloak-spinner/KeycloakSpinner.js";
import {useAdminClient, useFetch} from "../context/auth/AdminClient.js";
import {useRealm} from "../context/realm-context/RealmContext.js";
import {KEY_PROVIDER_TYPE} from "../util.js";
import {RealmSettingsTabs} from "./RealmSettingsTabs.js";
const sortByPriority = (components) => {
  const sortedComponents = [...components].sort((a, b) => {
    const priorityA = Number(a.config?.priority);
    const priorityB = Number(b.config?.priority);
    return (!isNaN(priorityB) ? priorityB : 0) - (!isNaN(priorityA) ? priorityA : 0);
  });
  return sortedComponents;
};
export default function RealmSettingsSection() {
  const adminClient = useAdminClient();
  const {realm: realmName} = useRealm();
  const [realm, setRealm] = useState();
  const [realmComponents, setRealmComponents] = useState();
  const [key, setKey] = useState(0);
  const refresh = () => {
    setKey(key + 1);
  };
  useFetch(async () => {
    const realm2 = await adminClient.realms.findOne({realm: realmName});
    const realmComponents2 = await adminClient.components.find({
      type: KEY_PROVIDER_TYPE,
      realm: realmName
    });
    return {realm: realm2, realmComponents: realmComponents2};
  }, ({realm: realm2, realmComponents: realmComponents2}) => {
    setRealmComponents(sortByPriority(realmComponents2));
    setRealm(realm2);
  }, [key]);
  if (!realm || !realmComponents) {
    return /* @__PURE__ */ React.createElement(KeycloakSpinner, null);
  }
  return /* @__PURE__ */ React.createElement(RealmSettingsTabs, {
    realm,
    refresh,
    realmComponents
  });
}
