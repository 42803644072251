export default {
  groups: {
    groups: "Groups",
    groupDetails: "Group details",
    childGroups: "Child groups",
    createGroup: "Create group",
    groupName: "Group name",
    searchForGroups: "Search for groups",
    searchGroups: "Search groups",
    searchGroup: "Search group",
    renameGroup: "Rename group",
    deleteGroup: "Delete group",
    leave: "Leave group",
    usersLeft_one: "{{count}} user left the group",
    usersLeft_other: "{{count}} users left the group",
    usersLeftError: "Could not remove users from the group: {{error}}",
    usersAdded_one: "{{count}} user added to the group",
    usersAdded_other: "{{count}} users added to the group",
    usersAddedError: "Could not add users to the group: {{error}}",
    search: "Search",
    members: "Members",
    searchMembers: "Search members",
    addMember: "Add member",
    includeSubGroups: "Include sub-group users",
    path: "Path",
    moveTo: "Move to",
    moveToGroup: "Move {{group1}} to {{group2}}",
    root: "Root",
    moveHere: "Move here",
    moveGroupEmpty: "No sub groups",
    moveGroupEmptyInstructions: "There are no sub groups, select 'Move here' to move the selected group as a subgroup of this group",
    moveGroupSuccess: "Group moved",
    moveGroupError: "Could not move group {{error}}",
    tableOfGroups: "Table of groups",
    groupsDescription: "Placeholder for groups explanation.",
    groupCreated: "Group created",
    couldNotCreateGroup: "Could not create group {{error}}",
    createAGroup: "Create a group",
    renameAGroup: "Rename group",
    create: "Create",
    rename: "Rename",
    email: "Email",
    lastName: "Last name",
    firstName: "First name",
    membership: "Membership",
    noSearchResults: "No search results",
    noSearchResultsInstructions: "Click on the search bar above to search for groups",
    noGroupsInThisRealm: "No groups in this realm",
    noGroupsInThisRealmInstructions: "You haven't created any groups in this realm. Create a group to get started.",
    noGroupsInThisSubGroup: "No groups in this sub group",
    noGroupsInThisSubGroupInstructions: "You haven't created any groups in this sub group.",
    deleteConfirmTitle_one: "Delete group?",
    deleteConfirmTitle_other: "Delete groups?",
    deleteConfirm_one: "Are you sure you want to delete this group",
    deleteConfirm_other: "Are you sure you want to delete this groups.",
    groupDeleted_one: "Group deleted",
    groupDeleted_other: "Groups deleted",
    groupDeleteError: "Error deleting group {error}",
    attributes: "Attributes",
    groupUpdated: "Group updated",
    groupUpdateError: "Error updating group {error}",
    roleMapping: "Role mapping"
  }
};
