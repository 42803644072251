import axios from "../../_snowpack/pkg/axios.js";
import {createContext, useEffect} from "../../_snowpack/pkg/react.js";
import {useErrorHandler} from "../../_snowpack/pkg/react-error-boundary.js";
import useRequiredContext from "../../utils/useRequiredContext.js";
export const AdminClient = createContext(void 0);
export const useAdminClient = () => useRequiredContext(AdminClient);
export function useFetch(adminClientCall, callback, deps) {
  const adminClient = useAdminClient();
  const onError = useErrorHandler();
  useEffect(() => {
    const source = axios.CancelToken.source();
    adminClient.setConfig({
      requestConfig: {cancelToken: source.token}
    });
    adminClientCall().then((result) => {
      if (!source.token.reason) {
        callback(result);
      }
    }).catch((error) => {
      if (!axios.isCancel(error)) {
        onError(error);
      }
    });
    adminClient.setConfig({
      requestConfig: {cancelToken: void 0}
    });
    return () => {
      source.cancel();
    };
  }, deps);
}
