export { H as HelpIcon, P as PencilAltIcon } from '../common/pencil-alt-icon-6845b176.js';
export { A as AngleRightIcon, a as CaretDownIcon, C as CheckCircleIcon, E as ExclamationCircleIcon, b as ExternalLinkAltIcon, I as InfoCircleIcon, P as PlusIcon, S as SearchIcon } from '../common/plus-icon-4b1aeab3.js';
import { c as createIcon } from '../common/createIcon-8065a13b.js';
export { C as CheckIcon, T as TimesIcon } from '../common/check-icon-fbfa463a.js';
import '../common/index-e2560c44.js';
import '../common/_commonjsHelpers-7446a541.js';

const EyeSlashIconConfig = {
  name: 'EyeSlashIcon',
  height: 512,
  width: 640,
  svgPath: 'M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z',
  yOffset: 0,
  xOffset: 0,
};

const EyeSlashIcon = createIcon(EyeSlashIconConfig);

const EyeIconConfig = {
  name: 'EyeIcon',
  height: 512,
  width: 576,
  svgPath: 'M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z',
  yOffset: 0,
  xOffset: 0,
};

const EyeIcon = createIcon(EyeIconConfig);

const CaretUpIconConfig = {
  name: 'CaretUpIcon',
  height: 512,
  width: 320,
  svgPath: 'M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z',
  yOffset: 0,
  xOffset: 0,
};

const CaretUpIcon = createIcon(CaretUpIconConfig);

const CogIconConfig = {
  name: 'CogIcon',
  height: 512,
  width: 512,
  svgPath: 'M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z',
  yOffset: 0,
  xOffset: 0,
};

const CogIcon = createIcon(CogIconConfig);

const CubeIconConfig = {
  name: 'CubeIcon',
  height: 512,
  width: 512,
  svgPath: 'M239.1 6.3l-208 78c-18.7 7-31.1 25-31.1 45v225.1c0 18.2 10.3 34.8 26.5 42.9l208 104c13.5 6.8 29.4 6.8 42.9 0l208-104c16.3-8.1 26.5-24.8 26.5-42.9V129.3c0-20-12.4-37.9-31.1-44.9l-208-78C262 2.2 250 2.2 239.1 6.3zM256 68.4l192 72v1.1l-192 78-192-78v-1.1l192-72zm32 356V275.5l160-65v133.9l-160 80z',
  yOffset: 0,
  xOffset: 0,
};

const CubeIcon = createIcon(CubeIconConfig);

const CubesIconConfig = {
  name: 'CubesIcon',
  height: 512,
  width: 512,
  svgPath: 'M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z',
  yOffset: 0,
  xOffset: 0,
};

const CubesIcon = createIcon(CubesIconConfig);

const DatabaseIconConfig = {
  name: 'DatabaseIcon',
  height: 512,
  width: 448,
  svgPath: 'M448 73.143v45.714C448 159.143 347.667 192 224 192S0 159.143 0 118.857V73.143C0 32.857 100.333 0 224 0s224 32.857 224 73.143zM448 176v102.857C448 319.143 347.667 352 224 352S0 319.143 0 278.857V176c48.125 33.143 136.208 48.572 224 48.572S399.874 209.143 448 176zm0 160v102.857C448 479.143 347.667 512 224 512S0 479.143 0 438.857V336c48.125 33.143 136.208 48.572 224 48.572S399.874 369.143 448 336z',
  yOffset: 0,
  xOffset: 0,
};

const DatabaseIcon = createIcon(DatabaseIconConfig);

const FacebookSquareIconConfig = {
  name: 'FacebookSquareIcon',
  height: 512,
  width: 448,
  svgPath: 'M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z',
  yOffset: 0,
  xOffset: 0,
};

const FacebookSquareIcon = createIcon(FacebookSquareIconConfig);

const FilterIconConfig = {
  name: 'FilterIcon',
  height: 512,
  width: 512,
  svgPath: 'M487.976 0H24.028C2.71 0-8.047 25.866 7.058 40.971L192 225.941V432c0 7.831 3.821 15.17 10.237 19.662l80 55.98C298.02 518.69 320 507.493 320 487.98V225.941l184.947-184.97C520.021 25.896 509.338 0 487.976 0z',
  yOffset: 0,
  xOffset: 0,
};

const FilterIcon = createIcon(FilterIconConfig);

const GithubIconConfig = {
  name: 'GithubIcon',
  height: 512,
  width: 496,
  svgPath: 'M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z',
  yOffset: 0,
  xOffset: 0,
};

const GithubIcon = createIcon(GithubIconConfig);

const GitlabIconConfig = {
  name: 'GitlabIcon',
  height: 512,
  width: 512,
  svgPath: 'M105.2 24.9c-3.1-8.9-15.7-8.9-18.9 0L29.8 199.7h132c-.1 0-56.6-174.8-56.6-174.8zM.9 287.7c-2.6 8 .3 16.9 7.1 22l247.9 184-226.2-294zm160.8-88l94.3 294 94.3-294zm349.4 88l-28.8-88-226.3 294 247.9-184c6.9-5.1 9.7-14 7.2-22zM425.7 24.9c-3.1-8.9-15.7-8.9-18.9 0l-56.6 174.8h132z',
  yOffset: 0,
  xOffset: 0,
};

const GitlabIcon = createIcon(GitlabIconConfig);

const GoogleIconConfig = {
  name: 'GoogleIcon',
  height: 512,
  width: 488,
  svgPath: 'M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z',
  yOffset: 0,
  xOffset: 0,
};

const GoogleIcon = createIcon(GoogleIconConfig);

const LinkedinIconConfig = {
  name: 'LinkedinIcon',
  height: 512,
  width: 448,
  svgPath: 'M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z',
  yOffset: 0,
  xOffset: 0,
};

const LinkedinIcon = createIcon(LinkedinIconConfig);

const MinusCircleIconConfig = {
  name: 'MinusCircleIcon',
  height: 512,
  width: 512,
  svgPath: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z',
  yOffset: 0,
  xOffset: 0,
};

const MinusCircleIcon = createIcon(MinusCircleIconConfig);

const OpenshiftIconConfig = {
  name: 'OpenshiftIcon',
  height: 100,
  width: 100,
  svgPath: 'M145.7,45.3l-16.1,5.8c0.2,2.6,0.6,5.1,1.3,7.6l15.3-5.6C145.7,50.6,145.5,47.9,145.7,45.3M216.7,27.5c-1.1-2.3-2.4-4.5-3.9-6.6l-16.1,5.8c1.9,1.9,3.4,4.1,4.7,6.4L216.7,27.5zM181.4,23c3.3,1.6,6.2,3.7,8.7,6.2l16.1-5.8c-4.4-6.2-10.5-11.5-17.9-14.9c-22.9-10.7-50.3-0.7-61,22.2c-3.5,7.4-4.8,15.3-4.1,23l16.1-5.8c0.3-3.5,1.1-7,2.7-10.3C148.7,22.5,166.4,16,181.4,23M131.9,58.4l-15.3,5.6c1.4,5.6,3.8,10.8,7.2,15.5l16-5.8C135.8,69.4,133,64.1,131.9,58.4M198.5,52.3c-0.3,3.5-1.1,7-2.7,10.3C188.8,77.5,171,84,156.1,77c-3.3-1.6-6.3-3.7-8.7-6.2l-16,5.8c4.4,6.2,10.5,11.5,17.9,14.9c22.9,10.7,50.3,0.7,61-22.2c3.5-7.4,4.7-15.3,4.1-22.9L198.5,52.3zM202.4,32.7l-15.3,5.6c2.8,5.1,4.2,10.9,3.7,16.8l16-5.8C206.5,43.5,204.9,37.9,202.4,32.7',
  yOffset: 0,
  xOffset: 116,
};

const OpenshiftIcon = createIcon(OpenshiftIconConfig);

const PlusCircleIconConfig = {
  name: 'PlusCircleIcon',
  height: 512,
  width: 512,
  svgPath: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z',
  yOffset: 0,
  xOffset: 0,
};

const PlusCircleIcon = createIcon(PlusCircleIconConfig);

const QuestionCircleIconConfig = {
  name: 'QuestionCircleIcon',
  height: 512,
  width: 512,
  svgPath: 'M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z',
  yOffset: 0,
  xOffset: 0,
};

const QuestionCircleIcon = createIcon(QuestionCircleIconConfig);

const StackOverflowIconConfig = {
  name: 'StackOverflowIcon',
  height: 512,
  width: 384,
  svgPath: 'M290.7 311L95 269.7 86.8 309l195.7 41zm51-87L188.2 95.7l-25.5 30.8 153.5 128.3zm-31.2 39.7L129.2 179l-16.7 36.5L293.7 300zM262 32l-32 24 119.3 160.3 32-24zm20.5 328h-200v39.7h200zm39.7 80H42.7V320h-40v160h359.5V320h-40z',
  yOffset: 0,
  xOffset: 0,
};

const StackOverflowIcon = createIcon(StackOverflowIconConfig);

const TableIconConfig = {
  name: 'TableIcon',
  height: 512,
  width: 512,
  svgPath: 'M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z',
  yOffset: 0,
  xOffset: 0,
};

const TableIcon = createIcon(TableIconConfig);

const TrashIconConfig = {
  name: 'TrashIcon',
  height: 512,
  width: 448,
  svgPath: 'M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z',
  yOffset: 0,
  xOffset: 0,
};

const TrashIcon = createIcon(TrashIconConfig);

const TwitterIconConfig = {
  name: 'TwitterIcon',
  height: 512,
  width: 512,
  svgPath: 'M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z',
  yOffset: 0,
  xOffset: 0,
};

const TwitterIcon = createIcon(TwitterIconConfig);

const WarningTriangleIconConfig = {
  name: 'WarningTriangleIcon',
  height: 1024,
  width: 1088,
  svgPath: 'M1057.10141,663.5 L845.101405,215.4 C787.101405,71.8 665.401405,0 542.901405,0 C420.201405,0 296.701405,71.9 235.001405,215.6 L31.7014051,648.5 C10.4014051,700 -0.0985948775,752.3 0.000697596367,800.8 C0.301405123,924.8 70.2014051,1024 209.101405,1024 L868.401405,1024 C1005.80141,1024 1087.70141,918.6 1088.00215,795.5 C1088.10141,752.4 1078.20141,707.2 1057.10141,663.5 Z M959.401405,800.3 C958.701405,822.9 952.901405,843.5 942.601405,859.7 C926.801405,884.6 902.601405,896.7 868.301405,896.7 L209.101405,896.7 C191.201405,896.7 176.601405,893.8 165.401405,888.2 C157.301405,884 150.801405,878.4 145.401405,870.3 C135.101405,855 129.101405,832 128.401405,805.6 C127.601405,772.8 134.901405,736.5 149.401405,700.5 L353.001405,266.7 C363.201405,242.9 376.101405,221.5 391.101405,203.2 C404.801405,186.6 420.301405,172.4 437.401405,161.1 C469.201405,139.9 505.701405,128.8 542.901405,128.8 C579.701405,128.8 615.401405,139.8 646.001405,160.5 C662.401405,171.6 677.101405,185.4 690.101405,201.6 C704.501405,219.6 716.401405,240.6 725.901405,264 L940.901405,718.9 L941.101405,719.3 L941.301405,719.7 C953.901405,746 960.201405,773.9 959.401405,800.3 Z M586.601405,832 L501.301405,832 C489.501405,831.8 480.201405,821.5 480.001405,808.7 L480.001405,727.3 C480.201405,714.5 489.601405,704.3 501.301405,704 L586.601405,704 C598.401405,704.2 607.701405,714.5 607.901405,727.3 L607.901405,808.7 L608.001405,808.7 C607.701405,821.5 598.301405,831.8 586.601405,832 M639.901405,290.7 L613.201405,610.4 C611.801405,626.9 598.001405,640 581.301405,640 L506.601405,640 C490.001405,640 476.101405,627.2 474.701405,610.7 L448.101405,291 C446.501405,272.3 461.301405,256.3 480.001405,256.3 L608.001405,256 C626.701405,256 641.401405,272 639.901405,290.7',
  yOffset: 0,
  xOffset: 0,
};

const WarningTriangleIcon = createIcon(WarningTriangleIconConfig);

export { CaretUpIcon, CogIcon, CubeIcon, CubesIcon, DatabaseIcon, EyeIcon, EyeSlashIcon, FacebookSquareIcon, FilterIcon, GithubIcon, GitlabIcon, GoogleIcon, LinkedinIcon, MinusCircleIcon, OpenshiftIcon, PlusCircleIcon, QuestionCircleIcon, StackOverflowIcon, TableIcon, TrashIcon, TwitterIcon, WarningTriangleIcon };
