import {cloneDeep} from "./_snowpack/pkg/lodash-es.js";
import {useTranslation} from "./_snowpack/pkg/react-i18next.js";
import FileSaver from "./_snowpack/pkg/file-saver.js";
import {unflatten, flatten} from "./_snowpack/pkg/flat.js";
import {
  arrayToAttributes,
  attributesToArray
} from "./components/attribute-form/attribute-convert.js";
import {
  convertToMultiline,
  toValue
} from "./components/multi-line-input/multi-line-convert.js";
export const sortProviders = (providers) => {
  return [...new Map(Object.entries(providers).sort(sortProvider)).keys()];
};
const sortProvider = (a, b) => {
  let s1, s2;
  if (a[1].order !== b[1].order) {
    s1 = b[1].order;
    s2 = a[1].order;
  } else {
    s1 = a[0];
    s2 = b[0];
  }
  if (s1 < s2) {
    return -1;
  } else if (s1 > s2) {
    return 1;
  } else {
    return 0;
  }
};
export const exportClient = (client) => {
  const clientCopy = cloneDeep(client);
  delete clientCopy.id;
  if (clientCopy.protocolMappers) {
    for (let i = 0; i < clientCopy.protocolMappers.length; i++) {
      delete clientCopy.protocolMappers[i].id;
    }
  }
  FileSaver.saveAs(new Blob([prettyPrintJSON(clientCopy)], {
    type: "application/json"
  }), clientCopy.clientId + ".json");
};
export const toUpperCase = (name) => name.charAt(0).toUpperCase() + name.slice(1);
const isAttributesObject = (value) => {
  return Object.values(value).filter((value2) => Array.isArray(value2) && value2.length === 1).length !== 0;
};
const isAttributeArray = (value) => {
  if (!Array.isArray(value)) {
    return false;
  }
  return value.filter((e) => e.key && e.value).length !== 0;
};
const isEmpty = (obj) => Object.keys(obj).length === 0;
export const convertToFormValues = (obj, setValue, multiline) => {
  Object.entries(obj).map(([key, value]) => {
    if (key === "attributes" && isAttributesObject(value)) {
      setValue(key, attributesToArray(value));
    } else if (key === "config" || key === "attributes") {
      setValue(key, !isEmpty(value) ? unflatten(value) : void 0);
    } else if (multiline?.includes(key)) {
      setValue(key, convertToMultiline(value));
    } else {
      setValue(key, value);
    }
  });
};
export function convertFormValuesToObject(obj, multiline = []) {
  const result = {};
  Object.entries(obj).map(([key, value]) => {
    if (isAttributeArray(value)) {
      result[key] = arrayToAttributes(value);
    } else if (multiline.includes(key)) {
      result[key] = toValue(value);
    } else if (key === "config" || key === "attributes") {
      result[key] = flatten(value, {safe: true});
    } else {
      result[key] = value;
    }
  });
  return result;
}
export const emptyFormatter = () => (data) => {
  return data ? data : "—";
};
export const upperCaseFormatter = () => (data) => {
  const value = data?.toString();
  return value ? toUpperCase(value) : void 0;
};
export const getBaseUrl = (adminClient) => {
  return (adminClient.keycloak ? adminClient.keycloak.authServerUrl : adminClient.baseUrl) + "/";
};
export const alphaRegexPattern = /[^A-Za-z]/g;
export const emailRegexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const forHumans = (seconds) => {
  const {t} = useTranslation();
  const levels = [
    [Math.floor(seconds / 31536e3), t("common:times.years")],
    [Math.floor(seconds % 31536e3 / 86400), t("common:times.days")],
    [
      Math.floor(seconds % 31536e3 % 86400 / 3600),
      t("common:times.hours")
    ],
    [
      Math.floor(seconds % 31536e3 % 86400 % 3600 / 60),
      t("common:times.minutes")
    ],
    [seconds % 31536e3 % 86400 % 3600 % 60, t("common:times.seconds")]
  ];
  let returntext = "";
  for (let i = 0, max = levels.length; i < max; i++) {
    if (levels[i][0] === 0)
      continue;
    returntext += " " + levels[i][0] + " " + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length - 1) : levels[i][1]);
  }
  return returntext.trim();
};
export const interpolateTimespan = (forHumans2) => {
  const {t} = useTranslation();
  const timespan = forHumans2.split(" ");
  if (timespan[1] === "Years") {
    return t(`realm-settings:convertedToYearsValue`, {
      convertedToYears: forHumans2
    });
  }
  if (timespan[1] === "Days") {
    return t(`realm-settings:convertedToDaysValue`, {
      convertedToYears: forHumans2
    });
  }
  if (timespan[1] === "Hours") {
    return t(`realm-settings:convertedToHoursValue`, {
      convertedToHours: forHumans2
    });
  }
  if (timespan[1] === "Minutes") {
    return t(`realm-settings:convertedToMinutesValue`, {
      convertedToMinutes: forHumans2
    });
  }
  if (timespan[1] === "Seconds") {
    return t(`realm-settings:convertedToSecondsValue`, {
      convertedToSeconds: forHumans2
    });
  }
};
export const KEY_PROVIDER_TYPE = "org.keycloak.keys.KeyProvider";
export const prettyPrintJSON = (value) => JSON.stringify(value, null, 2);
