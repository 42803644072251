import { a as createCommonjsModule } from './_commonjsHelpers-7446a541.js';

var requiredActionProviderRepresentation = createCommonjsModule(function (module, exports) {
exports.__esModule = true;
exports.RequiredActionAlias = void 0;
(function (RequiredActionAlias) {
    RequiredActionAlias["VERIFY_EMAIL"] = "VERIFY_EMAIL";
    RequiredActionAlias["UPDATE_PROFILE"] = "UPDATE_PROFILE";
    RequiredActionAlias["CONFIGURE_TOTP"] = "CONFIGURE_TOTP";
    RequiredActionAlias["UPDATE_PASSWORD"] = "UPDATE_PASSWORD";
    RequiredActionAlias["terms_and_conditions"] = "terms_and_conditions";
})(exports.RequiredActionAlias || (exports.RequiredActionAlias = {}));

});

export { requiredActionProviderRepresentation as r };
