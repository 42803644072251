import React, {useMemo, useState} from "../../_snowpack/pkg/react.js";
import {
  Button,
  ButtonVariant,
  DataList,
  DataListCell,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  Modal,
  ModalVariant,
  Text,
  TextContent,
  TextVariants
} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {
  Table,
  TableBody,
  TableHeader,
  TableVariant
} from "../../_snowpack/pkg/@patternfly/react-table.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {useServerInfo} from "../../context/server-info/ServerInfoProvider.js";
import {useWhoAmI} from "../../context/whoami/WhoAmI.js";
import {ListEmptyState} from "../../components/list-empty-state/ListEmptyState.js";
export const AddMapperDialog = (props) => {
  const {t} = useTranslation("client-scopes");
  const serverInfo = useServerInfo();
  const {whoAmI} = useWhoAmI();
  const protocol = props.protocol;
  const protocolMappers = serverInfo.protocolMapperTypes[protocol];
  const builtInMappers = serverInfo.builtinProtocolMappers[protocol];
  const [filter, setFilter] = useState([]);
  const allRows = useMemo(() => builtInMappers.sort((a, b) => a.name.localeCompare(b.name, whoAmI.getLocale())).map((mapper) => {
    const mapperType = protocolMappers.filter((type) => type.id === mapper.protocolMapper)[0];
    return {
      item: mapper,
      selected: false,
      cells: [mapper.name, mapperType.helpText]
    };
  }), []);
  const [rows, setRows] = useState(allRows);
  if (props.filter && props.filter.length !== filter.length) {
    setFilter(props.filter);
    const nameFilter = props.filter.map((f) => f.name);
    setRows([...allRows.filter((row) => !nameFilter.includes(row.item.name))]);
  }
  const selectedRows = rows.filter((row) => row.selected).map((row) => row.item);
  const sortedProtocolMappers = useMemo(() => protocolMappers.sort((a, b) => a.name.localeCompare(b.name, whoAmI.getLocale())), [protocolMappers]);
  const isBuiltIn = !!props.filter;
  const header = [t("common:name"), t("common:description")];
  return /* @__PURE__ */ React.createElement(Modal, {
    "aria-labelledby": t("addPredefinedMappers"),
    variant: ModalVariant.medium,
    header: /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement(Text, {
      component: TextVariants.h1
    }, t("addPredefinedMappers")), /* @__PURE__ */ React.createElement(Text, null, t("predefinedMappingDescription"))),
    isOpen: props.open,
    onClose: props.toggleDialog,
    actions: isBuiltIn ? [
      /* @__PURE__ */ React.createElement(Button, {
        id: "modal-confirm",
        "data-testid": "confirm",
        key: "confirm",
        isDisabled: rows.length === 0 || selectedRows.length === 0,
        onClick: () => {
          props.onConfirm(selectedRows);
          props.toggleDialog();
        }
      }, t("common:add")),
      /* @__PURE__ */ React.createElement(Button, {
        id: "modal-cancel",
        "data-testid": "cancel",
        key: "cancel",
        variant: ButtonVariant.link,
        onClick: () => {
          props.toggleDialog();
        }
      }, t("common:cancel"))
    ] : []
  }, !isBuiltIn && /* @__PURE__ */ React.createElement(DataList, {
    onSelectDataListItem: (id) => {
      const mapper = protocolMappers.find((mapper2) => mapper2.id === id);
      props.onConfirm(mapper);
      props.toggleDialog();
    },
    "aria-label": t("addPredefinedMappers"),
    isCompact: true
  }, /* @__PURE__ */ React.createElement(DataListItem, {
    "aria-labelledby": "headerName",
    id: "header"
  }, /* @__PURE__ */ React.createElement(DataListItemRow, null, /* @__PURE__ */ React.createElement(DataListItemCells, {
    dataListCells: header.map((name) => /* @__PURE__ */ React.createElement(DataListCell, {
      style: {fontWeight: 700},
      key: name
    }, name))
  }))), sortedProtocolMappers.map((mapper) => /* @__PURE__ */ React.createElement(DataListItem, {
    "aria-label": mapper.name,
    key: mapper.id,
    id: mapper.id
  }, /* @__PURE__ */ React.createElement(DataListItemRow, null, /* @__PURE__ */ React.createElement(DataListItemCells, {
    dataListCells: [
      /* @__PURE__ */ React.createElement(DataListCell, {
        key: `name-${mapper.id}`
      }, mapper.name),
      /* @__PURE__ */ React.createElement(DataListCell, {
        key: `helpText-${mapper.id}`
      }, mapper.helpText)
    ]
  }))))), isBuiltIn && rows.length > 0 && /* @__PURE__ */ React.createElement(Table, {
    variant: TableVariant.compact,
    cells: header,
    onSelect: (_, isSelected, rowIndex) => {
      if (rowIndex === -1) {
        setRows(rows.map((row) => ({
          ...row,
          selected: isSelected
        })));
      } else {
        rows[rowIndex].selected = isSelected;
        setRows([...rows]);
      }
    },
    canSelectAll: true,
    rows,
    "aria-label": t("addPredefinedMappers")
  }, /* @__PURE__ */ React.createElement(TableHeader, null), /* @__PURE__ */ React.createElement(TableBody, null)), isBuiltIn && rows.length === 0 && /* @__PURE__ */ React.createElement(ListEmptyState, {
    message: t("common:emptyMappers"),
    instructions: t("client-scopes:emptyBuiltInMappersInstructions")
  }));
};
