import React, {useState, useEffect} from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {
  AlertVariant,
  Button,
  ButtonVariant,
  DataList,
  DataListAction,
  DataListCell,
  DataListControl,
  DataListDragButton,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  Dropdown,
  DropdownItem,
  DropdownPosition,
  DropdownToggle,
  InputGroup,
  KebabToggle,
  PageSection,
  TextInput,
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
  Tooltip
} from "../_snowpack/pkg/@patternfly/react-core.js";
import {SearchIcon} from "../_snowpack/pkg/@patternfly/react-icons.js";
import "./RealmSettingsSection.css";
import {useServerInfo} from "../context/server-info/ServerInfoProvider.js";
import {useAdminClient} from "../context/auth/AdminClient.js";
import {useAlerts} from "../components/alert/Alerts.js";
import {useConfirmDialog} from "../components/confirm-dialog/ConfirmDialog.js";
import {useRealm} from "../context/realm-context/RealmContext.js";
import {Link, useRouteMatch} from "../_snowpack/pkg/react-router-dom.js";
import {AESGeneratedModal} from "./key-providers/aes-generated/AESGeneratedModal.js";
import {JavaKeystoreModal} from "./key-providers/java-keystore/JavaKeystoreModal.js";
import {HMACGeneratedModal} from "./key-providers/hmac-generated/HMACGeneratedModal.js";
import {ECDSAGeneratedModal} from "./key-providers/ecdsa-generated/ECDSAGeneratedModal.js";
import {RSAModal} from "./RSAModal.js";
import {RSAGeneratedModal} from "./key-providers/rsa-generated/RSAGeneratedModal.js";
import {KEY_PROVIDER_TYPE} from "../util.js";
export const KeysTabInner = ({components, refresh}) => {
  const {t} = useTranslation("realm-settings");
  const {addAlert, addError} = useAlerts();
  const adminClient = useAdminClient();
  const {realm} = useRealm();
  const {url} = useRouteMatch();
  const [id, setId] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const serverInfo = useServerInfo();
  const providerTypes = (serverInfo.componentTypes?.[KEY_PROVIDER_TYPE] ?? []).map((item) => item.id);
  const [itemOrder, setItemOrder] = useState([]);
  const [providerDropdownOpen, setProviderDropdownOpen] = useState(false);
  const [defaultConsoleDisplayName, setDefaultConsoleDisplayName] = useState("");
  const [selectedComponent, setSelectedComponent] = useState();
  const [liveText, setLiveText] = useState("");
  useEffect(() => {
    const itemIds = components.map((component) => component.id);
    setItemOrder(["data", ...itemIds]);
  }, [components, searchVal]);
  const [toggleDeleteDialog, DeleteConfirm] = useConfirmDialog({
    titleKey: "realm-settings:deleteProviderTitle",
    messageKey: t("deleteProviderConfirm") + selectedComponent?.name + "?",
    continueButtonLabel: "common:delete",
    continueButtonVariant: ButtonVariant.danger,
    onConfirm: async () => {
      try {
        await adminClient.components.del({
          id: selectedComponent.id,
          realm
        });
        refresh();
        addAlert(t("deleteProviderSuccess"), AlertVariant.success);
      } catch (error) {
        addError("realm-settings:deleteProviderError", error);
      }
    }
  });
  const onDragStart = async (id2) => {
    setLiveText(t("common:onDragStart", {item: id2}));
    setId(id2);
  };
  const onDragMove = () => {
    setLiveText(t("common:onDragMove", {item: id}));
  };
  const onDragCancel = () => {
    setLiveText(t("common:onDragCancel"));
  };
  const onDragFinish = async (itemOrder2) => {
    setItemOrder(itemOrder2);
    setLiveText(t("common:onDragFinish"));
    const updateAll = components.map((component) => {
      const componentToSave = {...component};
      delete componentToSave.providerDescription;
      return adminClient.components.update({id: component.id}, {
        ...componentToSave,
        config: {
          priority: [
            (itemOrder2.length - itemOrder2.indexOf(component.id) + 100).toString()
          ]
        }
      });
    });
    try {
      await Promise.all(updateAll);
      refresh();
      addAlert(t("realm-settings:saveProviderListSuccess"), AlertVariant.success);
    } catch (error) {
      addError("realm-settings:saveProviderError", error);
    }
  };
  const onSearch = () => {
    if (searchVal !== "") {
      setSearchVal(searchVal);
      const x = components.filter((v) => {
        return v.name?.includes(searchVal) || v.providerId?.includes(searchVal);
      });
      setFilteredComponents(x);
    } else {
      setSearchVal("");
      setFilteredComponents(components);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };
  const handleInputChange = (value) => {
    setSearchVal(value);
  };
  const handleModalToggle = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };
  const [actionListOpen, setActionListOpen] = useState(components.map(() => false));
  const toggleActionList = (index) => {
    actionListOpen[index] = !actionListOpen[index];
    setActionListOpen([...actionListOpen]);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, defaultConsoleDisplayName === "aes-generated" && /* @__PURE__ */ React.createElement(AESGeneratedModal, {
    handleModalToggle,
    providerType: defaultConsoleDisplayName,
    refresh,
    open: isCreateModalOpen
  }), defaultConsoleDisplayName === "ecdsa-generated" && /* @__PURE__ */ React.createElement(ECDSAGeneratedModal, {
    handleModalToggle,
    providerType: defaultConsoleDisplayName,
    refresh,
    open: isCreateModalOpen
  }), defaultConsoleDisplayName === "hmac-generated" && /* @__PURE__ */ React.createElement(HMACGeneratedModal, {
    handleModalToggle,
    providerType: defaultConsoleDisplayName,
    refresh,
    open: isCreateModalOpen
  }), defaultConsoleDisplayName === "java-keystore" && /* @__PURE__ */ React.createElement(JavaKeystoreModal, {
    handleModalToggle,
    providerType: defaultConsoleDisplayName,
    refresh,
    open: isCreateModalOpen
  }), defaultConsoleDisplayName === "rsa" && /* @__PURE__ */ React.createElement(RSAModal, {
    handleModalToggle,
    providerType: defaultConsoleDisplayName,
    refresh,
    open: isCreateModalOpen
  }), defaultConsoleDisplayName === "rsa-generated" && /* @__PURE__ */ React.createElement(RSAGeneratedModal, {
    handleModalToggle,
    providerType: defaultConsoleDisplayName,
    refresh,
    open: isCreateModalOpen
  }), /* @__PURE__ */ React.createElement(DeleteConfirm, null), /* @__PURE__ */ React.createElement(PageSection, {
    variant: "light",
    padding: {default: "noPadding"}
  }, /* @__PURE__ */ React.createElement(Toolbar, null, /* @__PURE__ */ React.createElement(ToolbarGroup, {
    className: "providers-toolbar"
  }, /* @__PURE__ */ React.createElement(ToolbarItem, null, /* @__PURE__ */ React.createElement(InputGroup, null, /* @__PURE__ */ React.createElement(TextInput, {
    name: "inputGroupName",
    id: "inputGroupName",
    type: "search",
    "aria-label": t("common:search"),
    placeholder: t("common:search"),
    onChange: handleInputChange,
    onKeyDown: handleKeyDown
  }), /* @__PURE__ */ React.createElement(Button, {
    variant: ButtonVariant.control,
    "aria-label": t("common:search")
  }, /* @__PURE__ */ React.createElement(SearchIcon, null)))), /* @__PURE__ */ React.createElement(ToolbarItem, null, /* @__PURE__ */ React.createElement(Dropdown, {
    "data-testid": "addProviderDropdown",
    className: "add-provider-dropdown",
    isOpen: providerDropdownOpen,
    toggle: /* @__PURE__ */ React.createElement(DropdownToggle, {
      onToggle: (val) => setProviderDropdownOpen(val),
      isPrimary: true
    }, t("realm-settings:addProvider")),
    dropdownItems: [
      providerTypes.map((item) => /* @__PURE__ */ React.createElement(DropdownItem, {
        onClick: () => {
          handleModalToggle();
          setProviderDropdownOpen(false);
          setDefaultConsoleDisplayName(item);
        },
        "data-testid": `option-${item}`,
        key: item
      }, item))
    ]
  })))), /* @__PURE__ */ React.createElement(DataList, {
    "aria-label": t("common:groups"),
    onDragFinish,
    onDragStart,
    onDragMove,
    onDragCancel,
    itemOrder,
    isCompact: true
  }, /* @__PURE__ */ React.createElement(DataListItem, {
    "aria-labelledby": "aria",
    id: "data",
    key: "data"
  }, /* @__PURE__ */ React.createElement(DataListItemRow, {
    className: "test",
    "data-testid": "data-list-row"
  }, /* @__PURE__ */ React.createElement(DataListDragButton, {
    className: "header-drag-button",
    "aria-label": "Reorder",
    "aria-describedby": t("common-help:dragHelp"),
    "aria-pressed": "false",
    isDisabled: true
  }), /* @__PURE__ */ React.createElement(DataListItemCells, {
    className: "data-list-cells",
    dataListCells: [
      /* @__PURE__ */ React.createElement(DataListCell, {
        className: "name",
        key: "name"
      }, /* @__PURE__ */ React.createElement(React.Fragment, null, t("realm-settings:name"))),
      /* @__PURE__ */ React.createElement(DataListCell, {
        className: "provider",
        key: "provider"
      }, /* @__PURE__ */ React.createElement(React.Fragment, null, t("realm-settings:provider"))),
      /* @__PURE__ */ React.createElement(DataListCell, {
        className: "provider-description",
        key: "provider-description"
      }, /* @__PURE__ */ React.createElement(React.Fragment, null, t("realm-settings:providerDescription")))
    ]
  }))), (filteredComponents.length === 0 ? components : filteredComponents).map((component, idx) => /* @__PURE__ */ React.createElement(DataListItem, {
    draggable: true,
    "aria-labelledby": "aria",
    key: component.id,
    id: component.id
  }, /* @__PURE__ */ React.createElement(DataListItemRow, {
    "data-testid": "data-list-row"
  }, /* @__PURE__ */ React.createElement(DataListControl, null, /* @__PURE__ */ React.createElement(Tooltip, {
    content: t("dragInstruction"),
    position: "top"
  }, /* @__PURE__ */ React.createElement(DataListDragButton, {
    className: "kc-row-drag-button",
    "aria-label": "Reorder",
    "aria-describedby": t("common-help:dragHelp"),
    "aria-pressed": "false"
  }))), /* @__PURE__ */ React.createElement(DataListItemCells, {
    dataListCells: [
      /* @__PURE__ */ React.createElement(DataListCell, {
        "data-testid": "provider-name",
        key: `name-${idx}`
      }, /* @__PURE__ */ React.createElement(Link, {
        key: component.name,
        "data-testid": "provider-name-link",
        to: `${url}/${component.id}/${component.providerId}/settings`
      }, component.name)),
      /* @__PURE__ */ React.createElement(DataListCell, {
        key: `providerId-${idx}`
      }, component.providerId),
      /* @__PURE__ */ React.createElement(DataListCell, {
        key: `providerDescription-${idx}`
      }, component.providerDescription),
      /* @__PURE__ */ React.createElement(DataListAction, {
        "aria-labelledby": "data-list-action",
        "aria-label": "Actions",
        isPlainButtonAction: true,
        key: `data-action-list-${idx}`,
        id: `data-action-list-${idx}`
      }, /* @__PURE__ */ React.createElement(Dropdown, {
        isPlain: true,
        position: DropdownPosition.right,
        isOpen: actionListOpen[idx],
        toggle: /* @__PURE__ */ React.createElement(KebabToggle, {
          "data-testid": "provider-action",
          onToggle: () => {
            toggleActionList(idx);
          }
        }),
        dropdownItems: [
          /* @__PURE__ */ React.createElement(DropdownItem, {
            key: "action",
            component: "button",
            "data-testid": "delete-action",
            onClick: () => {
              setSelectedComponent(component);
              toggleDeleteDialog();
              toggleActionList(idx);
            }
          }, t("common:delete"))
        ]
      }))
    ]
  }))))), /* @__PURE__ */ React.createElement("div", {
    className: "pf-screen-reader",
    "aria-live": "assertive"
  }, liveText)));
};
export const KeysProvidersTab = ({
  keyProviderComponentTypes,
  realmComponents,
  refresh,
  ...props
}) => {
  return /* @__PURE__ */ React.createElement(KeysTabInner, {
    components: realmComponents?.map((component) => {
      const provider = keyProviderComponentTypes.find((componentType) => component.providerId === componentType.id);
      return {
        ...component,
        providerDescription: provider?.helpText
      };
    }),
    keyProviderComponentTypes,
    refresh,
    realmComponents,
    ...props
  });
};
