export default {
  "client-scopes": {
    createClientScope: "Create client scope",
    clientScopeList: "Client scopes",
    grantedClientScopes: "Granted client scopes",
    clientScopeDetails: "Client scope details",
    clientScopeExplain: "Client scopes allow you to define a common set of protocol mappers and roles, which are shared between multiple clients.",
    searchFor: "Search for client scope",
    protocol: "Protocol",
    assignedType: "Assigned type",
    displayOrder: "Display order",
    type: "Type",
    deleteClientScope_one: "Delete client scope {{name}}",
    deleteClientScope_other: "Delete {{count}} client scopes",
    deleteConfirm: "Are you sure you want to delete this client scope",
    changeTypeTo: "Change type to",
    changeTypeIntro: "{{count}} selected client scopes will be changed to",
    clientScopeSuccess: "Scope mapping updated",
    clientScopeError: "Could not update scope mapping {{error}}",
    deletedSuccess: "The client scope has been deleted",
    deleteError: "Could not delete client scope: {{error}}",
    includeInTokenScope: "Include in token scope",
    realmRolePrefix: "Realm role prefix",
    userInfo: "User info",
    createSuccess: "Client scope created",
    createError: "Could not create client scope: '{{error}}'",
    updateSuccess: "Client scope updated",
    updateError: "Could not update client scope: '{{error}}'",
    addMapperExplain: "If you want more fine-grain control, you can create protocol mapper on this client",
    realmRoles: "Realm roles",
    newRoleName: "New role name",
    searchClientByName: "Search client by name",
    clients: "Clients",
    mapperCreateSuccess: "New mapping has been added",
    mapperCreateError: "Could not create mapping: {{error}}",
    fromPredefinedMapper: "From predefined mappers",
    byConfiguration: "By configuration",
    emptyBuiltInMappersInstructions: "All built in mappers were added to this client",
    emptySecondaryAction: "Configure a new mapper",
    displayOnConsentScreen: "Display on consent screen",
    consentScreenText: "Consent screen text",
    guiOrder: "Display Order",
    shouldBeANumber: "Should be a number",
    chooseAMapperType: "Choose a mapper type",
    addPredefinedMappers: "Add predefined mappers",
    predefinedMappingDescription: "Choose any of the predefined mappings from this table",
    mappingTable: "Table with predefined mapping",
    scope: "Scope",
    roleMappingUpdatedSuccess: "Role mapping updated",
    roleMappingUpdatedError: "Could not update role mapping {{error}}",
    protocolTypes: {
      all: "All",
      saml: "SAML",
      "openid-connect": "openid-connect"
    }
  }
};
