export default {
  "users-help": {
    temporaryLocked: "The user may be locked due to multiple failed attempts to log in.",
    disabled: "A disabled user cannot log in.",
    emailVerified: "Has the user's email been verified?",
    requiredUserActions: "Require an action when the user logs in. 'Verify email' sends an email to the user to verify their email address. 'Update profile' requires user to enter in new personal information. 'Update password' requires user to enter in a new password. 'Configure OTP' requires setup of a mobile password generator.",
    groups: "Groups where the user has membership. To leave a group, select it and click Leave.",
    userIdHelperText: "Enter the unique ID of the user for this identity provider.",
    usernameHelperText: "Enter the username of the user for this identity provider."
  }
};
