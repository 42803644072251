import {generatePath} from "../../_snowpack/pkg/react-router-dom.js";
import NewClientPolicyCondition from "../NewClientPolicyCondition.js";
export const EditClientPolicyConditionRoute = {
  path: "/:realm/realm-settings/clientPolicies/:policyName?/edit-policy/:conditionName/edit-condition",
  component: NewClientPolicyCondition,
  breadcrumb: (t) => t("realm-settings:editCondition"),
  access: "manage-clients"
};
export const toEditClientPolicyCondition = (params) => ({
  pathname: generatePath(EditClientPolicyConditionRoute.path, params)
});
