import React, {useState} from "../../_snowpack/pkg/react.js";
import {Controller, useFormContext} from "../../_snowpack/pkg/react-hook-form.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {sortedUniq} from "../../_snowpack/pkg/lodash-es.js";
import {
  FormGroup,
  Select,
  SelectOption,
  SelectVariant
} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {useAdminClient, useFetch} from "../../context/auth/AdminClient.js";
import {HelpItem} from "../help-enabler/HelpItem.js";
import {useWhoAmI} from "../../context/whoami/WhoAmI.js";
export const MultivaluedRoleComponent = ({
  name,
  label,
  helpText
}) => {
  const {t} = useTranslation("dynamic");
  const {whoAmI} = useWhoAmI();
  const fieldName = `config.${name}`;
  const adminClient = useAdminClient();
  const {control} = useFormContext();
  const [clientRoles, setClientRoles] = useState([]);
  const [open, setOpen] = useState(false);
  useFetch(async () => {
    const clients = await adminClient.clients.find();
    const clientRoles2 = await Promise.all(clients.map(async (client) => {
      const roles = await adminClient.clients.listRoles({id: client.id});
      return roles.map((role) => ({
        ...role
      }));
    }));
    return clientRoles2.flat();
  }, (clientRoles2) => {
    setClientRoles(clientRoles2);
  }, []);
  const alphabetizedClientRoles = sortedUniq(clientRoles.map((item) => item.name)).sort((a, b) => a.localeCompare(b, whoAmI.getLocale(), {ignorePunctuation: true}));
  return /* @__PURE__ */ React.createElement(FormGroup, {
    label: t(label),
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: t(helpText),
      fieldLabelId: `dynamic:${label}`
    }),
    fieldId: name
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: fieldName,
    defaultValue: [],
    control,
    rules: {required: true},
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      onToggle: (isExpanded) => setOpen(isExpanded),
      isOpen: open,
      className: "kc-role-select",
      "data-testid": "multivalued-role-select",
      variant: SelectVariant.typeaheadMulti,
      placeholderText: t("selectARole"),
      chipGroupProps: {
        numChips: 5,
        expandedText: t("common:hide"),
        collapsedText: t("common:showRemaining")
      },
      typeAheadAriaLabel: t("selectARole"),
      selections: value.map((v) => v.value),
      isCreatable: true,
      onSelect: (_, v) => {
        const option = v.toString();
        if (value.map((v2) => v2.value).includes(option)) {
          onChange(value.filter((item) => item.value !== option));
        } else {
          onChange([...value, {value: option}]);
        }
      },
      maxHeight: 200,
      onClear: () => onChange([])
    }, alphabetizedClientRoles.map((option) => /* @__PURE__ */ React.createElement(SelectOption, {
      key: option,
      value: option
    })))
  }));
};
