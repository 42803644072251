import React from "../_snowpack/pkg/react.js";
import {
  AlertVariant,
  Button,
  ButtonVariant,
  Modal,
  ModalVariant,
  TextContent
} from "../_snowpack/pkg/@patternfly/react-core.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {useAdminClient} from "../context/auth/AdminClient.js";
import {useRealm} from "../context/realm-context/RealmContext.js";
import {useAlerts} from "../components/alert/Alerts.js";
export const LogoutAllSessionsModal = ({
  handleModalToggle
}) => {
  const {t} = useTranslation("sessions");
  const {addAlert} = useAlerts();
  const {realm: realmName} = useRealm();
  const adminClient = useAdminClient();
  const logoutAllSessions = async () => {
    try {
      await adminClient.realms.logoutAll({realm: realmName});
      adminClient.keycloak?.logout({redirectUri: ""});
    } catch (error) {
      addAlert(t("logoutAllSessionsError", {error}), AlertVariant.danger);
    }
  };
  return /* @__PURE__ */ React.createElement(Modal, {
    variant: ModalVariant.small,
    title: t("signOutAllActiveSessionsQuestion"),
    isOpen: true,
    onClose: handleModalToggle,
    actions: [
      /* @__PURE__ */ React.createElement(Button, {
        "data-testid": "logout-all-confirm-button",
        key: "set-to-now",
        variant: "primary",
        onClick: () => {
          logoutAllSessions();
          handleModalToggle();
        },
        form: "revocation-modal-form"
      }, t("realm-settings:confirm")),
      /* @__PURE__ */ React.createElement(Button, {
        id: "modal-cancel",
        "data-testid": "cancel",
        key: "cancel",
        variant: ButtonVariant.link,
        onClick: () => {
          handleModalToggle();
        }
      }, t("common:cancel"))
    ]
  }, /* @__PURE__ */ React.createElement(TextContent, {
    className: "kc-logout-all-description-text"
  }, t("logoutAllDescription")));
};
