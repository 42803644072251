import React from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {useFormContext} from "../../_snowpack/pkg/react-hook-form.js";
import {FormGroup, TextInput, ValidatedOptions} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {HelpItem} from "../../components/help-enabler/HelpItem.js";
export const X509 = () => {
  const {t} = useTranslation("clients");
  const {register, errors} = useFormContext();
  return /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("subject"),
    fieldId: "kc-subject",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:subject",
      fieldLabelId: "clients:subject"
    }),
    helperTextInvalid: t("common:required"),
    validated: errors.attributes?.["x509.subjectdn"] ? ValidatedOptions.error : ValidatedOptions.default,
    isRequired: true
  }, /* @__PURE__ */ React.createElement(TextInput, {
    ref: register({required: true}),
    type: "text",
    id: "kc-subject",
    name: "attributes.x509.subjectdn",
    validated: errors.attributes?.["x509.subjectdn"] ? ValidatedOptions.error : ValidatedOptions.default
  }));
};
