import {AesGeneratedSettingsRoute} from "./routes/AesGeneratedSettings.js";
import {EcdsaGeneratedSettingsRoute} from "./routes/EcdsaGeneratedSettings.js";
import {HmacGeneratedSettingsRoute} from "./routes/HmacGeneratedSettings.js";
import {JavaKeystoreSettingsRoute} from "./routes/JavaKeystoreSettings.js";
import {RealmSettingsRoute} from "./routes/RealmSettings.js";
import {RsaGeneratedSettingsRoute} from "./routes/RsaGeneratedSettings.js";
import {RsaSettingsRoute} from "./routes/RsaSettings.js";
import {ClientPoliciesRoute} from "./routes/ClientPolicies.js";
import {AddClientProfileRoute} from "./routes/AddClientProfile.js";
import {ClientProfileRoute} from "./routes/ClientProfile.js";
import {AddExecutorRoute} from "./routes/AddExecutor.js";
import {ExecutorRoute} from "./routes/Executor.js";
import {AddClientPolicyRoute} from "./routes/AddClientPolicy.js";
import {EditClientPolicyRoute} from "./routes/EditClientPolicy.js";
import {NewClientPolicyConditionRoute} from "./routes/AddCondition.js";
import {EditClientPolicyConditionRoute} from "./routes/EditCondition.js";
import {UserProfileRoute} from "./routes/UserProfile.js";
import {KeysRoute} from "./routes/KeysTab.js";
const routes = [
  RealmSettingsRoute,
  KeysRoute,
  AesGeneratedSettingsRoute,
  EcdsaGeneratedSettingsRoute,
  HmacGeneratedSettingsRoute,
  JavaKeystoreSettingsRoute,
  RsaGeneratedSettingsRoute,
  RsaSettingsRoute,
  ClientPoliciesRoute,
  AddClientProfileRoute,
  AddExecutorRoute,
  ClientProfileRoute,
  ExecutorRoute,
  AddClientPolicyRoute,
  EditClientPolicyRoute,
  NewClientPolicyConditionRoute,
  EditClientPolicyConditionRoute,
  UserProfileRoute
];
export default routes;
