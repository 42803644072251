import React, {useState} from "../_snowpack/pkg/react.js";
import {useHistory, useRouteMatch} from "../_snowpack/pkg/react-router-dom.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {
  Button,
  ButtonVariant,
  PageSection,
  Select,
  SelectOption,
  SelectVariant
} from "../_snowpack/pkg/@patternfly/react-core.js";
import {cellWidth} from "../_snowpack/pkg/@patternfly/react-table.js";
import {ListEmptyState} from "../components/list-empty-state/ListEmptyState.js";
import {KeycloakDataTable} from "../components/table-toolbar/KeycloakDataTable.js";
import {useConfirmDialog} from "../components/confirm-dialog/ConfirmDialog.js";
import {emptyFormatter} from "../util.js";
import {useAdminClient} from "../context/auth/AdminClient.js";
import {useRealm} from "../context/realm-context/RealmContext.js";
import "./RealmSettingsSection.css";
import {FilterIcon} from "../_snowpack/pkg/@patternfly/react-icons.js";
export const KeysListTab = ({realmComponents}) => {
  const {t} = useTranslation("roles");
  const history = useHistory();
  const {url} = useRouteMatch();
  const [key, setKey] = useState(0);
  const [publicKey, setPublicKey] = useState("");
  const [certificate, setCertificate] = useState("");
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [filterType, setFilterType] = useState("Active keys");
  const refresh = () => {
    setKey(new Date().getTime());
  };
  const adminClient = useAdminClient();
  const {realm: realmName} = useRealm();
  const loader = async () => {
    const keysMetaData = await adminClient.realms.getKeys({
      realm: realmName
    });
    const keys = keysMetaData.keys;
    return keys?.map((key2) => {
      const provider = realmComponents.find((component) => component.id === key2.providerId);
      return {...key2, provider: provider?.name};
    });
  };
  const activeKeysLoader = async () => {
    const keysMetaData = await adminClient.realms.getKeys({
      realm: realmName
    });
    const keys = keysMetaData.keys;
    const activeKeysCopy = keys.filter((i) => i.status === "ACTIVE");
    return activeKeysCopy?.map((key2) => {
      const provider = realmComponents.find((component) => component.id === key2.providerId);
      return {...key2, provider: provider?.name};
    });
  };
  const passiveKeysLoader = async () => {
    const keysMetaData = await adminClient.realms.getKeys({
      realm: realmName
    });
    const keys = keysMetaData.keys;
    const passiveKeys = keys.filter((i) => i.status === "PASSIVE");
    return passiveKeys?.map((key2) => {
      const provider = realmComponents.find((component) => component.id === key2.providerId);
      return {...key2, provider: provider?.name};
    });
  };
  const disabledKeysLoader = async () => {
    const keysMetaData = await adminClient.realms.getKeys({
      realm: realmName
    });
    const keys = keysMetaData.keys;
    const disabledKeys = keys.filter((i) => i.status === "DISABLED");
    return disabledKeys?.map((key2) => {
      const provider = realmComponents.find((component) => component.id === key2.providerId);
      return {...key2, provider: provider?.name};
    });
  };
  const [togglePublicKeyDialog, PublicKeyDialog] = useConfirmDialog({
    titleKey: t("realm-settings:publicKeys").slice(0, -1),
    messageKey: publicKey,
    continueButtonLabel: "common:close",
    continueButtonVariant: ButtonVariant.primary,
    onConfirm: () => Promise.resolve()
  });
  const [toggleCertificateDialog, CertificateDialog] = useConfirmDialog({
    titleKey: t("realm-settings:certificate"),
    messageKey: certificate,
    continueButtonLabel: "common:close",
    continueButtonVariant: ButtonVariant.primary,
    onConfirm: () => Promise.resolve()
  });
  const goToCreate = () => history.push(`${url}/add-role`);
  const ProviderRenderer = ({provider}) => provider;
  const ButtonRenderer = ({type, publicKey: publicKey2, certificate: certificate2}) => {
    if (type === "EC") {
      return /* @__PURE__ */ React.createElement(Button, {
        onClick: () => {
          togglePublicKeyDialog();
          setPublicKey(publicKey2);
        },
        variant: "secondary",
        id: "kc-public-key"
      }, t("realm-settings:publicKeys").slice(0, -1));
    } else if (type === "RSA") {
      return /* @__PURE__ */ React.createElement("div", {
        className: "button-wrapper"
      }, /* @__PURE__ */ React.createElement(Button, {
        onClick: () => {
          togglePublicKeyDialog();
          setPublicKey(publicKey2);
        },
        variant: "secondary",
        id: "kc-rsa-public-key"
      }, t("realm-settings:publicKeys").slice(0, -1)), /* @__PURE__ */ React.createElement(Button, {
        onClick: () => {
          toggleCertificateDialog();
          setCertificate(certificate2);
        },
        variant: "secondary",
        id: "kc-certificate"
      }, t("realm-settings:certificate")));
    }
  };
  const options = [
    /* @__PURE__ */ React.createElement(SelectOption, {
      key: 1,
      "data-testid": "active-keys-option",
      value: t("realm-settings:activeKeys"),
      isPlaceholder: true
    }),
    /* @__PURE__ */ React.createElement(SelectOption, {
      "data-testid": "passive-keys-option",
      key: 2,
      value: t("realm-settings:passiveKeys")
    }),
    /* @__PURE__ */ React.createElement(SelectOption, {
      "data-testid": "disabled-keys-option",
      key: 3,
      value: t("realm-settings:disabledKeys")
    })
  ];
  return /* @__PURE__ */ React.createElement(PageSection, {
    variant: "light",
    padding: {default: "noPadding"}
  }, /* @__PURE__ */ React.createElement(PublicKeyDialog, null), /* @__PURE__ */ React.createElement(CertificateDialog, null), /* @__PURE__ */ React.createElement(KeycloakDataTable, {
    isNotCompact: true,
    key,
    loader: filterType === "Active keys" ? activeKeysLoader : filterType === "Passive keys" ? passiveKeysLoader : filterType === "Disabled keys" ? disabledKeysLoader : loader,
    ariaLabelKey: "realm-settings:keysList",
    searchPlaceholderKey: "realm-settings:searchKey",
    searchTypeComponent: /* @__PURE__ */ React.createElement(Select, {
      width: 300,
      "data-testid": "filter-type-select",
      isOpen: filterDropdownOpen,
      className: "kc-filter-type-select",
      variant: SelectVariant.single,
      onToggle: () => setFilterDropdownOpen(!filterDropdownOpen),
      toggleIcon: /* @__PURE__ */ React.createElement(FilterIcon, null),
      onSelect: (_, value) => {
        setFilterType(value);
        refresh();
        setFilterDropdownOpen(false);
      },
      selections: filterType
    }, options),
    canSelectAll: true,
    columns: [
      {
        name: "algorithm",
        displayKey: "realm-settings:algorithm",
        cellFormatters: [emptyFormatter()],
        transforms: [cellWidth(15)]
      },
      {
        name: "type",
        displayKey: "realm-settings:type",
        cellFormatters: [emptyFormatter()],
        transforms: [cellWidth(10)]
      },
      {
        name: "kid",
        displayKey: "realm-settings:kid",
        cellFormatters: [emptyFormatter()],
        transforms: [cellWidth(10)]
      },
      {
        name: "provider",
        displayKey: "realm-settings:provider",
        cellRenderer: ProviderRenderer,
        cellFormatters: [emptyFormatter()],
        transforms: [cellWidth(10)]
      },
      {
        name: "publicKeys",
        displayKey: "realm-settings:publicKeys",
        cellRenderer: ButtonRenderer,
        cellFormatters: [],
        transforms: [cellWidth(20)]
      }
    ],
    isSearching: !!filterType,
    emptyState: /* @__PURE__ */ React.createElement(ListEmptyState, {
      hasIcon: true,
      message: t("realm-settings:noKeys"),
      instructions: t(`realm-settings:noKeysDescription`) + `${filterType.toLocaleLowerCase()}.`,
      primaryActionText: t("createRole"),
      onPrimaryAction: goToCreate
    })
  }));
};
