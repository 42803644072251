import React, {useState} from "../_snowpack/pkg/react.js";
import {Link} from "../_snowpack/pkg/react-router-dom.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import moment from "../_snowpack/pkg/moment.js";
import {
  DropdownItem,
  PageSection,
  Select,
  SelectOption,
  SelectVariant
} from "../_snowpack/pkg/@patternfly/react-core.js";
import {FilterIcon} from "../_snowpack/pkg/@patternfly/react-icons.js";
import {ListEmptyState} from "../components/list-empty-state/ListEmptyState.js";
import {ViewHeader} from "../components/view-header/ViewHeader.js";
import {KeycloakDataTable} from "../components/table-toolbar/KeycloakDataTable.js";
import {useAdminClient} from "../context/auth/AdminClient.js";
import {CubesIcon} from "../_snowpack/pkg/@patternfly/react-icons.js";
import "./SessionsSection.css";
import {RevocationModal} from "./RevocationModal.js";
import {LogoutAllSessionsModal} from "./LogoutAllSessionsModal.js";
import helpUrls from "../help-urls.js";
const Clients = (row) => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, Object.values(row.clients).map((client) => /* @__PURE__ */ React.createElement(Link, {
    key: client,
    to: "",
    className: "pf-u-mx-sm"
  }, client)));
};
export default function SessionsSection() {
  const {t} = useTranslation("sessions");
  const adminClient = useAdminClient();
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [revocationModalOpen, setRevocationModalOpen] = useState(false);
  const [logoutAllSessionsModalOpen, setLogoutAllSessionsModalOpen] = useState(false);
  const [activeClientDetails, setActiveClientDetails] = useState([]);
  const [filterType, setFilterType] = useState(t("sessionsType.allSessions").toString());
  const [key, setKey] = useState(0);
  const [noSessions, setNoSessions] = useState(false);
  const refresh = () => {
    setKey(new Date().getTime());
  };
  const handleRevocationModalToggle = () => {
    setRevocationModalOpen(!revocationModalOpen);
  };
  const handleLogoutAllSessionsModalToggle = () => {
    setLogoutAllSessionsModalOpen(!logoutAllSessionsModalOpen);
  };
  const loader = async () => {
    const activeClients = await adminClient.sessions.find();
    const clientSessions = (await Promise.all(activeClients.map((client) => adminClient.clients.listSessions({id: client.id})))).flat();
    setNoSessions(clientSessions.length === 0);
    const allClients = await adminClient.clients.find();
    const getActiveClientDetails = allClients.filter((x) => activeClients.map((y) => y.id).includes(x.id));
    setActiveClientDetails(getActiveClientDetails);
    const userIds = Array.from(new Set(clientSessions.map((session) => session.userId)));
    const userSessions = (await Promise.all(userIds.map((userId) => adminClient.users.listSessions({id: userId})))).flat();
    return userSessions;
  };
  const dropdownItems = [
    /* @__PURE__ */ React.createElement(DropdownItem, {
      key: "toggle-modal",
      "data-testid": "revocation",
      component: "button",
      onClick: () => handleRevocationModalToggle()
    }, t("revocation")),
    /* @__PURE__ */ React.createElement(DropdownItem, {
      key: "delete-role",
      "data-testid": "logout-all",
      component: "button",
      isDisabled: noSessions,
      onClick: () => handleLogoutAllSessionsModalToggle()
    }, t("signOutAllActiveSessions"))
  ];
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ViewHeader, {
    dropdownItems,
    titleKey: "sessions:title",
    subKey: "sessions:sessionExplain",
    helpUrl: helpUrls.sessionsUrl
  }), /* @__PURE__ */ React.createElement(PageSection, {
    variant: "light",
    className: "pf-u-p-0"
  }, revocationModalOpen && /* @__PURE__ */ React.createElement(RevocationModal, {
    handleModalToggle: handleRevocationModalToggle,
    activeClients: activeClientDetails,
    save: () => {
      handleRevocationModalToggle();
    }
  }), logoutAllSessionsModalOpen && /* @__PURE__ */ React.createElement(LogoutAllSessionsModal, {
    handleModalToggle: handleLogoutAllSessionsModalToggle
  }), /* @__PURE__ */ React.createElement(KeycloakDataTable, {
    key,
    loader,
    ariaLabelKey: "session:title",
    searchPlaceholderKey: "sessions:searchForSession",
    searchTypeComponent: /* @__PURE__ */ React.createElement(Select, {
      "data-testid": "filter-session-type-select",
      isOpen: filterDropdownOpen,
      className: "kc-filter-session-type-select",
      variant: SelectVariant.single,
      onToggle: (isExpanded) => setFilterDropdownOpen(isExpanded),
      toggleIcon: /* @__PURE__ */ React.createElement(FilterIcon, null),
      onSelect: (_, value) => {
        setFilterType(value.toString());
        refresh();
        setFilterDropdownOpen(false);
      },
      selections: filterType
    }, /* @__PURE__ */ React.createElement(SelectOption, {
      "data-testid": "all-sessions-option",
      value: t("sessionsType.allSessions"),
      isPlaceholder: true
    }), /* @__PURE__ */ React.createElement(SelectOption, {
      "data-testid": "regular-sso-option",
      value: t("sessionsType.regularSSO")
    }), /* @__PURE__ */ React.createElement(SelectOption, {
      "data-testid": "offline-option",
      value: t("sessionsType.offline")
    }), /* @__PURE__ */ React.createElement(SelectOption, {
      "data-testid": "direct-grant-option",
      value: t("sessionsType.directGrant")
    }), /* @__PURE__ */ React.createElement(SelectOption, {
      "data-testid": "service-account-option",
      value: t("sessionsType.serviceAccount")
    })),
    columns: [
      {
        name: "username",
        displayKey: "sessions:subject"
      },
      {
        name: "lastAccess",
        displayKey: "sessions:lastAccess",
        cellRenderer: (row) => moment(row.lastAccess).fromNow()
      },
      {
        name: "start",
        displayKey: "sessions:startDate",
        cellRenderer: (row) => moment(row.lastAccess).format("LLL")
      },
      {
        name: "clients",
        displayKey: "sessions:accessedClients",
        cellRenderer: Clients
      }
    ],
    emptyState: /* @__PURE__ */ React.createElement(ListEmptyState, {
      hasIcon: true,
      icon: CubesIcon,
      message: t("noSessions"),
      instructions: t("noSessionsDescription")
    })
  })));
}
