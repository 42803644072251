export default {
  realm: {
    uploadFile: "Upload JSON file",
    realmName: "Realm name",
    enabled: "Enabled",
    createRealm: "Create realm",
    realmExplain: "A realm manages a set of users, credentials, roles, and groups. A user belongs to and logs into a realm. Realms are isolated from one another and can only manage and authenticate the users that they control.",
    noRealmRoles: "No realm roles",
    emptyStateText: "There aren't any realm roles in this realm. Create a realm role to get started.",
    saveRealmSuccess: "Realm created successfully",
    saveRealmError: "Could not create realm {{error}}"
  }
};
