import React, {Fragment, useEffect} from "../../_snowpack/pkg/react.js";
import {useFieldArray, useFormContext, useWatch} from "../../_snowpack/pkg/react-hook-form.js";
import {
  TextInput,
  Button,
  ButtonVariant,
  InputGroup
} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {MinusCircleIcon, PlusCircleIcon} from "../../_snowpack/pkg/@patternfly/react-icons.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
export const MultiLineInput = ({
  name,
  addButtonLabel,
  ...rest
}) => {
  const {t} = useTranslation();
  const {register, control, reset} = useFormContext();
  const {fields, append, remove} = useFieldArray({
    name,
    control
  });
  const currentValues = useWatch({control, name});
  useEffect(() => {
    reset({
      [name]: [{value: ""}]
    });
  }, []);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, fields.map(({id, value}, index) => /* @__PURE__ */ React.createElement(Fragment, {
    key: id
  }, /* @__PURE__ */ React.createElement(InputGroup, null, /* @__PURE__ */ React.createElement(TextInput, {
    id,
    ref: register(),
    name: `${name}[${index}].value`,
    defaultValue: value,
    ...rest
  }), /* @__PURE__ */ React.createElement(Button, {
    variant: ButtonVariant.link,
    onClick: () => remove(index),
    tabIndex: -1,
    "aria-label": t("common:remove"),
    isDisabled: index === fields.length - 1
  }, /* @__PURE__ */ React.createElement(MinusCircleIcon, null))), index === fields.length - 1 && /* @__PURE__ */ React.createElement(Button, {
    variant: ButtonVariant.link,
    onClick: () => append({}),
    tabIndex: -1,
    "aria-label": t("common:add"),
    "data-testid": "addValue",
    isDisabled: rest.isDisabled || !currentValues?.[index]?.value
  }, /* @__PURE__ */ React.createElement(PlusCircleIcon, null), " ", t(addButtonLabel || "common:add")))));
};
