import React, {Fragment, useState} from "../_snowpack/pkg/react.js";
import {
  AlertVariant,
  Button,
  ButtonVariant,
  Divider
} from "../_snowpack/pkg/@patternfly/react-core.js";
import {
  TableComposable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "../_snowpack/pkg/@patternfly/react-table.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {useAlerts} from "../components/alert/Alerts.js";
import {ListEmptyState} from "../components/list-empty-state/ListEmptyState.js";
import {useAdminClient, useFetch} from "../context/auth/AdminClient.js";
import {HelpItem} from "../components/help-enabler/HelpItem.js";
import {useConfirmDialog} from "../components/confirm-dialog/ConfirmDialog.js";
import {ResetPasswordDialog} from "./user-credentials/ResetPasswordDialog.js";
import {ResetCredentialDialog} from "./user-credentials/ResetCredentialDialog.js";
import {InlineLabelEdit} from "./user-credentials/InlineLabelEdit.js";
import "./user-credentials.css";
import {CredentialRow} from "./user-credentials/CredentialRow.js";
import {toUpperCase} from "../util.js";
export const UserCredentials = ({user}) => {
  const {t} = useTranslation("users");
  const {addAlert, addError} = useAlerts();
  const [key, setKey] = useState(0);
  const refresh = () => setKey(key + 1);
  const [isOpen, setIsOpen] = useState(false);
  const [openCredentialReset, setOpenCredentialReset] = useState(false);
  const adminClient = useAdminClient();
  const [userCredentials, setUserCredentials] = useState([]);
  const [groupedUserCredentials, setGroupedUserCredentials] = useState([]);
  const [selectedCredential, setSelectedCredential] = useState({});
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isUserLabelEdit, setIsUserLabelEdit] = useState();
  useFetch(() => adminClient.users.getCredentials({id: user.id}), (credentials) => {
    setUserCredentials(credentials);
    const groupedCredentials = credentials.reduce((r, a) => {
      r[a.type] = r[a.type] || [];
      r[a.type].push(a);
      return r;
    }, Object.create(null));
    const groupedCredentialsArray = Object.keys(groupedCredentials).map((key2) => ({key: key2, value: groupedCredentials[key2]}));
    setGroupedUserCredentials(groupedCredentialsArray.map((groupedCredential) => ({
      ...groupedCredential,
      isExpanded: false
    })));
  }, [key]);
  const passwordTypeFinder = userCredentials.find((credential) => credential.type === "password");
  const toggleModal = () => setIsOpen(!isOpen);
  const toggleCredentialsResetModal = () => {
    setOpenCredentialReset(!openCredentialReset);
  };
  const resetPassword = () => {
    setIsResetPassword(true);
    toggleModal();
  };
  const [toggleDeleteDialog, DeleteConfirm] = useConfirmDialog({
    titleKey: t("deleteCredentialsConfirmTitle"),
    messageKey: t("deleteCredentialsConfirm"),
    continueButtonLabel: t("common:delete"),
    continueButtonVariant: ButtonVariant.danger,
    onConfirm: async () => {
      try {
        await adminClient.users.deleteCredential({
          id: user.id,
          credentialId: selectedCredential.id
        });
        addAlert(t("deleteCredentialsSuccess"), AlertVariant.success);
        setKey((key2) => key2 + 1);
      } catch (error) {
        addError("users:deleteCredentialsError", error);
      }
    }
  });
  const Row = ({credential}) => /* @__PURE__ */ React.createElement(CredentialRow, {
    key: credential.id,
    credential,
    toggleDelete: () => {
      setSelectedCredential(credential);
      toggleDeleteDialog();
    },
    resetPassword
  }, /* @__PURE__ */ React.createElement(InlineLabelEdit, {
    credential,
    userId: user.id,
    isEditable: isUserLabelEdit?.status && isUserLabelEdit.rowKey === credential.id || false,
    toggle: () => {
      setIsUserLabelEdit({
        status: !isUserLabelEdit?.status,
        rowKey: credential.id
      });
      if (isUserLabelEdit?.status) {
        refresh();
      }
    }
  }));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, isOpen && /* @__PURE__ */ React.createElement(ResetPasswordDialog, {
    user,
    isResetPassword,
    refresh,
    onClose: () => setIsOpen(false)
  }), openCredentialReset && /* @__PURE__ */ React.createElement(ResetCredentialDialog, {
    userId: user.id,
    onClose: () => setOpenCredentialReset(false)
  }), /* @__PURE__ */ React.createElement(DeleteConfirm, null), userCredentials.length !== 0 && passwordTypeFinder === void 0 && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button, {
    key: `confirmSaveBtn-table-${user.id}`,
    className: "kc-setPasswordBtn-tbl",
    "data-testid": "setPasswordBtn-table",
    variant: "primary",
    form: "userCredentials-form",
    onClick: () => {
      setIsOpen(true);
    }
  }, t("savePassword")), /* @__PURE__ */ React.createElement(Divider, null)), groupedUserCredentials.length !== 0 ? /* @__PURE__ */ React.createElement(React.Fragment, null, user.email && /* @__PURE__ */ React.createElement(Button, {
    className: "resetCredentialBtn-header",
    variant: "primary",
    "data-testid": "credentialResetBtn",
    onClick: () => setOpenCredentialReset(true)
  }, t("credentialResetBtn")), /* @__PURE__ */ React.createElement(TableComposable, {
    "aria-label": "password-data-table",
    variant: "compact"
  }, /* @__PURE__ */ React.createElement(Thead, null, /* @__PURE__ */ React.createElement(Tr, null, /* @__PURE__ */ React.createElement(Th, null, /* @__PURE__ */ React.createElement(HelpItem, {
    helpText: "users:userCredentialsHelpText",
    fieldLabelId: "users:userCredentialsHelpTextLabel"
  })), /* @__PURE__ */ React.createElement(Th, null, t("type")), /* @__PURE__ */ React.createElement(Th, null, t("userLabel")), /* @__PURE__ */ React.createElement(Th, null, t("data")), /* @__PURE__ */ React.createElement(Th, null), /* @__PURE__ */ React.createElement(Th, null))), /* @__PURE__ */ React.createElement(Tbody, null, groupedUserCredentials.map((groupedCredential, rowIndex) => /* @__PURE__ */ React.createElement(Fragment, {
    key: `table-${groupedCredential.key}`
  }, /* @__PURE__ */ React.createElement(Tr, null, groupedCredential.value.length > 1 ? /* @__PURE__ */ React.createElement(Td, {
    className: "kc-expandRow-btn",
    expand: {
      rowIndex,
      isExpanded: groupedCredential.isExpanded,
      onToggle: (_, rowIndex2) => {
        const rows = groupedUserCredentials.map((credential, index) => index === rowIndex2 ? {
          ...credential,
          isExpanded: !credential.isExpanded
        } : credential);
        setGroupedUserCredentials(rows);
      }
    }
  }) : /* @__PURE__ */ React.createElement(Td, null), /* @__PURE__ */ React.createElement(Td, {
    key: `table-item-${groupedCredential.key}`,
    dataLabel: `columns-${groupedCredential.key}`,
    className: "kc-notExpandableRow-credentialType"
  }, toUpperCase(groupedCredential.key)), groupedCredential.value.length <= 1 && groupedCredential.value.map((credential) => /* @__PURE__ */ React.createElement(Row, {
    key: `subrow-${credential.id}`,
    credential
  }))), groupedCredential.isExpanded && groupedCredential.value.map((credential) => /* @__PURE__ */ React.createElement(Tr, {
    key: `child-key-${credential.id}`
  }, /* @__PURE__ */ React.createElement(Td, null), /* @__PURE__ */ React.createElement(Td, {
    dataLabel: `child-columns-${credential.id}`,
    className: "kc-expandableRow-credentialType"
  }, toUpperCase(credential.type)), /* @__PURE__ */ React.createElement(Row, {
    credential
  })))))))) : /* @__PURE__ */ React.createElement(ListEmptyState, {
    hasIcon: true,
    message: t("noCredentials"),
    instructions: t("noCredentialsText"),
    primaryActionText: t("setPassword"),
    onPrimaryAction: toggleModal,
    secondaryActions: user.email ? [
      {
        text: t("credentialResetBtn"),
        onClick: toggleCredentialsResetModal,
        type: ButtonVariant.link
      }
    ] : void 0
  }));
};
