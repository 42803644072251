import React, {useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {
  AlertVariant,
  Badge,
  Button,
  ButtonVariant,
  Checkbox,
  ToolbarItem
} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {AddRoleMappingModal} from "./AddRoleMappingModal.js";
import {KeycloakDataTable} from "../table-toolbar/KeycloakDataTable.js";
import {emptyFormatter} from "../../util.js";
import "./role-mapping.css";
import {useConfirmDialog} from "../confirm-dialog/ConfirmDialog.js";
import {useAdminClient} from "../../context/auth/AdminClient.js";
import {useAlerts} from "../alert/Alerts.js";
import {ListEmptyState} from "../list-empty-state/ListEmptyState.js";
export const mapRoles = (assignedRoles, effectiveRoles, hide) => {
  return [
    ...hide ? assignedRoles.map((row) => ({
      ...row,
      role: {
        ...row.role,
        isInherited: false
      }
    })) : effectiveRoles.map((row) => ({
      ...row,
      role: {
        ...row.role,
        isInherited: assignedRoles.find((r) => r.role.id === row.role.id) === void 0
      }
    }))
  ];
};
export const ServiceRole = ({role, client}) => /* @__PURE__ */ React.createElement(React.Fragment, null, client && /* @__PURE__ */ React.createElement(Badge, {
  key: `${client.id}-${role.id}`,
  isRead: true,
  className: "keycloak-admin--role-mapping__client-name"
}, client.clientId), role.name);
export const RoleMapping = ({
  name,
  id,
  type,
  loader,
  save,
  onHideRolesToggle
}) => {
  const {t} = useTranslation("clients");
  const adminClient = useAdminClient();
  const {addAlert, addError} = useAlerts();
  const [key, setKey] = useState(0);
  const refresh = () => setKey(new Date().getTime());
  const [hide, setHide] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [selected, setSelected] = useState([]);
  const assignRoles = async (rows) => {
    await save(rows);
    refresh();
  };
  const [toggleDeleteDialog, DeleteConfirm] = useConfirmDialog({
    titleKey: "clients:removeMappingTitle",
    messageKey: t("removeMappingConfirm", {count: selected.length}),
    continueButtonLabel: "common:remove",
    continueButtonVariant: ButtonVariant.danger,
    onConfirm: async () => {
      try {
        switch (type) {
          case "group":
            await Promise.all(selected.map((row) => {
              const role = {id: row.role.id, name: row.role.name};
              if (row.client) {
                return adminClient.groups.delClientRoleMappings({
                  id,
                  clientUniqueId: row.client.id,
                  roles: [role]
                });
              } else {
                return adminClient.groups.delRealmRoleMappings({
                  id,
                  roles: [role]
                });
              }
            }));
            break;
          case "service-account":
            await Promise.all(selected.map((row) => {
              const role = {id: row.role.id, name: row.role.name};
              if (row.client) {
                return adminClient.users.delClientRoleMappings({
                  id,
                  clientUniqueId: row.client.id,
                  roles: [role]
                });
              } else {
                return adminClient.users.delRealmRoleMappings({
                  id,
                  roles: [role]
                });
              }
            }));
            break;
          case "client-scope":
            await Promise.all(selected.map((row) => {
              const role = {id: row.role.id, name: row.role.name};
              if (row.client) {
                return adminClient.clientScopes.delClientScopeMappings({
                  id,
                  client: row.client.id
                }, [role]);
              } else {
                return adminClient.clientScopes.delRealmScopeMappings({
                  id
                }, [role]);
              }
            }));
            break;
        }
        addAlert(t("clientScopeRemoveSuccess"), AlertVariant.success);
        refresh();
      } catch (error) {
        addError("clients:clientScopeRemoveError", error);
      }
    }
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, showAssign && /* @__PURE__ */ React.createElement(AddRoleMappingModal, {
    id,
    type,
    name,
    onAssign: assignRoles,
    onClose: () => setShowAssign(false)
  }), /* @__PURE__ */ React.createElement(DeleteConfirm, null), /* @__PURE__ */ React.createElement(KeycloakDataTable, {
    "data-testid": "assigned-roles",
    key,
    loader,
    canSelectAll: true,
    onSelect: (rows) => setSelected(rows),
    searchPlaceholderKey: "clients:searchByName",
    ariaLabelKey: "clients:clientScopeList",
    isRowDisabled: (value) => value.role.isInherited || false,
    toolbarItem: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ToolbarItem, null, /* @__PURE__ */ React.createElement(Checkbox, {
      label: t("hideInheritedRoles"),
      id: "hideInheritedRoles",
      isChecked: hide,
      onChange: (check) => {
        setHide(check);
        onHideRolesToggle();
        refresh();
      }
    })), /* @__PURE__ */ React.createElement(ToolbarItem, null, /* @__PURE__ */ React.createElement(Button, {
      "data-testid": "assignRole",
      onClick: () => setShowAssign(true)
    }, t("assignRole"))), /* @__PURE__ */ React.createElement(ToolbarItem, null, /* @__PURE__ */ React.createElement(Button, {
      variant: "link",
      "data-testid": "unAssignRole",
      onClick: toggleDeleteDialog,
      isDisabled: selected.length === 0
    }, t("unAssignRole")))),
    actions: [
      {
        title: t("unAssignRole"),
        onRowClick: async (role) => {
          setSelected([role]);
          toggleDeleteDialog();
          return false;
        }
      }
    ],
    columns: [
      {
        name: "role.name",
        displayKey: t("name"),
        cellRenderer: ServiceRole
      },
      {
        name: "role.parent.name",
        displayKey: t("inherentFrom"),
        cellFormatters: [emptyFormatter()]
      },
      {
        name: "role.description",
        displayKey: t("description"),
        cellFormatters: [emptyFormatter()]
      }
    ],
    emptyState: /* @__PURE__ */ React.createElement(ListEmptyState, {
      message: t("noRoles"),
      instructions: t("noRolesInstructions"),
      primaryActionText: t("assignRole"),
      onPrimaryAction: () => setShowAssign(true)
    })
  }));
};
