import React, {useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {
  FormGroup,
  Select,
  SelectVariant,
  SelectOption,
  PageSection,
  ActionGroup,
  Button,
  Switch,
  ExpandableSection,
  TextInput
} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {Controller, useFormContext} from "../../_snowpack/pkg/react-hook-form.js";
import {FormAccess} from "../../components/form-access/FormAccess.js";
import {HelpItem} from "../../components/help-enabler/HelpItem.js";
import {FormPanel} from "../../components/scroll-form/FormPanel.js";
import {useAdminClient, useFetch} from "../../context/auth/AdminClient.js";
import {useRealm} from "../../context/realm-context/RealmContext.js";
import {AttributeInput} from "../../components/attribute-input/AttributeInput.js";
import {defaultContextAttributes} from "../utils.js";
import {useParams} from "../../_snowpack/pkg/react-router-dom.js";
export const AuthorizationEvaluate = ({
  clients,
  clientRoles,
  clientName,
  users,
  reset
}) => {
  const form = useFormContext();
  const {control} = form;
  const {t} = useTranslation("clients");
  const adminClient = useAdminClient();
  const realm = useRealm();
  const {clientId} = useParams();
  const [clientsDropdownOpen, setClientsDropdownOpen] = useState(false);
  const [scopesDropdownOpen, setScopesDropdownOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [roleDropdownOpen, setRoleDropdownOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [applyToResourceType, setApplyToResourceType] = useState(false);
  const [resources, setResources] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [selectedUser, setSelectedUser] = useState();
  useFetch(async () => Promise.all([
    adminClient.clients.listResources({
      id: clientId
    }),
    adminClient.clients.listAllScopes({
      id: clientId
    })
  ]), ([resources2, scopes2]) => {
    setResources(resources2);
    setScopes(scopes2);
  }, []);
  const evaluate = (formValues) => {
    const resEval = {
      roleIds: formValues.roleIds ?? [],
      userId: selectedUser?.id,
      entitlements: false,
      context: formValues.context,
      resources: formValues.resources,
      clientId: selectedClient?.id
    };
    return adminClient.clients.evaluateResource({id: clientId, realm: realm.realm}, resEval);
  };
  return /* @__PURE__ */ React.createElement(PageSection, null, /* @__PURE__ */ React.createElement(FormPanel, {
    className: "kc-identity-information",
    title: t("clients:identityInformation")
  }, /* @__PURE__ */ React.createElement(FormAccess, {
    isHorizontal: true,
    role: "manage-clients",
    onSubmit: form.handleSubmit(evaluate)
  }, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("client"),
    isRequired: true,
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:client",
      fieldLabelId: "clients:client"
    }),
    fieldId: "client"
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "client",
    defaultValue: clientName,
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "client",
      onToggle: setClientsDropdownOpen,
      onSelect: (_, value2) => {
        setSelectedClient(value2);
        onChange(value2.clientId);
        setClientsDropdownOpen(false);
      },
      selections: value,
      variant: SelectVariant.typeahead,
      "aria-label": t("client"),
      isOpen: clientsDropdownOpen
    }, clients.map((client) => /* @__PURE__ */ React.createElement(SelectOption, {
      selected: client === value,
      key: client.clientId,
      value: client
    }, client.clientId)))
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("user"),
    isRequired: true,
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:userSelect",
      fieldLabelId: "clients:userSelect"
    }),
    fieldId: "loginTheme"
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "userId",
    defaultValue: "",
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "user",
      placeholderText: t("selectAUser"),
      onToggle: setUserDropdownOpen,
      onSelect: (_, value2) => {
        setSelectedUser(value2);
        onChange(value2.username);
        setUserDropdownOpen(false);
      },
      selections: value,
      variant: SelectVariant.typeahead,
      "aria-label": t("user"),
      isOpen: userDropdownOpen
    }, users.map((user) => /* @__PURE__ */ React.createElement(SelectOption, {
      selected: user.username === value,
      key: user.username,
      value: user
    }, user.username)))
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("roles"),
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:roles",
      fieldLabelId: "clients:roles"
    }),
    fieldId: "realmRole"
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "rolesIds",
    placeholderText: t("selectARole"),
    control,
    defaultValue: [],
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      variant: SelectVariant.typeaheadMulti,
      toggleId: "role",
      onToggle: setRoleDropdownOpen,
      selections: value,
      onSelect: (_, v) => {
        const option = v.toString();
        if (value.includes(option)) {
          onChange(value.filter((item) => item !== option));
        } else {
          onChange([...value, option]);
        }
        setRoleDropdownOpen(false);
      },
      onClear: (event) => {
        event.stopPropagation();
        onChange([]);
      },
      "aria-label": t("realmRole"),
      isOpen: roleDropdownOpen
    }, clientRoles.map((role) => /* @__PURE__ */ React.createElement(SelectOption, {
      selected: role.name === value,
      key: role.name,
      value: role.name
    })))
  })))), /* @__PURE__ */ React.createElement(FormPanel, {
    className: "kc-permissions",
    title: t("permissions")
  }, /* @__PURE__ */ React.createElement(FormAccess, {
    isHorizontal: true,
    role: "manage-clients"
  }, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("applyToResourceType"),
    fieldId: "applyToResourceType",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:applyToResourceType",
      fieldLabelId: "clients:applyToResourceType"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "applyToResource",
    defaultValue: "",
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "applyToResource-switch",
      label: t("common:on"),
      labelOff: t("common:off"),
      isChecked: value === "true",
      onChange: (value2) => {
        onChange(value2.toString());
        setApplyToResourceType(value2);
      }
    })
  })), !applyToResourceType && /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("resourcesAndAuthScopes"),
    id: "resourcesAndAuthScopes",
    isRequired: true,
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: t("clients-help:contextualAttributes"),
      fieldLabelId: `resourcesAndAuthScopes`
    }),
    helperTextInvalid: t("common:required"),
    fieldId: name
  }, /* @__PURE__ */ React.createElement(AttributeInput, {
    selectableValues: resources.map((item) => item.name),
    resources,
    isKeySelectable: true,
    name: "resources"
  })), applyToResourceType && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("resourceType"),
    isRequired: true,
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:resourceType",
      fieldLabelId: "clients:resourceType"
    }),
    fieldId: "client"
  }, /* @__PURE__ */ React.createElement(TextInput, {
    type: "text",
    id: "alias",
    name: "alias",
    "data-testid": "alias",
    ref: form.register({required: true})
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("authScopes"),
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:scopesSelect",
      fieldLabelId: "clients:client"
    }),
    fieldId: "authScopes"
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "authScopes",
    defaultValue: [],
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "authScopes",
      onToggle: setScopesDropdownOpen,
      onSelect: (_, v) => {
        const option = v.toString();
        if (value.includes(option)) {
          onChange(value.filter((item) => item !== option));
        } else {
          onChange([...value, option]);
        }
        setScopesDropdownOpen(false);
      },
      selections: value,
      variant: SelectVariant.typeaheadMulti,
      "aria-label": t("authScopes"),
      isOpen: scopesDropdownOpen
    }, scopes.map((scope) => /* @__PURE__ */ React.createElement(SelectOption, {
      selected: scope.name === value,
      key: scope.id,
      value: scope.name
    })))
  }))), /* @__PURE__ */ React.createElement(ExpandableSection, {
    toggleText: t("contextualInfo"),
    onToggle: () => setIsExpanded(!isExpanded),
    isExpanded
  }, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("contextualAttributes"),
    id: "contextualAttributes",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: t("clients-help:contextualAttributes"),
      fieldLabelId: `contextualAttributes`
    }),
    helperTextInvalid: t("common:required"),
    fieldId: name
  }, /* @__PURE__ */ React.createElement(AttributeInput, {
    selectableValues: defaultContextAttributes.map((item) => item.name),
    isKeySelectable: true,
    name: "context"
  }))), /* @__PURE__ */ React.createElement(ActionGroup, null, /* @__PURE__ */ React.createElement(Button, {
    "data-testid": "authorization-eval",
    type: "submit"
  }, t("evaluate")), /* @__PURE__ */ React.createElement(Button, {
    "data-testid": "authorization-revert",
    variant: "link",
    onClick: reset
  }, t("common:revert")), /* @__PURE__ */ React.createElement(Button, {
    "data-testid": "authorization-revert",
    variant: "primary",
    onClick: reset,
    isDisabled: true
  }, t("lastEvaluation"))))));
};
