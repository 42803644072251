import React, {Children, Fragment} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {
  Grid,
  GridItem,
  JumpLinks,
  JumpLinksItem,
  PageSection
} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {mainPageContentId} from "../../App.js";
import {ScrollPanel} from "./ScrollPanel.js";
import {FormPanel} from "./FormPanel.js";
import "./scroll-form.css";
const spacesToHyphens = (string) => {
  return string.replace(/\s+/g, "-");
};
export const ScrollForm = ({
  sections,
  borders = false,
  children,
  ...rest
}) => {
  const {t} = useTranslation("common");
  const nodes = Children.toArray(children);
  return /* @__PURE__ */ React.createElement(Grid, {
    hasGutter: true,
    ...rest
  }, /* @__PURE__ */ React.createElement(GridItem, {
    span: 8
  }, sections.map((cat, index) => {
    const scrollId = spacesToHyphens(cat.toLowerCase());
    return /* @__PURE__ */ React.createElement(Fragment, {
      key: cat
    }, !borders && /* @__PURE__ */ React.createElement(ScrollPanel, {
      scrollId,
      title: cat
    }, nodes[index]), borders && /* @__PURE__ */ React.createElement(FormPanel, {
      scrollId,
      title: cat,
      className: "kc-form-panel__panel"
    }, nodes[index]));
  })), /* @__PURE__ */ React.createElement(GridItem, {
    span: 4
  }, /* @__PURE__ */ React.createElement(PageSection, {
    className: "kc-scroll-form--sticky"
  }, /* @__PURE__ */ React.createElement(JumpLinks, {
    isVertical: true,
    scrollableSelector: `#${mainPageContentId}`,
    label: t("jumpToSection"),
    offset: 100
  }, sections.map((cat) => {
    const scrollId = spacesToHyphens(cat.toLowerCase());
    return /* @__PURE__ */ React.createElement(JumpLinksItem, {
      key: cat,
      href: `#${scrollId}`,
      "data-testid": `jump-link-${scrollId}`
    }, cat);
  })))));
};
