import {
  Select,
  SelectOption,
  SelectVariant,
  Split,
  SplitItem,
  TextInput
} from "../../_snowpack/pkg/@patternfly/react-core.js";
import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
export const TimeSelector = ({
  value,
  units = ["seconds", "minutes", "hours", "days"],
  onChange,
  className,
  min,
  menuAppendTo,
  ...rest
}) => {
  const {t} = useTranslation("common");
  const allTimes = [
    {unit: "seconds", label: t("times.seconds"), multiplier: 1},
    {unit: "minutes", label: t("times.minutes"), multiplier: 60},
    {unit: "hours", label: t("times.hours"), multiplier: 3600},
    {unit: "days", label: t("times.days"), multiplier: 86400}
  ];
  const times = units.map((unit) => allTimes.find((time) => time.unit === unit));
  const defaultMultiplier = allTimes.find((time) => time.unit === units[0])?.multiplier;
  const [timeValue, setTimeValue] = useState("");
  const [multiplier, setMultiplier] = useState(defaultMultiplier);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const x = times.reduce((v, time) => value % time.multiplier === 0 && v < time.multiplier ? time.multiplier : v, 1);
    if (value) {
      setMultiplier(x);
      setTimeValue(value / x);
    } else {
      setTimeValue("");
      setMultiplier(defaultMultiplier);
    }
  }, [value]);
  const updateTimeout = (timeout, times2 = multiplier) => {
    if (timeout !== "") {
      onChange(timeout * (times2 || 1));
      setTimeValue(timeout);
    } else {
      onChange("");
    }
  };
  return /* @__PURE__ */ React.createElement(Split, {
    hasGutter: true,
    className
  }, /* @__PURE__ */ React.createElement(SplitItem, null, /* @__PURE__ */ React.createElement(TextInput, {
    ...rest,
    type: "number",
    "aria-label": "kc-time",
    min: min || 0,
    value: timeValue,
    className: `${className}-input`,
    onChange: (value2) => {
      updateTimeout(value2 === "" ? value2 : parseInt(value2));
    }
  })), /* @__PURE__ */ React.createElement(SplitItem, {
    id: `${className}-select-menu`
  }, /* @__PURE__ */ React.createElement(Select, {
    variant: SelectVariant.single,
    "aria-label": t("unitLabel"),
    className: `${className}-select`,
    onSelect: (_, value2) => {
      setMultiplier(value2);
      updateTimeout(timeValue, value2);
      setOpen(false);
    },
    menuAppendTo,
    selections: [multiplier],
    onToggle: () => {
      setOpen(!open);
    },
    isOpen: open
  }, times.map((time) => /* @__PURE__ */ React.createElement(SelectOption, {
    id: time.label,
    key: time.label,
    value: time.multiplier
  }, time.label)))));
};
