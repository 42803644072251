export default {
  common: {
    fullName: "{{givenName}} {{familyName}}",
    unknownUser: "Anonymous",
    add: "Add",
    yes: "Yes",
    no: "No",
    create: "Create",
    save: "Save",
    revert: "Revert",
    cancel: "Cancel",
    reload: "Reload",
    continue: "Continue",
    close: "Close",
    delete: "Delete",
    remove: "Remove",
    search: "Search",
    key: "Key",
    value: "Value",
    noSearchResults: "No search results",
    noSearchResultsInstructions: "Click on the search bar above to search",
    next: "Next",
    back: "Back",
    finish: "Finish",
    skipCustomizationAndFinish: "Skip customization and finish",
    export: "Export",
    action: "Action",
    download: "Download",
    resourceFile: "Resource file",
    clear: "Clear",
    clearFile: "Clear this file",
    clearFileExplain: "Are you sure you want to clear this file?",
    on: "On",
    off: "Off",
    edit: "Edit",
    enabled: "Enabled",
    disabled: "Disabled",
    disable: "Disable",
    selectOne: "Select an option",
    select: "Select",
    choose: "Choose...",
    any: "Any",
    none: "None",
    signOut: "Sign out",
    manageAccount: "Manage account",
    serverInfo: "Server info",
    realmInfo: "Realm info",
    help: "Help",
    helpLabel: "More help for '{{label}}'",
    helpEnabled: "Help on",
    helpDisabled: "Help off",
    documentation: "Documentation",
    enableHelpMode: "Enable help mode",
    learnMore: "Learn more",
    show: "Show",
    hide: "Hide",
    showRemaining: "Show ${remaining}",
    more: "{{count}} more",
    test: "Test",
    testConnection: "Test connection",
    name: "Name",
    role: "Role",
    description: "Description",
    type: "Type",
    category: "Category",
    priority: "Priority",
    unexpectedError: "An unexpected error occurred: '{{error}}'",
    retry: "Press here to refresh and continue",
    plus: "Plus",
    minus: "Minus",
    clientScope: {
      default: "Default",
      optional: "Optional",
      none: "None"
    },
    allTypes: "All types",
    home: "Home",
    manage: "Manage",
    clients: "Clients",
    clientScopes: "Client scopes",
    realmRoles: "Realm roles",
    clientRoles: "Client roles",
    users: "Users",
    groups: "Groups",
    sessions: "Sessions",
    events: "Events",
    mappers: "Mappers",
    configure: "Configure",
    realmSettings: "Realm settings",
    authentication: "Authentication",
    identityProviders: "Identity providers",
    userFederation: "User federation",
    settings: "Settings",
    details: "Details",
    required: "Required field",
    maxLength: "Max length {{length}}",
    createRealm: "Create Realm",
    recent: "Recent",
    jumpToSection: "Jump to section",
    Sunday: "Sunday",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    unitLabel: "Select a time unit",
    times: {
      seconds: "Seconds",
      minutes: "Minutes",
      hours: "Hours",
      days: "Days",
      years: "Years"
    },
    attributes: "Attributes",
    credentials: "Credentials",
    clientId: "Client ID",
    id: "ID",
    addMapper: "Add mapper",
    createNewMapper: "Create new mapper",
    searchForMapper: "Search for mapper",
    mapperType: "Mapper type",
    mappingDeletedSuccess: "Mapping successfully deleted",
    mappingDeletedError: "Could not delete mapping: '{{error}}'",
    mappingDetails: "Mapper details",
    mappingUpdatedSuccess: "Mapping successfully updated",
    mappingUpdatedError: "Could not update mapping: '{{error}}'",
    mappingCreatedSuccess: "Mapping successfully created",
    mappingCreatedError: "Could not create mapping: '{{error}}'",
    deleteMappingTitle: "Delete mapping?",
    deleteMappingConfirm: "Are you sure you want to delete this mapping?",
    emptyMappers: "No mappers",
    emptyMappersInstructions: "If you want to add mappers, please click the button below to add some predefined mappers or to configure a new mapper.",
    emptyPrimaryAction: "Add predefined mapper",
    leaveDirtyTitle: "Leave without saving?",
    leaveDirtyConfirm: "Do you want to leave this page without saving? Any unsaved changes will be lost.",
    leave: "Leave",
    reorder: "Reorder",
    onDragStart: "Dragging started for item {{item}}",
    onDragMove: "Dragging item {{item}}",
    onDragCancel: "Dragging cancelled. List is unchanged.",
    onDragFinish: "Dragging finished {{list}}",
    notFound: "Could not find the resource that you are looking for",
    password: "Password",
    passwordConfirmation: "Password confirmation",
    temporaryPassword: "Temporary",
    temporaryPasswordHelpText: "If enabled, the user must change the password on next login"
  }
};
