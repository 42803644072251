import * as __SNOWPACK_ENV__ from './_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

import "./index.css";
import React, {StrictMode} from "./_snowpack/pkg/react.js";
import ReactDOM from "./_snowpack/pkg/react-dom.js";
import {App} from "./App.js";
import i18n from "./i18n.js";
import init from "./context/auth/keycloak.js";
console.info("supported languages", ...i18n.languages);
init().then((adminClient) => {
  ReactDOM.render(/* @__PURE__ */ React.createElement(StrictMode, null, /* @__PURE__ */ React.createElement(App, {
    adminClient
  })), document.getElementById("app"));
});
if (undefined /* [snowpack] import.meta.hot */ ) {
  undefined /* [snowpack] import.meta.hot */ .accept();
}
