import React from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {useFormContext} from "../../_snowpack/pkg/react-hook-form.js";
import {
  FormGroup,
  TextArea,
  TextInput,
  ValidatedOptions
} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {HelpItem} from "../../components/help-enabler/HelpItem.js";
export const NameDescription = () => {
  const {t} = useTranslation("authentication");
  const {register, errors} = useFormContext();
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("common:name"),
    fieldId: "kc-name",
    helperTextInvalid: t("common:required"),
    validated: errors.alias ? ValidatedOptions.error : ValidatedOptions.default,
    isRequired: true,
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "authentication-help:name",
      fieldLabelId: "name"
    })
  }, /* @__PURE__ */ React.createElement(TextInput, {
    type: "text",
    id: "kc-name",
    name: "alias",
    "data-testid": "alias",
    ref: register({required: true}),
    validated: errors.alias ? ValidatedOptions.error : ValidatedOptions.default
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("common:description"),
    fieldId: "kc-description",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "authentication-help:description",
      fieldLabelId: "description"
    }),
    validated: errors.description ? ValidatedOptions.error : ValidatedOptions.default,
    helperTextInvalid: errors.description?.message
  }, /* @__PURE__ */ React.createElement(TextArea, {
    ref: register({
      maxLength: {
        value: 255,
        message: t("common:maxLength", {length: 255})
      }
    }),
    type: "text",
    id: "kc-description",
    name: "description",
    "data-testid": "description",
    validated: errors.description ? ValidatedOptions.error : ValidatedOptions.default
  })));
};
