import React, {useEffect, useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {Controller, useFieldArray, useFormContext} from "../../_snowpack/pkg/react-hook-form.js";
import {
  Button,
  Select,
  SelectOption,
  SelectVariant,
  TextInput
} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {
  TableComposable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "../../_snowpack/pkg/@patternfly/react-table.js";
import {MinusCircleIcon, PlusCircleIcon} from "../../_snowpack/pkg/@patternfly/react-icons.js";
import "../attribute-form/attribute-form.css";
import {defaultContextAttributes} from "../../clients/utils.js";
import {camelCase} from "../../_snowpack/pkg/lodash-es.js";
export const AttributeInput = ({
  name,
  isKeySelectable,
  selectableValues,
  resources
}) => {
  const {t} = useTranslation("common");
  const {control, register, watch, getValues} = useFormContext();
  const {fields, append, remove} = useFieldArray({
    control,
    name
  });
  useEffect(() => {
    if (!fields.length) {
      append({key: "", value: ""});
    }
  }, []);
  const [isKeyOpenArray, setIsKeyOpenArray] = useState([false]);
  const watchLastKey = watch(`${name}[${fields.length - 1}].key`, "");
  const watchLastValue = watch(`${name}[${fields.length - 1}].value`, "");
  const [isValueOpenArray, setIsValueOpenArray] = useState([false]);
  const toggleKeySelect = (rowIndex, open) => {
    const arr = [...isKeyOpenArray];
    arr[rowIndex] = open;
    setIsKeyOpenArray(arr);
  };
  const toggleValueSelect = (rowIndex, open) => {
    const arr = [...isValueOpenArray];
    arr[rowIndex] = open;
    setIsValueOpenArray(arr);
  };
  const renderValueInput = (rowIndex, attribute) => {
    let attributeValues = [];
    const scopeValues = resources?.find((resource) => resource.name === getValues().resources[rowIndex]?.key)?.scopes;
    if (selectableValues) {
      attributeValues = defaultContextAttributes.find((attr) => attr.name === getValues().context[rowIndex]?.key)?.values;
    }
    const getMessageBundleKey = (attributeName) => camelCase(attributeName).replace(/\W/g, "");
    return /* @__PURE__ */ React.createElement(Td, null, scopeValues?.length || attributeValues?.length ? /* @__PURE__ */ React.createElement(Controller, {
      name: `${name}[${rowIndex}].value`,
      defaultValue: [],
      control,
      render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
        id: `${attribute.id}-value`,
        className: "kc-attribute-value-selectable",
        name: `${name}[${rowIndex}].value`,
        chipGroupProps: {
          numChips: 1,
          expandedText: t("common:hide"),
          collapsedText: t("common:showRemaining")
        },
        toggleId: `group-${name}`,
        onToggle: (open) => toggleValueSelect(rowIndex, open),
        isOpen: isValueOpenArray[rowIndex],
        variant: resources ? SelectVariant.typeaheadMulti : SelectVariant.typeahead,
        typeAheadAriaLabel: t("clients:selectOrTypeAKey"),
        placeholderText: t("clients:selectOrTypeAKey"),
        selections: value,
        onSelect: (_, v) => {
          if (resources) {
            const option = v.toString();
            if (value.includes(option)) {
              onChange(value.filter((item) => item !== option));
            } else {
              onChange([...value, option]);
            }
          } else {
            onChange(v);
          }
          toggleValueSelect(rowIndex, false);
        }
      }, (scopeValues || attributeValues)?.map((scope) => /* @__PURE__ */ React.createElement(SelectOption, {
        key: scope.name,
        value: scope.name
      })))
    }) : /* @__PURE__ */ React.createElement(TextInput, {
      id: `${getMessageBundleKey(attribute.key)}-value`,
      className: "value-input",
      name: `${name}[${rowIndex}].value`,
      ref: register(),
      defaultValue: attribute.value,
      "data-testid": "attribute-value-input"
    }));
  };
  return /* @__PURE__ */ React.createElement(TableComposable, {
    className: "kc-attributes__table",
    "aria-label": "Role attribute keys and values",
    variant: "compact",
    borders: false
  }, /* @__PURE__ */ React.createElement(Thead, null, /* @__PURE__ */ React.createElement(Tr, null, /* @__PURE__ */ React.createElement(Th, {
    id: "key",
    width: 40
  }, t("key")), /* @__PURE__ */ React.createElement(Th, {
    id: "value",
    width: 40
  }, t("value")))), /* @__PURE__ */ React.createElement(Tbody, null, fields.map((attribute, rowIndex) => /* @__PURE__ */ React.createElement(Tr, {
    key: attribute.id,
    "data-testid": "attribute-row"
  }, /* @__PURE__ */ React.createElement(Td, null, isKeySelectable ? /* @__PURE__ */ React.createElement(Controller, {
    name: `${name}[${rowIndex}].key`,
    defaultValue: attribute.key,
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      id: `${name}[${rowIndex}].key`,
      className: "kc-attribute-key-selectable",
      name: `${name}[${rowIndex}].key`,
      toggleId: `group-${name}`,
      onToggle: (open) => toggleKeySelect(rowIndex, open),
      isOpen: isKeyOpenArray[rowIndex],
      variant: SelectVariant.typeahead,
      typeAheadAriaLabel: t("clients:selectOrTypeAKey"),
      placeholderText: t("clients:selectOrTypeAKey"),
      selections: value,
      onSelect: (_, v) => {
        onChange(v);
        toggleKeySelect(rowIndex, false);
      }
    }, selectableValues?.map((attribute2) => /* @__PURE__ */ React.createElement(SelectOption, {
      selected: attribute2 === value,
      key: attribute2,
      value: attribute2
    }, attribute2)))
  }) : /* @__PURE__ */ React.createElement(TextInput, {
    id: `${attribute.id}-key`,
    name: `${name}[${rowIndex}].key`,
    ref: register(),
    defaultValue: attribute.key,
    "data-testid": "attribute-key-input"
  })), renderValueInput(rowIndex, attribute), /* @__PURE__ */ React.createElement(Td, {
    key: "minus-button",
    id: `kc-minus-button-${rowIndex}`
  }, /* @__PURE__ */ React.createElement(Button, {
    id: `minus-button-${rowIndex}`,
    variant: "link",
    className: "kc-attributes__minus-icon",
    onClick: () => remove(rowIndex)
  }, /* @__PURE__ */ React.createElement(MinusCircleIcon, null))))), /* @__PURE__ */ React.createElement(Tr, null, /* @__PURE__ */ React.createElement(Td, null, /* @__PURE__ */ React.createElement(Button, {
    "aria-label": t("roles:addAttributeText"),
    id: "plus-icon",
    variant: "link",
    className: "kc-attributes__plus-icon",
    onClick: () => {
      append({key: "", value: ""});
      if (isKeySelectable) {
        setIsKeyOpenArray([...isKeyOpenArray, false]);
      }
    },
    icon: /* @__PURE__ */ React.createElement(PlusCircleIcon, null),
    isDisabled: isKeySelectable ? !watchLastValue : !watchLastKey,
    "data-testid": "attribute-add-row"
  }, t("roles:addAttributeText"))))));
};
