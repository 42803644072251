import {lazy} from "../../_snowpack/pkg/react.js";
import {generatePath} from "../../_snowpack/pkg/react-router-dom.js";
export const RsaGeneratedSettingsRoute = {
  path: "/:realm/realm-settings/keys/providers/:id/rsa-generated/settings",
  component: lazy(() => import("../key-providers/rsa-generated/RSAGeneratedForm.js")),
  breadcrumb: (t) => t("realm-settings:editProvider"),
  access: "view-realm"
};
export const toRsaGeneratedSettings = (params) => ({
  pathname: generatePath(RsaGeneratedSettingsRoute.path, params)
});
