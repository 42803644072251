import React from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {FormGroup, TextInput} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {HelpItem} from "../../components/help-enabler/HelpItem.js";
import {PasswordInput} from "../../components/password-input/PasswordInput.js";
export const StoreSettings = ({
  register,
  hidePassword = false
}) => {
  const {t} = useTranslation("clients");
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("keyAlias"),
    fieldId: "keyAlias",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:keyAlias",
      fieldLabelId: "clients:keyAlias"
    })
  }, /* @__PURE__ */ React.createElement(TextInput, {
    "data-testid": "keyAlias",
    type: "text",
    id: "keyAlias",
    name: "keyAlias",
    ref: register
  })), !hidePassword && /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("keyPassword"),
    fieldId: "keyPassword",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:keyPassword",
      fieldLabelId: "clients:keyPassword"
    })
  }, /* @__PURE__ */ React.createElement(PasswordInput, {
    "data-testid": "keyPassword",
    id: "keyPassword",
    name: "keyPassword",
    ref: register
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("storePassword"),
    fieldId: "storePassword",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:storePassword",
      fieldLabelId: "clients:storePassword"
    })
  }, /* @__PURE__ */ React.createElement(PasswordInput, {
    "data-testid": "storePassword",
    id: "storePassword",
    name: "storePassword",
    ref: register
  })));
};
