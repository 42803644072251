import React, {useState} from "../../../_snowpack/pkg/react.js";
import {
  ActionGroup,
  AlertVariant,
  Button,
  FormGroup,
  PageSection,
  Select,
  SelectOption,
  SelectVariant,
  Switch,
  TextInput,
  ValidatedOptions
} from "../../../_snowpack/pkg/@patternfly/react-core.js";
import {useTranslation} from "../../../_snowpack/pkg/react-i18next.js";
import {Controller, useForm} from "../../../_snowpack/pkg/react-hook-form.js";
import {HelpItem} from "../../../components/help-enabler/HelpItem.js";
import {useServerInfo} from "../../../context/server-info/ServerInfoProvider.js";
import {useAdminClient, useFetch} from "../../../context/auth/AdminClient.js";
import {useParams, useRouteMatch} from "../../../_snowpack/pkg/react-router-dom.js";
import {FormAccess} from "../../../components/form-access/FormAccess.js";
import {ViewHeader} from "../../../components/view-header/ViewHeader.js";
import {convertToFormValues, KEY_PROVIDER_TYPE} from "../../../util.js";
import {useAlerts} from "../../../components/alert/Alerts.js";
export const RSAGeneratedForm = ({
  editMode,
  providerType,
  handleModalToggle,
  refresh
}) => {
  const {t} = useTranslation("realm-settings");
  const serverInfo = useServerInfo();
  const [isKeySizeDropdownOpen, setIsKeySizeDropdownOpen] = useState(false);
  const [isEllipticCurveDropdownOpen, setIsEllipticCurveDropdownOpen] = useState(false);
  const adminClient = useAdminClient();
  const {addAlert, addError} = useAlerts();
  const {id} = useParams();
  const providerId = useRouteMatch("/:providerType?")?.params.providerType;
  const save = async (component) => {
    try {
      if (id) {
        await adminClient.components.update({id}, {
          ...component,
          parentId: component.parentId,
          providerId: providerType,
          providerType: KEY_PROVIDER_TYPE
        });
        addAlert(t("saveProviderSuccess"), AlertVariant.success);
      } else {
        await adminClient.components.create({
          ...component,
          parentId: component.parentId,
          providerId: providerType,
          providerType: KEY_PROVIDER_TYPE,
          config: {priority: ["0"]}
        });
        handleModalToggle?.();
        addAlert(t("saveProviderSuccess"), AlertVariant.success);
        refresh?.();
      }
    } catch (error) {
      addError("realm-settings:saveProviderError", error);
    }
  };
  const form = useForm({mode: "onChange"});
  const setupForm = (component) => {
    form.reset();
    convertToFormValues(component, form.setValue);
  };
  useFetch(async () => {
    if (editMode)
      return await adminClient.components.findOne({id});
  }, (result) => {
    if (result) {
      setupForm(result);
    }
  }, []);
  const allComponentTypes = serverInfo.componentTypes?.[KEY_PROVIDER_TYPE] ?? [];
  const rsaGeneratedKeySizeOptions = allComponentTypes[5].properties[4].options;
  const rsaGeneratedAlgorithmOptions = allComponentTypes[5].properties[3].options;
  return /* @__PURE__ */ React.createElement(FormAccess, {
    isHorizontal: true,
    id: "add-provider",
    className: "pf-u-mt-lg",
    role: "manage-realm",
    onSubmit: form.handleSubmit(save)
  }, editMode && /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("providerId"),
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "client-scopes-help:mapperName",
      fieldLabelId: "client-scopes:providerId"
    }),
    fieldId: "id",
    isRequired: true,
    validated: form.errors.name ? ValidatedOptions.error : ValidatedOptions.default,
    helperTextInvalid: t("common:required")
  }, /* @__PURE__ */ React.createElement(TextInput, {
    ref: form.register(),
    id: "id",
    type: "text",
    name: "id",
    isReadOnly: editMode,
    "aria-label": t("consoleDisplayName"),
    defaultValue: id,
    "data-testid": "display-name-input"
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("common:name"),
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "client-scopes-help:mapperName",
      fieldLabelId: "name"
    }),
    fieldId: "name",
    isRequired: true,
    validated: form.errors.name ? ValidatedOptions.error : ValidatedOptions.default,
    helperTextInvalid: t("common:required")
  }, !editMode && /* @__PURE__ */ React.createElement(Controller, {
    name: "name",
    control: form.control,
    defaultValue: providerType,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(TextInput, {
      id: "name",
      type: "text",
      "aria-label": t("consoleDisplayName"),
      value,
      onChange: (value2) => onChange(value2),
      "data-testid": "display-name-input"
    })
  }), editMode && /* @__PURE__ */ React.createElement(TextInput, {
    ref: form.register(),
    type: "text",
    id: "name",
    name: "name",
    defaultValue: providerId,
    validated: form.errors.name ? ValidatedOptions.error : ValidatedOptions.default
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("common:enabled"),
    fieldId: "kc-enabled",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "realm-settings-help:enabled",
      fieldLabelId: "enabled"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "config.enabled",
    control: form.control,
    defaultValue: ["true"],
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "kc-enabled-switch",
      label: t("common:on"),
      labelOff: t("common:off"),
      isChecked: value[0] === "true",
      "data-testid": value[0] === "true" ? "enabled" : "disabled",
      onChange: (value2) => {
        onChange([value2.toString()]);
      }
    })
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("active"),
    fieldId: "kc-active",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "realm-settings-help:active",
      fieldLabelId: "realm-settings:active"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "config.active",
    control: form.control,
    defaultValue: ["true"],
    render: ({onChange, value}) => {
      return /* @__PURE__ */ React.createElement(Switch, {
        id: "kc-active-switch",
        label: t("common:on"),
        labelOff: t("common:off"),
        isChecked: value[0] === "true",
        "data-testid": value[0] === "true" ? "active" : "passive",
        onChange: (value2) => {
          onChange([value2.toString()]);
        }
      });
    }
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("secretSize"),
    fieldId: "kc-rsa-generated-keysize",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "realm-settings-help:secretSize",
      fieldLabelId: "realm-settings:secretSize"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "config.secretSize",
    control: form.control,
    defaultValue: ["2048"],
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "kc-rsa-generated-keysize",
      onToggle: (isExpanded) => setIsKeySizeDropdownOpen(isExpanded),
      onSelect: (_, value2) => {
        onChange([value2.toString()]);
        setIsKeySizeDropdownOpen(false);
      },
      selections: [value.toString()],
      isOpen: isKeySizeDropdownOpen,
      variant: SelectVariant.single,
      "aria-label": t("KeySize"),
      "data-testid": "select-secret-size"
    }, rsaGeneratedKeySizeOptions.map((item) => /* @__PURE__ */ React.createElement(SelectOption, {
      selected: item === value,
      key: item,
      value: item
    })))
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("algorithm"),
    fieldId: "kc-algorithm",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "realm-settings-help:algorithm",
      fieldLabelId: "realm-settings:algorithm"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "config.algorithm",
    control: form.control,
    defaultValue: ["RS256"],
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "kc-elliptic",
      onToggle: (isExpanded) => setIsEllipticCurveDropdownOpen(isExpanded),
      onSelect: (_, value2) => {
        onChange([value2.toString()]);
        setIsEllipticCurveDropdownOpen(false);
      },
      selections: [value.toString()],
      variant: SelectVariant.single,
      "aria-label": t("emailTheme"),
      isOpen: isEllipticCurveDropdownOpen,
      placeholderText: "Select one...",
      "data-testid": "select-email-theme"
    }, rsaGeneratedAlgorithmOptions.map((p, idx) => /* @__PURE__ */ React.createElement(SelectOption, {
      selected: p === value,
      key: `email-theme-${idx}`,
      value: p
    })))
  })), /* @__PURE__ */ React.createElement(ActionGroup, {
    className: "kc-rsa-generated-form-buttons"
  }, /* @__PURE__ */ React.createElement(Button, {
    className: "kc-rsa-generated-form-save-button",
    "data-testid": "add-provider-button",
    variant: "primary",
    type: "submit"
  }, t("common:save")), /* @__PURE__ */ React.createElement(Button, {
    className: "kc-rsa-generated-form-cancel-button",
    onClick: !editMode && handleModalToggle || void 0,
    variant: "link"
  }, t("common:cancel"))));
};
export default function RSAGeneratedSettings() {
  const {t} = useTranslation("realm-settings");
  const providerId = useRouteMatch("/:realm/realm-settings/keys/:id?/:providerType?/settings")?.params.providerType;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ViewHeader, {
    titleKey: t("editProvider"),
    subKey: providerId
  }), /* @__PURE__ */ React.createElement(PageSection, {
    variant: "light"
  }, /* @__PURE__ */ React.createElement(RSAGeneratedForm, {
    providerType: providerId,
    editMode: true
  })));
}
