import {lazy} from "../../_snowpack/pkg/react.js";
import {generatePath} from "../../_snowpack/pkg/react-router-dom.js";
export const HmacGeneratedSettingsRoute = {
  path: "/:realm/realm-settings/keys/providers/:id/hmac-generated/settings",
  component: lazy(() => import("../key-providers/hmac-generated/HMACGeneratedForm.js")),
  breadcrumb: (t) => t("realm-settings:editProvider"),
  access: "view-realm"
};
export const toHmacGeneratedSettings = (params) => ({
  pathname: generatePath(HmacGeneratedSettingsRoute.path, params)
});
