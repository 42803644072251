import React from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {useFormContext} from "../../_snowpack/pkg/react-hook-form.js";
import {AlertVariant} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {KeyForm} from "./GenerateKeyDialog.js";
import {useAdminClient} from "../../context/auth/AdminClient.js";
import {useAlerts} from "../../components/alert/Alerts.js";
import {submitForm} from "./SamlKeysDialog.js";
import {ConfirmDialogModal} from "../../components/confirm-dialog/ConfirmDialog.js";
export const SamlImportKeyDialog = ({
  id,
  attr,
  onClose
}) => {
  const {t} = useTranslation("clients");
  const {register, control, handleSubmit} = useFormContext();
  const adminClient = useAdminClient();
  const {addAlert, addError} = useAlerts();
  const submit = (form) => {
    submitForm(form, id, attr, adminClient, (error) => {
      if (error) {
        addError("clients:importError", error);
      } else {
        addAlert(t("importSuccess"), AlertVariant.success);
      }
    });
  };
  return /* @__PURE__ */ React.createElement(ConfirmDialogModal, {
    open: true,
    toggleDialog: onClose,
    continueButtonLabel: "clients:import",
    titleKey: "clients:importKey",
    onConfirm: () => {
      handleSubmit(submit)();
      onClose();
    }
  }, /* @__PURE__ */ React.createElement(KeyForm, {
    register,
    control,
    useFile: true
  }));
};
