import React from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {useFormContext} from "../../_snowpack/pkg/react-hook-form.js";
import {ActionGroup, Button} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {FormAccess} from "../../components/form-access/FormAccess.js";
import {HelpLinkTextInput} from "./HelpLinkTextInput.js";
import "./security-defences.css";
export const HeadersForm = ({save, reset}) => {
  const {t} = useTranslation();
  const {
    formState: {isDirty},
    handleSubmit
  } = useFormContext();
  return /* @__PURE__ */ React.createElement(FormAccess, {
    isHorizontal: true,
    role: "manage-realm",
    className: "keycloak__security-defences__form",
    onSubmit: handleSubmit(save)
  }, /* @__PURE__ */ React.createElement(HelpLinkTextInput, {
    fieldName: "browserSecurityHeaders.xFrameOptions",
    url: "http://tools.ietf.org/html/rfc7034"
  }), /* @__PURE__ */ React.createElement(HelpLinkTextInput, {
    fieldName: "browserSecurityHeaders.contentSecurityPolicy",
    url: "http://www.w3.org/TR/CSP/"
  }), /* @__PURE__ */ React.createElement(HelpLinkTextInput, {
    fieldName: "browserSecurityHeaders.contentSecurityPolicyReportOnly",
    url: "http://www.w3.org/TR/CSP/"
  }), /* @__PURE__ */ React.createElement(HelpLinkTextInput, {
    fieldName: "browserSecurityHeaders.xContentTypeOptions",
    url: "https://www.owasp.org/index.php/List_of_useful_HTTP_headers"
  }), /* @__PURE__ */ React.createElement(HelpLinkTextInput, {
    fieldName: "browserSecurityHeaders.xRobotsTag",
    url: "https://developers.google.com/webmasters/control-crawl-index/docs/robots_meta_tag"
  }), /* @__PURE__ */ React.createElement(HelpLinkTextInput, {
    fieldName: "browserSecurityHeaders.xXSSProtection",
    url: "https://www.owasp.org/index.php/OWASP_Secure_Headers_Project#xxxsp"
  }), /* @__PURE__ */ React.createElement(HelpLinkTextInput, {
    fieldName: "browserSecurityHeaders.strictTransportSecurity",
    url: "https://www.owasp.org/index.php/OWASP_Secure_Headers_Project#hsts"
  }), /* @__PURE__ */ React.createElement(ActionGroup, null, /* @__PURE__ */ React.createElement(Button, {
    variant: "primary",
    type: "submit",
    "data-testid": "headers-form-tab-save",
    isDisabled: !isDirty
  }, t("common:save")), /* @__PURE__ */ React.createElement(Button, {
    variant: "link",
    onClick: reset
  }, t("common:revert"))));
};
