import { r as react } from './index-e2560c44.js';
import { f as canUseDOM, n as keyHandler, c as css, q as formatBreakpointMods, k as getOUIAProps, P as Popper, D as useOUIAId, F as KEYHANDLER_DIRECTION, G as preventedEvents, b as Tooltip, u as useOUIAProps, K as KEY_CODES, H as trimLeft, l as getDefaultOUIAId, g as getResizeObserver, V as ValidatedOptions, T as Title, C as TitleSizes, I as FindRefWrapper, B as Button, h as getUniqueId, J as getOpacityTransition } from './EmptyState-13cdb7ce.js';
import { g as getDefaultExportFromCjs, a as createCommonjsModule } from './_commonjsHelpers-7446a541.js';
import { _ as __rest, c as createIcon } from './createIcon-8065a13b.js';
import { r as reactDom } from './index-076cd651.js';
import { T as TimesIcon } from './check-icon-fbfa463a.js';

/*!
* tabbable 5.2.1
* @license MIT, https://github.com/focus-trap/tabbable/blob/master/LICENSE
*/
var candidateSelectors = ['input', 'select', 'textarea', 'a[href]', 'button', '[tabindex]', 'audio[controls]', 'video[controls]', '[contenteditable]:not([contenteditable="false"])', 'details>summary:first-of-type', 'details'];
var candidateSelector = /* #__PURE__ */candidateSelectors.join(',');
var matches = typeof Element === 'undefined' ? function () {} : Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;

var getCandidates = function getCandidates(el, includeContainer, filter) {
  var candidates = Array.prototype.slice.apply(el.querySelectorAll(candidateSelector));

  if (includeContainer && matches.call(el, candidateSelector)) {
    candidates.unshift(el);
  }

  candidates = candidates.filter(filter);
  return candidates;
};

var isContentEditable = function isContentEditable(node) {
  return node.contentEditable === 'true';
};

var getTabindex = function getTabindex(node) {
  var tabindexAttr = parseInt(node.getAttribute('tabindex'), 10);

  if (!isNaN(tabindexAttr)) {
    return tabindexAttr;
  } // Browsers do not return `tabIndex` correctly for contentEditable nodes;
  // so if they don't have a tabindex attribute specifically set, assume it's 0.


  if (isContentEditable(node)) {
    return 0;
  } // in Chrome, <details/>, <audio controls/> and <video controls/> elements get a default
  //  `tabIndex` of -1 when the 'tabindex' attribute isn't specified in the DOM,
  //  yet they are still part of the regular tab order; in FF, they get a default
  //  `tabIndex` of 0; since Chrome still puts those elements in the regular tab
  //  order, consider their tab index to be 0.


  if ((node.nodeName === 'AUDIO' || node.nodeName === 'VIDEO' || node.nodeName === 'DETAILS') && node.getAttribute('tabindex') === null) {
    return 0;
  }

  return node.tabIndex;
};

var sortOrderedTabbables = function sortOrderedTabbables(a, b) {
  return a.tabIndex === b.tabIndex ? a.documentOrder - b.documentOrder : a.tabIndex - b.tabIndex;
};

var isInput = function isInput(node) {
  return node.tagName === 'INPUT';
};

var isHiddenInput = function isHiddenInput(node) {
  return isInput(node) && node.type === 'hidden';
};

var isDetailsWithSummary = function isDetailsWithSummary(node) {
  var r = node.tagName === 'DETAILS' && Array.prototype.slice.apply(node.children).some(function (child) {
    return child.tagName === 'SUMMARY';
  });
  return r;
};

var getCheckedRadio = function getCheckedRadio(nodes, form) {
  for (var i = 0; i < nodes.length; i++) {
    if (nodes[i].checked && nodes[i].form === form) {
      return nodes[i];
    }
  }
};

var isTabbableRadio = function isTabbableRadio(node) {
  if (!node.name) {
    return true;
  }

  var radioScope = node.form || node.ownerDocument;

  var queryRadios = function queryRadios(name) {
    return radioScope.querySelectorAll('input[type="radio"][name="' + name + '"]');
  };

  var radioSet;

  if (typeof window !== 'undefined' && typeof window.CSS !== 'undefined' && typeof window.CSS.escape === 'function') {
    radioSet = queryRadios(window.CSS.escape(node.name));
  } else {
    try {
      radioSet = queryRadios(node.name);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Looks like you have a radio button with a name attribute containing invalid CSS selector characters and need the CSS.escape polyfill: %s', err.message);
      return false;
    }
  }

  var checked = getCheckedRadio(radioSet, node.form);
  return !checked || checked === node;
};

var isRadio = function isRadio(node) {
  return isInput(node) && node.type === 'radio';
};

var isNonTabbableRadio = function isNonTabbableRadio(node) {
  return isRadio(node) && !isTabbableRadio(node);
};

var isHidden = function isHidden(node, displayCheck) {
  if (getComputedStyle(node).visibility === 'hidden') {
    return true;
  }

  var isDirectSummary = matches.call(node, 'details>summary:first-of-type');
  var nodeUnderDetails = isDirectSummary ? node.parentElement : node;

  if (matches.call(nodeUnderDetails, 'details:not([open]) *')) {
    return true;
  }

  if (!displayCheck || displayCheck === 'full') {
    while (node) {
      if (getComputedStyle(node).display === 'none') {
        return true;
      }

      node = node.parentElement;
    }
  } else if (displayCheck === 'non-zero-area') {
    var _node$getBoundingClie = node.getBoundingClientRect(),
        width = _node$getBoundingClie.width,
        height = _node$getBoundingClie.height;

    return width === 0 && height === 0;
  }

  return false;
}; // form fields (nested) inside a disabled fieldset are not focusable/tabbable
//  unless they are in the _first_ <legend> element of the top-most disabled
//  fieldset


var isDisabledFromFieldset = function isDisabledFromFieldset(node) {
  if (isInput(node) || node.tagName === 'SELECT' || node.tagName === 'TEXTAREA' || node.tagName === 'BUTTON') {
    var parentNode = node.parentElement;

    while (parentNode) {
      if (parentNode.tagName === 'FIELDSET' && parentNode.disabled) {
        // look for the first <legend> as an immediate child of the disabled
        //  <fieldset>: if the node is in that legend, it'll be enabled even
        //  though the fieldset is disabled; otherwise, the node is in a
        //  secondary/subsequent legend, or somewhere else within the fieldset
        //  (however deep nested) and it'll be disabled
        for (var i = 0; i < parentNode.children.length; i++) {
          var child = parentNode.children.item(i);

          if (child.tagName === 'LEGEND') {
            if (child.contains(node)) {
              return false;
            } // the node isn't in the first legend (in doc order), so no matter
            //  where it is now, it'll be disabled


            return true;
          }
        } // the node isn't in a legend, so no matter where it is now, it'll be disabled


        return true;
      }

      parentNode = parentNode.parentElement;
    }
  } // else, node's tabbable/focusable state should not be affected by a fieldset's
  //  enabled/disabled state


  return false;
};

var isNodeMatchingSelectorFocusable = function isNodeMatchingSelectorFocusable(options, node) {
  if (node.disabled || isHiddenInput(node) || isHidden(node, options.displayCheck) || // For a details element with a summary, the summary element gets the focus
  isDetailsWithSummary(node) || isDisabledFromFieldset(node)) {
    return false;
  }

  return true;
};

var isNodeMatchingSelectorTabbable = function isNodeMatchingSelectorTabbable(options, node) {
  if (!isNodeMatchingSelectorFocusable(options, node) || isNonTabbableRadio(node) || getTabindex(node) < 0) {
    return false;
  }

  return true;
};

var tabbable = function tabbable(el, options) {
  options = options || {};
  var regularTabbables = [];
  var orderedTabbables = [];
  var candidates = getCandidates(el, options.includeContainer, isNodeMatchingSelectorTabbable.bind(null, options));
  candidates.forEach(function (candidate, i) {
    var candidateTabindex = getTabindex(candidate);

    if (candidateTabindex === 0) {
      regularTabbables.push(candidate);
    } else {
      orderedTabbables.push({
        documentOrder: i,
        tabIndex: candidateTabindex,
        node: candidate
      });
    }
  });
  var tabbableNodes = orderedTabbables.sort(sortOrderedTabbables).map(function (a) {
    return a.node;
  }).concat(regularTabbables);
  return tabbableNodes;
};

var focusableCandidateSelector = /* #__PURE__ */candidateSelectors.concat('iframe').join(',');

var isFocusable = function isFocusable(node, options) {
  options = options || {};

  if (!node) {
    throw new Error('No node provided');
  }

  if (matches.call(node, focusableCandidateSelector) === false) {
    return false;
  }

  return isNodeMatchingSelectorFocusable(options, node);
};

/*!
* focus-trap 6.2.2
* @license MIT, https://github.com/focus-trap/focus-trap/blob/master/LICENSE
*/

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var activeFocusDelay;

var activeFocusTraps = function () {
  var trapQueue = [];
  return {
    activateTrap: function activateTrap(trap) {
      if (trapQueue.length > 0) {
        var activeTrap = trapQueue[trapQueue.length - 1];

        if (activeTrap !== trap) {
          activeTrap.pause();
        }
      }

      var trapIndex = trapQueue.indexOf(trap);

      if (trapIndex === -1) {
        trapQueue.push(trap);
      } else {
        // move this existing trap to the front of the queue
        trapQueue.splice(trapIndex, 1);
        trapQueue.push(trap);
      }
    },
    deactivateTrap: function deactivateTrap(trap) {
      var trapIndex = trapQueue.indexOf(trap);

      if (trapIndex !== -1) {
        trapQueue.splice(trapIndex, 1);
      }

      if (trapQueue.length > 0) {
        trapQueue[trapQueue.length - 1].unpause();
      }
    }
  };
}();

var isSelectableInput = function isSelectableInput(node) {
  return node.tagName && node.tagName.toLowerCase() === 'input' && typeof node.select === 'function';
};

var isEscapeEvent = function isEscapeEvent(e) {
  return e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27;
};

var isTabEvent = function isTabEvent(e) {
  return e.key === 'Tab' || e.keyCode === 9;
};

var delay = function delay(fn) {
  return setTimeout(fn, 0);
};

var createFocusTrap = function createFocusTrap(elements, userOptions) {
  var doc = document;

  var config = _objectSpread2({
    returnFocusOnDeactivate: true,
    escapeDeactivates: true,
    delayInitialFocus: true
  }, userOptions);

  var state = {
    // @type {Array<HTMLElement>}
    containers: [],
    // list of objects identifying the first and last tabbable nodes in all containers/groups in
    //  the trap
    // NOTE: it's possible that a group has no tabbable nodes if nodes get removed while the trap
    //  is active, but the trap should never get to a state where there isn't at least one group
    //  with at least one tabbable node in it (that would lead to an error condition that would
    //  result in an error being thrown)
    // @type {Array<{ firstTabbableNode: HTMLElement|null, lastTabbableNode: HTMLElement|null }>}
    tabbableGroups: [],
    nodeFocusedBeforeActivation: null,
    mostRecentlyFocusedNode: null,
    active: false,
    paused: false
  };
  var trap; // eslint-disable-line prefer-const -- some private functions reference it, and its methods reference private functions, so we must declare here and define later

  var containersContain = function containersContain(element) {
    return state.containers.some(function (container) {
      return container.contains(element);
    });
  };

  var getNodeForOption = function getNodeForOption(optionName) {
    var optionValue = config[optionName];

    if (!optionValue) {
      return null;
    }

    var node = optionValue;

    if (typeof optionValue === 'string') {
      node = doc.querySelector(optionValue);

      if (!node) {
        throw new Error("`".concat(optionName, "` refers to no known node"));
      }
    }

    if (typeof optionValue === 'function') {
      node = optionValue();

      if (!node) {
        throw new Error("`".concat(optionName, "` did not return a node"));
      }
    }

    return node;
  };

  var getInitialFocusNode = function getInitialFocusNode() {
    var node;

    if (getNodeForOption('initialFocus') !== null) {
      node = getNodeForOption('initialFocus');
    } else if (containersContain(doc.activeElement)) {
      node = doc.activeElement;
    } else {
      var firstTabbableGroup = state.tabbableGroups[0];
      var firstTabbableNode = firstTabbableGroup && firstTabbableGroup.firstTabbableNode;
      node = firstTabbableNode || getNodeForOption('fallbackFocus');
    }

    if (!node) {
      throw new Error('Your focus-trap needs to have at least one focusable element');
    }

    return node;
  };

  var updateTabbableNodes = function updateTabbableNodes() {
    state.tabbableGroups = state.containers.map(function (container) {
      var tabbableNodes = tabbable(container);

      if (tabbableNodes.length > 0) {
        return {
          firstTabbableNode: tabbableNodes[0],
          lastTabbableNode: tabbableNodes[tabbableNodes.length - 1]
        };
      }

      return undefined;
    }).filter(function (group) {
      return !!group;
    }); // remove groups with no tabbable nodes
    // throw if no groups have tabbable nodes and we don't have a fallback focus node either

    if (state.tabbableGroups.length <= 0 && !getNodeForOption('fallbackFocus')) {
      throw new Error('Your focus-trap must have at least one container with at least one tabbable node in it at all times');
    }
  };

  var tryFocus = function tryFocus(node) {
    if (node === doc.activeElement) {
      return;
    }

    if (!node || !node.focus) {
      tryFocus(getInitialFocusNode());
      return;
    }

    node.focus({
      preventScroll: !!config.preventScroll
    });
    state.mostRecentlyFocusedNode = node;

    if (isSelectableInput(node)) {
      node.select();
    }
  };

  var getReturnFocusNode = function getReturnFocusNode(previousActiveElement) {
    var node = getNodeForOption('setReturnFocus');
    return node ? node : previousActiveElement;
  }; // This needs to be done on mousedown and touchstart instead of click
  // so that it precedes the focus event.


  var checkPointerDown = function checkPointerDown(e) {
    if (containersContain(e.target)) {
      // allow the click since it ocurred inside the trap
      return;
    }

    if (config.clickOutsideDeactivates) {
      // immediately deactivate the trap
      trap.deactivate({
        // if, on deactivation, we should return focus to the node originally-focused
        //  when the trap was activated (or the configured `setReturnFocus` node),
        //  then assume it's also OK to return focus to the outside node that was
        //  just clicked, causing deactivation, as long as that node is focusable;
        //  if it isn't focusable, then return focus to the original node focused
        //  on activation (or the configured `setReturnFocus` node)
        // NOTE: by setting `returnFocus: false`, deactivate() will do nothing,
        //  which will result in the outside click setting focus to the node
        //  that was clicked, whether it's focusable or not; by setting
        //  `returnFocus: true`, we'll attempt to re-focus the node originally-focused
        //  on activation (or the configured `setReturnFocus` node)
        returnFocus: config.returnFocusOnDeactivate && !isFocusable(e.target)
      });
      return;
    } // This is needed for mobile devices.
    // (If we'll only let `click` events through,
    // then on mobile they will be blocked anyways if `touchstart` is blocked.)


    if (config.allowOutsideClick && (typeof config.allowOutsideClick === 'boolean' ? config.allowOutsideClick : config.allowOutsideClick(e))) {
      // allow the click outside the trap to take place
      return;
    } // otherwise, prevent the click


    e.preventDefault();
  }; // In case focus escapes the trap for some strange reason, pull it back in.


  var checkFocusIn = function checkFocusIn(e) {
    var targetContained = containersContain(e.target); // In Firefox when you Tab out of an iframe the Document is briefly focused.

    if (targetContained || e.target instanceof Document) {
      if (targetContained) {
        state.mostRecentlyFocusedNode = e.target;
      }
    } else {
      // escaped! pull it back in to where it just left
      e.stopImmediatePropagation();
      tryFocus(state.mostRecentlyFocusedNode || getInitialFocusNode());
    }
  }; // Hijack Tab events on the first and last focusable nodes of the trap,
  // in order to prevent focus from escaping. If it escapes for even a
  // moment it can end up scrolling the page and causing confusion so we
  // kind of need to capture the action at the keydown phase.


  var checkTab = function checkTab(e) {
    updateTabbableNodes();
    var destinationNode = null;

    if (state.tabbableGroups.length > 0) {
      if (e.shiftKey) {
        var startOfGroupIndex = state.tabbableGroups.findIndex(function (_ref) {
          var firstTabbableNode = _ref.firstTabbableNode;
          return e.target === firstTabbableNode;
        });

        if (startOfGroupIndex >= 0) {
          var destinationGroupIndex = startOfGroupIndex === 0 ? state.tabbableGroups.length - 1 : startOfGroupIndex - 1;
          var destinationGroup = state.tabbableGroups[destinationGroupIndex];
          destinationNode = destinationGroup.lastTabbableNode;
        }
      } else {
        var lastOfGroupIndex = state.tabbableGroups.findIndex(function (_ref2) {
          var lastTabbableNode = _ref2.lastTabbableNode;
          return e.target === lastTabbableNode;
        });

        if (lastOfGroupIndex >= 0) {
          var _destinationGroupIndex = lastOfGroupIndex === state.tabbableGroups.length - 1 ? 0 : lastOfGroupIndex + 1;

          var _destinationGroup = state.tabbableGroups[_destinationGroupIndex];
          destinationNode = _destinationGroup.firstTabbableNode;
        }
      }
    } else {
      destinationNode = getNodeForOption('fallbackFocus');
    }

    if (destinationNode) {
      e.preventDefault();
      tryFocus(destinationNode);
    }
  };

  var checkKey = function checkKey(e) {
    if (config.escapeDeactivates !== false && isEscapeEvent(e)) {
      e.preventDefault();
      trap.deactivate();
      return;
    }

    if (isTabEvent(e)) {
      checkTab(e);
      return;
    }
  };

  var checkClick = function checkClick(e) {
    if (config.clickOutsideDeactivates) {
      return;
    }

    if (containersContain(e.target)) {
      return;
    }

    if (config.allowOutsideClick && (typeof config.allowOutsideClick === 'boolean' ? config.allowOutsideClick : config.allowOutsideClick(e))) {
      return;
    }

    e.preventDefault();
    e.stopImmediatePropagation();
  }; //
  // EVENT LISTENERS
  //


  var addListeners = function addListeners() {
    if (!state.active) {
      return;
    } // There can be only one listening focus trap at a time


    activeFocusTraps.activateTrap(trap); // Delay ensures that the focused element doesn't capture the event
    // that caused the focus trap activation.

    activeFocusDelay = config.delayInitialFocus ? delay(function () {
      tryFocus(getInitialFocusNode());
    }) : tryFocus(getInitialFocusNode());
    doc.addEventListener('focusin', checkFocusIn, true);
    doc.addEventListener('mousedown', checkPointerDown, {
      capture: true,
      passive: false
    });
    doc.addEventListener('touchstart', checkPointerDown, {
      capture: true,
      passive: false
    });
    doc.addEventListener('click', checkClick, {
      capture: true,
      passive: false
    });
    doc.addEventListener('keydown', checkKey, {
      capture: true,
      passive: false
    });
    return trap;
  };

  var removeListeners = function removeListeners() {
    if (!state.active) {
      return;
    }

    doc.removeEventListener('focusin', checkFocusIn, true);
    doc.removeEventListener('mousedown', checkPointerDown, true);
    doc.removeEventListener('touchstart', checkPointerDown, true);
    doc.removeEventListener('click', checkClick, true);
    doc.removeEventListener('keydown', checkKey, true);
    return trap;
  }; //
  // TRAP DEFINITION
  //


  trap = {
    activate: function activate(activateOptions) {
      if (state.active) {
        return this;
      }

      updateTabbableNodes();
      state.active = true;
      state.paused = false;
      state.nodeFocusedBeforeActivation = doc.activeElement;
      var onActivate = activateOptions && activateOptions.onActivate ? activateOptions.onActivate : config.onActivate;

      if (onActivate) {
        onActivate();
      }

      addListeners();
      return this;
    },
    deactivate: function deactivate(deactivateOptions) {
      if (!state.active) {
        return this;
      }

      clearTimeout(activeFocusDelay);
      removeListeners();
      state.active = false;
      state.paused = false;
      activeFocusTraps.deactivateTrap(trap);
      var onDeactivate = deactivateOptions && deactivateOptions.onDeactivate !== undefined ? deactivateOptions.onDeactivate : config.onDeactivate;

      if (onDeactivate) {
        onDeactivate();
      }

      var returnFocus = deactivateOptions && deactivateOptions.returnFocus !== undefined ? deactivateOptions.returnFocus : config.returnFocusOnDeactivate;

      if (returnFocus) {
        delay(function () {
          tryFocus(getReturnFocusNode(state.nodeFocusedBeforeActivation));
        });
      }

      return this;
    },
    pause: function pause() {
      if (state.paused || !state.active) {
        return this;
      }

      state.paused = true;
      removeListeners();
      return this;
    },
    unpause: function unpause() {
      if (!state.paused || !state.active) {
        return this;
      }

      state.paused = false;
      updateTabbableNodes();
      addListeners();
      return this;
    },
    updateContainerElements: function updateContainerElements(containerElements) {
      var elementsAsArray = [].concat(containerElements).filter(Boolean);
      state.containers = elementsAsArray.map(function (element) {
        return typeof element === 'string' ? doc.querySelector(element) : element;
      });

      if (state.active) {
        updateTabbableNodes();
      }

      return this;
    }
  }; // initialize container elements

  trap.updateContainerElements(elements);
  return trap;
};

class FocusTrap extends react.Component {
    constructor(props) {
        super(props);
        this.divRef = react.createRef();
        if (typeof document !== 'undefined') {
            this.previouslyFocusedElement = document.activeElement;
        }
    }
    componentDidMount() {
        // We need to hijack the returnFocusOnDeactivate option,
        // because React can move focus into the element before we arrived at
        // this lifecycle hook (e.g. with autoFocus inputs). So the component
        // captures the previouslyFocusedElement in componentWillMount,
        // then (optionally) returns focus to it in componentWillUnmount.
        this.focusTrap = createFocusTrap(this.divRef.current, Object.assign(Object.assign({}, this.props.focusTrapOptions), { returnFocusOnDeactivate: false }));
        if (this.props.active) {
            this.focusTrap.activate();
        }
        if (this.props.paused) {
            this.focusTrap.pause();
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.active && !this.props.active) {
            this.focusTrap.deactivate();
        }
        else if (!prevProps.active && this.props.active) {
            this.focusTrap.activate();
        }
        if (prevProps.paused && !this.props.paused) {
            this.focusTrap.unpause();
        }
        else if (!prevProps.paused && this.props.paused) {
            this.focusTrap.pause();
        }
    }
    componentWillUnmount() {
        this.focusTrap.deactivate();
        if (this.props.focusTrapOptions.returnFocusOnDeactivate !== false &&
            this.previouslyFocusedElement &&
            this.previouslyFocusedElement.focus) {
            this.previouslyFocusedElement.focus({ preventScroll: this.props.preventScrollOnDeactivate });
        }
    }
    render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _a = this.props, { children, className, focusTrapOptions, active, paused, preventScrollOnDeactivate } = _a, rest = __rest(_a, ["children", "className", "focusTrapOptions", "active", "paused", "preventScrollOnDeactivate"]);
        return (react.createElement("div", Object.assign({ ref: this.divRef, className: className }, rest), children));
    }
}
FocusTrap.displayName = 'FocusTrap';
FocusTrap.defaultProps = {
    active: true,
    paused: false,
    focusTrapOptions: {},
    preventScrollOnDeactivate: false
};

/**
 * This function is a helper for handling basic arrow keyboard interactions. If a component already has its own key handler and event start up/tear down, this function may be easier to integrate in over the full component.
 *
 * @param {event} event Event triggered by the keyboard
 * @param {element[]} navigableElements Valid traversable elements of the container
 * @param {function} isActiveElement Callback to determine if a given element from the navigable elements array is the active element of the page
 * @param {function} getFocusableElement Callback returning the focusable element of a given element from the navigable elements array
 * @param {string[]} validSiblingTags Valid sibling tags that horizontal arrow handling will focus
 * @param {boolean} noVerticalArrowHandling Flag indicating that the included vertical arrow key handling should be ignored
 * @param {boolean} noHorizontalArrowHandling Flag indicating that the included horizontal arrow key handling should be ignored
 * @param {boolean} updateTabIndex Flag indicating that the tabIndex of the currently focused element and next focused element should be updated, in the case of using a roving tabIndex
 * @param {boolean} onlyTraverseSiblings Flag indicating that next focusable element of a horizontal movement will be this element's sibling
 */
const handleArrows = (event, navigableElements, isActiveElement = element => document.activeElement.contains(element), getFocusableElement = element => element, validSiblingTags = ['A', 'BUTTON', 'INPUT'], noVerticalArrowHandling = false, noHorizontalArrowHandling = false, updateTabIndex = true, onlyTraverseSiblings = true) => {
    const activeElement = document.activeElement;
    const key = event.key;
    let moveTarget = null;
    // Handle vertical arrow keys. If noVerticalArrowHandling is passed, skip this block
    if (!noVerticalArrowHandling) {
        if (['ArrowUp', 'ArrowDown'].includes(key)) {
            event.preventDefault();
            event.stopImmediatePropagation(); // For menus in menus
            // Traverse navigableElements to find the element which is currently active
            let currentIndex = -1;
            // while (currentIndex === -1) {
            navigableElements.forEach((element, index) => {
                if (isActiveElement(element)) {
                    // Once found, move up or down the array by 1. Determined by the vertical arrow key direction
                    let increment = 0;
                    // keep increasing the increment until you've tried the whole navigableElement
                    while (!moveTarget && increment < navigableElements.length && increment * -1 < navigableElements.length) {
                        key === 'ArrowUp' ? increment-- : increment++;
                        currentIndex = index + increment;
                        if (currentIndex >= navigableElements.length) {
                            currentIndex = 0;
                        }
                        if (currentIndex < 0) {
                            currentIndex = navigableElements.length - 1;
                        }
                        // Set the next target element (undefined if none found)
                        moveTarget = getFocusableElement(navigableElements[currentIndex]);
                    }
                }
            });
            // }
        }
    }
    // Handle horizontal arrow keys. If noHorizontalArrowHandling is passed, skip this block
    if (!noHorizontalArrowHandling) {
        if (['ArrowLeft', 'ArrowRight'].includes(key)) {
            event.preventDefault();
            event.stopImmediatePropagation(); // For menus in menus
            let currentIndex = -1;
            navigableElements.forEach((element, index) => {
                if (isActiveElement(element)) {
                    const activeRow = navigableElements[index].querySelectorAll(validSiblingTags.join(',')); // all focusable elements in my row
                    if (!activeRow.length || onlyTraverseSiblings) {
                        let nextSibling = activeElement;
                        // While a sibling exists, check each sibling to determine if it should be focussed
                        while (nextSibling) {
                            // Set the next checked sibling, determined by the horizontal arrow key direction
                            nextSibling = key === 'ArrowLeft' ? nextSibling.previousElementSibling : nextSibling.nextElementSibling;
                            if (nextSibling) {
                                if (validSiblingTags.includes(nextSibling.tagName)) {
                                    // If the sibling's tag is included in validSiblingTags, set the next target element and break the loop
                                    moveTarget = nextSibling;
                                    break;
                                }
                                // If the sibling's tag is not valid, skip to the next sibling if possible
                            }
                        }
                    }
                    else {
                        activeRow.forEach((focusableElement, index) => {
                            if (event.target === focusableElement) {
                                // Once found, move up or down the array by 1. Determined by the vertical arrow key direction
                                const increment = key === 'ArrowLeft' ? -1 : 1;
                                currentIndex = index + increment;
                                if (currentIndex >= activeRow.length) {
                                    currentIndex = 0;
                                }
                                if (currentIndex < 0) {
                                    currentIndex = activeRow.length - 1;
                                }
                                // Set the next target element
                                moveTarget = activeRow[currentIndex];
                            }
                        });
                    }
                }
            });
        }
    }
    if (moveTarget) {
        // If updateTabIndex is true, set the previously focussed element's tabIndex to -1 and the next focussed element's tabIndex to 0
        // This updates the tabIndex for a roving tabIndex
        if (updateTabIndex) {
            activeElement.tabIndex = -1;
            moveTarget.tabIndex = 0;
        }
        // If a move target has been set by either arrow handler, focus that target
        moveTarget.focus();
    }
};
/**
 * This function is a helper for setting the initial tabIndexes in a roving tabIndex
 *
 * @param {HTMLElement[]} options Array of elements which should have a tabIndex of -1, except for the first element which will have a tabIndex of 0
 */
const setTabIndex = (options) => {
    if (options && options.length > 0) {
        // Iterate the options and set the tabIndex to -1 on every option
        options.forEach((option) => {
            option.tabIndex = -1;
        });
        // Manually set the tabIndex of the first option to 0
        options[0].tabIndex = 0;
    }
};
class KeyboardHandler extends react.Component {
    constructor() {
        super(...arguments);
        this.keyHandler = (event) => {
            const { isEventFromContainer } = this.props;
            // If the passed keyboard event is not from the container, ignore the event by returning
            if (isEventFromContainer ? !isEventFromContainer(event) : !this._isEventFromContainer(event)) {
                return;
            }
            const { isActiveElement, getFocusableElement, noVerticalArrowHandling, noHorizontalArrowHandling, noEnterHandling, noSpaceHandling, updateTabIndex, validSiblingTags, additionalKeyHandler, createNavigableElements } = this.props;
            // Pass the event off to be handled by any custom handler
            additionalKeyHandler && additionalKeyHandler(event);
            // Initalize navigableElements from the createNavigableElements callback
            const navigableElements = createNavigableElements();
            if (!navigableElements) {
                // eslint-disable-next-line no-console
                console.warn('No navigable elements have been passed to the KeyboardHandler. Keyboard navigation provided by this component will be ignored.');
                return;
            }
            const key = event.key;
            // Handle enter key. If noEnterHandling is passed, skip this block
            if (!noEnterHandling) {
                if (key === 'Enter') {
                    event.preventDefault();
                    event.stopImmediatePropagation(); // For menus in menus
                    document.activeElement.click();
                }
            }
            // Handle space key. If noSpaceHandling is passed, skip this block
            if (!noSpaceHandling) {
                if (key === ' ') {
                    event.preventDefault();
                    event.stopImmediatePropagation(); // For menus in menus
                    document.activeElement.click();
                }
            }
            // Inject helper handler for arrow navigation
            handleArrows(event, navigableElements, isActiveElement, getFocusableElement, validSiblingTags, noVerticalArrowHandling, noHorizontalArrowHandling, updateTabIndex);
        };
        this._isEventFromContainer = (event) => {
            const { containerRef } = this.props;
            return containerRef.current && containerRef.current.contains(event.target);
        };
    }
    componentDidMount() {
        if (canUseDOM) {
            window.addEventListener('keydown', this.keyHandler);
        }
    }
    componentWillUnmount() {
        if (canUseDOM) {
            window.removeEventListener('keydown', this.keyHandler);
        }
    }
    render() {
        return null;
    }
}
KeyboardHandler.displayName = 'KeyboardHandler';
KeyboardHandler.defaultProps = {
    containerRef: null,
    createNavigableElements: () => null,
    isActiveElement: (navigableElement) => document.activeElement === navigableElement,
    getFocusableElement: (navigableElement) => navigableElement,
    validSiblingTags: ['BUTTON', 'A'],
    updateTabIndex: true,
    noHorizontalArrowHandling: false,
    noVerticalArrowHandling: false,
    noEnterHandling: false,
    noSpaceHandling: false
};

var bullseye = createCommonjsModule(function (module, exports) {
exports.__esModule = true;

exports.default = {
  "bullseye": "pf-l-bullseye"
};
});

var styles = /*@__PURE__*/getDefaultExportFromCjs(bullseye);

var formControl = createCommonjsModule(function (module, exports) {
exports.__esModule = true;

exports.default = {
  "formControl": "pf-c-form-control",
  "modifiers": {
    "success": "pf-m-success",
    "expanded": "pf-m-expanded",
    "icon": "pf-m-icon",
    "warning": "pf-m-warning",
    "search": "pf-m-search",
    "calendar": "pf-m-calendar",
    "clock": "pf-m-clock",
    "placeholder": "pf-m-placeholder",
    "resizeVertical": "pf-m-resize-vertical",
    "resizeHorizontal": "pf-m-resize-horizontal"
  }
};
});

var formStyles = /*@__PURE__*/getDefaultExportFromCjs(formControl);

var dropdown = createCommonjsModule(function (module, exports) {
exports.__esModule = true;

exports.default = {
  "badge": "pf-c-badge",
  "check": "pf-c-check",
  "divider": "pf-c-divider",
  "dropdown": "pf-c-dropdown",
  "dropdownGroup": "pf-c-dropdown__group",
  "dropdownGroupTitle": "pf-c-dropdown__group-title",
  "dropdownMenu": "pf-c-dropdown__menu",
  "dropdownMenuItem": "pf-c-dropdown__menu-item",
  "dropdownMenuItemDescription": "pf-c-dropdown__menu-item-description",
  "dropdownMenuItemIcon": "pf-c-dropdown__menu-item-icon",
  "dropdownMenuItemMain": "pf-c-dropdown__menu-item-main",
  "dropdownToggle": "pf-c-dropdown__toggle",
  "dropdownToggleButton": "pf-c-dropdown__toggle-button",
  "dropdownToggleCheck": "pf-c-dropdown__toggle-check",
  "dropdownToggleIcon": "pf-c-dropdown__toggle-icon",
  "dropdownToggleImage": "pf-c-dropdown__toggle-image",
  "dropdownToggleText": "pf-c-dropdown__toggle-text",
  "menu": "pf-c-menu",
  "modifiers": {
    "fullHeight": "pf-m-full-height",
    "expanded": "pf-m-expanded",
    "action": "pf-m-action",
    "disabled": "pf-m-disabled",
    "plain": "pf-m-plain",
    "text": "pf-m-text",
    "splitButton": "pf-m-split-button",
    "primary": "pf-m-primary",
    "active": "pf-m-active",
    "secondary": "pf-m-secondary",
    "top": "pf-m-top",
    "alignRight": "pf-m-align-right",
    "alignLeft": "pf-m-align-left",
    "alignRightOnSm": "pf-m-align-right-on-sm",
    "alignLeftOnSm": "pf-m-align-left-on-sm",
    "alignRightOnMd": "pf-m-align-right-on-md",
    "alignLeftOnMd": "pf-m-align-left-on-md",
    "alignRightOnLg": "pf-m-align-right-on-lg",
    "alignLeftOnLg": "pf-m-align-left-on-lg",
    "alignRightOnXl": "pf-m-align-right-on-xl",
    "alignLeftOnXl": "pf-m-align-left-on-xl",
    "alignRightOn_2xl": "pf-m-align-right-on-2xl",
    "alignLeftOn_2xl": "pf-m-align-left-on-2xl",
    "ariaDisabled": "pf-m-aria-disabled",
    "icon": "pf-m-icon",
    "description": "pf-m-description"
  }
};
});

var styles$1 = /*@__PURE__*/getDefaultExportFromCjs(dropdown);

var DropdownPosition;
(function (DropdownPosition) {
    DropdownPosition["right"] = "right";
    DropdownPosition["left"] = "left";
})(DropdownPosition || (DropdownPosition = {}));
var DropdownDirection;
(function (DropdownDirection) {
    DropdownDirection["up"] = "up";
    DropdownDirection["down"] = "down";
})(DropdownDirection || (DropdownDirection = {}));
const DropdownContext = react.createContext({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSelect: (event) => undefined,
    id: '',
    toggleIndicatorClass: '',
    toggleIconClass: '',
    toggleTextClass: '',
    menuClass: '',
    itemClass: '',
    toggleClass: '',
    baseClass: '',
    baseComponent: 'div',
    sectionClass: '',
    sectionTitleClass: '',
    sectionComponent: 'section',
    disabledClass: '',
    plainTextClass: '',
    menuComponent: 'ul'
});
const DropdownArrowContext = react.createContext({
    keyHandler: null,
    sendRef: null
});

class DropdownMenu extends react.Component {
    constructor() {
        super(...arguments);
        this.refsCollection = [];
        this.componentWillUnmount = () => {
            document.removeEventListener('keydown', this.onKeyDown);
        };
        this.onKeyDown = (event) => {
            if (!this.props.isOpen ||
                !Array.from(document.activeElement.classList).find(className => DropdownMenu.validToggleClasses.concat(this.context.toggleClass).includes(className))) {
                return;
            }
            const refs = this.refsCollection;
            if (event.key === 'ArrowDown') {
                const firstFocusTargetCollection = refs.find(ref => ref && ref[0] && !ref[0].hasAttribute('disabled'));
                DropdownMenu.focusFirstRef(firstFocusTargetCollection);
                event.stopPropagation();
            }
            else if (event.key === 'ArrowUp') {
                const collectionLength = refs.length;
                const lastFocusTargetCollection = refs.slice(collectionLength - 1, collectionLength);
                const lastFocusTarget = lastFocusTargetCollection && lastFocusTargetCollection[0];
                DropdownMenu.focusFirstRef(lastFocusTarget);
                event.stopPropagation();
            }
        };
        this.childKeyHandler = (index, innerIndex, position, custom = false) => {
            keyHandler(index, innerIndex, position, this.refsCollection, this.props.isGrouped ? this.refsCollection : react.Children.toArray(this.props.children), custom);
        };
        this.sendRef = (index, nodes, isDisabled, isSeparator) => {
            this.refsCollection[index] = [];
            nodes.map((node, innerIndex) => {
                if (!node) {
                    this.refsCollection[index][innerIndex] = null;
                }
                else if (!node.getAttribute) {
                    // eslint-disable-next-line react/no-find-dom-node
                    this.refsCollection[index][innerIndex] = reactDom.findDOMNode(node);
                }
                else if (isSeparator) {
                    this.refsCollection[index][innerIndex] = null;
                }
                else {
                    this.refsCollection[index][innerIndex] = node;
                }
            });
        };
    }
    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);
        const { autoFocus } = this.props;
        if (autoFocus) {
            // Focus first non-disabled element
            const focusTargetCollection = this.refsCollection.find(ref => ref && ref[0] && !ref[0].hasAttribute('disabled'));
            const focusTarget = focusTargetCollection && focusTargetCollection[0];
            if (focusTarget && focusTarget.focus) {
                setTimeout(() => focusTarget.focus());
            }
        }
    }
    shouldComponentUpdate() {
        // reset refsCollection before updating to account for child removal between mounts
        this.refsCollection = [];
        return true;
    }
    extendChildren() {
        const { children, isGrouped } = this.props;
        if (isGrouped) {
            let index = 0;
            return react.Children.map(children, groupedChildren => {
                const group = groupedChildren;
                const props = {};
                if (group.props && group.props.children) {
                    if (Array.isArray(group.props.children)) {
                        props.children = react.Children.map(group.props.children, option => react.cloneElement(option, {
                            index: index++
                        }));
                    }
                    else {
                        props.children = react.cloneElement(group.props.children, {
                            index: index++
                        });
                    }
                }
                return react.cloneElement(group, props);
            });
        }
        return react.Children.map(children, (child, index) => react.cloneElement(child, {
            index
        }));
    }
    render() {
        const _a = this.props, { className, isOpen, position, children, component, isGrouped, setMenuComponentRef, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        openedOnEnter, alignments } = _a, props = __rest(_a, ["className", "isOpen", "position", "children", "component", "isGrouped", "setMenuComponentRef", "openedOnEnter", "alignments"]);
        return (react.createElement(DropdownArrowContext.Provider, { value: {
                keyHandler: this.childKeyHandler,
                sendRef: this.sendRef
            } }, component === 'div' ? (react.createElement(DropdownContext.Consumer, null, ({ onSelect, menuClass }) => (react.createElement("div", { className: css(menuClass, position === DropdownPosition.right && styles$1.modifiers.alignRight, formatBreakpointMods(alignments, styles$1, 'align-'), className), hidden: !isOpen, onClick: event => onSelect && onSelect(event), ref: setMenuComponentRef }, children)))) : ((isGrouped && (react.createElement(DropdownContext.Consumer, null, ({ menuClass, menuComponent }) => {
            const MenuComponent = (menuComponent || 'div');
            return (react.createElement(MenuComponent, Object.assign({}, props, { className: css(menuClass, position === DropdownPosition.right && styles$1.modifiers.alignRight, formatBreakpointMods(alignments, styles$1, 'align-'), className), hidden: !isOpen, role: "menu", ref: setMenuComponentRef }), this.extendChildren()));
        }))) || (react.createElement(DropdownContext.Consumer, null, ({ menuClass, menuComponent }) => {
            const MenuComponent = (menuComponent || component);
            return (react.createElement(MenuComponent, Object.assign({}, props, { className: css(menuClass, position === DropdownPosition.right && styles$1.modifiers.alignRight, formatBreakpointMods(alignments, styles$1, 'align-'), className), hidden: !isOpen, role: "menu", ref: setMenuComponentRef }), this.extendChildren()));
        })))));
    }
}
DropdownMenu.displayName = 'DropdownMenu';
DropdownMenu.defaultProps = {
    className: '',
    isOpen: true,
    openedOnEnter: false,
    autoFocus: true,
    position: DropdownPosition.left,
    component: 'ul',
    isGrouped: false,
    setMenuComponentRef: null
};
DropdownMenu.validToggleClasses = [styles$1.dropdownToggle, styles$1.dropdownToggleButton];
DropdownMenu.focusFirstRef = (refCollection) => {
    if (refCollection && refCollection[0] && refCollection[0].focus) {
        setTimeout(() => refCollection[0].focus());
    }
};
DropdownMenu.contextType = DropdownContext;

class DropdownWithContext extends react.Component {
    constructor(props) {
        super(props);
        this.openedOnEnter = false;
        this.baseComponentRef = react.createRef();
        this.menuComponentRef = react.createRef();
        this.onEnter = () => {
            this.openedOnEnter = true;
        };
        this.setMenuComponentRef = (element) => {
            this.menuComponentRef = element;
        };
        this.getMenuComponentRef = () => this.menuComponentRef;
        if (props.dropdownItems && props.dropdownItems.length > 0 && props.children) {
            // eslint-disable-next-line no-console
            console.error('Children and dropdownItems props have been provided. Only the dropdownItems prop items will be rendered');
        }
    }
    componentDidUpdate() {
        if (!this.props.isOpen) {
            this.openedOnEnter = false;
        }
    }
    render() {
        const _a = this.props, { children, className, direction, dropdownItems, isOpen, isPlain, isText, isGrouped, isFullHeight, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSelect, position, toggle, autoFocus, menuAppendTo } = _a, props = __rest(_a, ["children", "className", "direction", "dropdownItems", "isOpen", "isPlain", "isText", "isGrouped", "isFullHeight", "onSelect", "position", "toggle", "autoFocus", "menuAppendTo"]);
        const id = toggle.props.id || `pf-dropdown-toggle-id-${DropdownWithContext.currentId++}`;
        let component;
        let renderedContent;
        let ariaHasPopup = false;
        if (dropdownItems && dropdownItems.length > 0) {
            component = 'ul';
            renderedContent = dropdownItems;
            ariaHasPopup = true;
        }
        else {
            component = 'div';
            renderedContent = react.Children.toArray(children);
        }
        const openedOnEnter = this.openedOnEnter;
        return (react.createElement(DropdownContext.Consumer, null, ({ baseClass, baseComponent, id: contextId, ouiaId, ouiaComponentType, ouiaSafe, alignments }) => {
            const BaseComponent = baseComponent;
            const menuContainer = (react.createElement(DropdownMenu, { setMenuComponentRef: this.setMenuComponentRef, component: component, isOpen: isOpen, position: position, "aria-labelledby": contextId ? `${contextId}-toggle` : id, isGrouped: isGrouped, autoFocus: openedOnEnter && autoFocus, alignments: alignments }, renderedContent));
            const popperContainer = (react.createElement("div", { className: css(baseClass, direction === DropdownDirection.up && styles$1.modifiers.top, position === DropdownPosition.right && styles$1.modifiers.alignRight, isOpen && styles$1.modifiers.expanded, className) }, isOpen && menuContainer));
            const mainContainer = (react.createElement(BaseComponent, Object.assign({}, props, { className: css(baseClass, direction === DropdownDirection.up && styles$1.modifiers.top, position === DropdownPosition.right && styles$1.modifiers.alignRight, isOpen && styles$1.modifiers.expanded, isFullHeight && styles$1.modifiers.fullHeight, className), ref: this.baseComponentRef }, getOUIAProps(ouiaComponentType, ouiaId, ouiaSafe)),
                react.Children.map(toggle, oneToggle => react.cloneElement(oneToggle, {
                    parentRef: this.baseComponentRef,
                    getMenuRef: this.getMenuComponentRef,
                    isOpen,
                    id,
                    isPlain,
                    isText,
                    'aria-haspopup': ariaHasPopup,
                    onEnter: () => {
                        this.onEnter();
                        oneToggle.props.onEnter && oneToggle.props.onEnter();
                    }
                })),
                menuAppendTo === 'inline' && isOpen && menuContainer));
            const getParentElement = () => {
                if (this.baseComponentRef && this.baseComponentRef.current) {
                    return this.baseComponentRef.current.parentElement;
                }
                return null;
            };
            return menuAppendTo === 'inline' ? (mainContainer) : (react.createElement(Popper, { trigger: mainContainer, popper: popperContainer, direction: direction, position: position, appendTo: menuAppendTo === 'parent' ? getParentElement() : menuAppendTo, isVisible: isOpen }));
        }));
    }
}
DropdownWithContext.displayName = 'DropdownWithContext';
// seed for the aria-labelledby ID
DropdownWithContext.currentId = 0;
DropdownWithContext.defaultProps = {
    className: '',
    dropdownItems: [],
    isOpen: false,
    isPlain: false,
    isText: false,
    isGrouped: false,
    position: DropdownPosition.left,
    direction: DropdownDirection.down,
    onSelect: () => undefined,
    autoFocus: true,
    menuAppendTo: 'inline'
};

const Dropdown = (_a) => {
    var { onSelect, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref, // Types of Ref are different for React.FC vs React.Component
    ouiaId, ouiaSafe, alignments, contextProps } = _a, props = __rest(_a, ["onSelect", "ref", "ouiaId", "ouiaSafe", "alignments", "contextProps"]);
    return (react.createElement(DropdownContext.Provider, { value: Object.assign({ onSelect: event => onSelect && onSelect(event), toggleTextClass: styles$1.dropdownToggleText, toggleIconClass: styles$1.dropdownToggleImage, toggleIndicatorClass: styles$1.dropdownToggleIcon, menuClass: styles$1.dropdownMenu, itemClass: styles$1.dropdownMenuItem, toggleClass: styles$1.dropdownToggle, baseClass: styles$1.dropdown, baseComponent: 'div', sectionClass: styles$1.dropdownGroup, sectionTitleClass: styles$1.dropdownGroupTitle, sectionComponent: 'section', disabledClass: styles$1.modifiers.disabled, plainTextClass: styles$1.modifiers.text, ouiaId: useOUIAId(Dropdown.displayName, ouiaId), ouiaSafe, ouiaComponentType: Dropdown.displayName, alignments }, contextProps) },
        react.createElement(DropdownWithContext, Object.assign({}, props))));
};
Dropdown.displayName = 'Dropdown';

class InternalDropdownItem extends react.Component {
    constructor() {
        super(...arguments);
        this.ref = react.createRef();
        this.additionalRef = react.createRef();
        this.getInnerNode = (node) => (node && node.childNodes && node.childNodes.length ? node.childNodes[0] : node);
        this.onKeyDown = (event) => {
            // Detected key press on this item, notify the menu parent so that the appropriate item can be focused
            const innerIndex = event.target === this.ref.current ? 0 : 1;
            if (!this.props.customChild) {
                event.preventDefault();
            }
            if (event.key === 'ArrowUp') {
                this.props.context.keyHandler(this.props.index, innerIndex, KEYHANDLER_DIRECTION.UP);
                event.stopPropagation();
            }
            else if (event.key === 'ArrowDown') {
                this.props.context.keyHandler(this.props.index, innerIndex, KEYHANDLER_DIRECTION.DOWN);
                event.stopPropagation();
            }
            else if (event.key === 'ArrowRight') {
                this.props.context.keyHandler(this.props.index, innerIndex, KEYHANDLER_DIRECTION.RIGHT);
                event.stopPropagation();
            }
            else if (event.key === 'ArrowLeft') {
                this.props.context.keyHandler(this.props.index, innerIndex, KEYHANDLER_DIRECTION.LEFT);
                event.stopPropagation();
            }
            else if (event.key === 'Enter' || event.key === ' ') {
                event.target.click();
                this.props.enterTriggersArrowDown &&
                    this.props.context.keyHandler(this.props.index, innerIndex, KEYHANDLER_DIRECTION.DOWN);
            }
        };
        this.componentRef = (element) => {
            this.ref.current = element;
            const { component } = this.props;
            const ref = component.ref;
            if (ref) {
                if (typeof ref === 'function') {
                    ref(element);
                }
                else {
                    ref.current = element;
                }
            }
        };
    }
    componentDidMount() {
        const { context, index, isDisabled, role, customChild, autoFocus } = this.props;
        const customRef = customChild ? this.getInnerNode(this.ref.current) : this.ref.current;
        context.sendRef(index, [customRef, customChild ? customRef : this.additionalRef.current], isDisabled, role === 'separator');
        autoFocus && setTimeout(() => customRef.focus());
    }
    componentDidUpdate() {
        const { context, index, isDisabled, role, customChild } = this.props;
        const customRef = customChild ? this.getInnerNode(this.ref.current) : this.ref.current;
        context.sendRef(index, [customRef, customChild ? customRef : this.additionalRef.current], isDisabled, role === 'separator');
    }
    extendAdditionalChildRef() {
        const { additionalChild } = this.props;
        return react.cloneElement(additionalChild, {
            ref: this.additionalRef
        });
    }
    render() {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const _a = this.props, { className, children, isHovered, context, onClick, component, role, isDisabled, isAriaDisabled, isPlainText, index, href, tooltip, tooltipProps, id, componentID, listItemClassName, additionalChild, customChild, enterTriggersArrowDown, icon, autoFocus, styleChildren, description, inoperableEvents } = _a, additionalProps = __rest(_a, ["className", "children", "isHovered", "context", "onClick", "component", "role", "isDisabled", "isAriaDisabled", "isPlainText", "index", "href", "tooltip", "tooltipProps", "id", "componentID", "listItemClassName", "additionalChild", "customChild", "enterTriggersArrowDown", "icon", "autoFocus", "styleChildren", "description", "inoperableEvents"]);
        /* eslint-enable @typescript-eslint/no-unused-vars */
        let classes = css(icon && styles$1.modifiers.icon, isAriaDisabled && styles$1.modifiers.ariaDisabled, className);
        if (component === 'a') {
            additionalProps['aria-disabled'] = isDisabled || isAriaDisabled;
        }
        else if (component === 'button') {
            additionalProps['aria-disabled'] = isDisabled || isAriaDisabled;
            additionalProps.type = additionalProps.type || 'button';
        }
        const renderWithTooltip = (childNode) => tooltip ? (react.createElement(Tooltip, Object.assign({ content: tooltip }, tooltipProps), childNode)) : (childNode);
        const renderClonedComponent = (element) => react.cloneElement(element, Object.assign(Object.assign({}, (styleChildren && {
            className: css(element.props.className, classes)
        })), (this.props.role !== 'separator' && { ref: this.componentRef })));
        const renderDefaultComponent = (tag) => {
            const Component = tag;
            const componentContent = description ? (react.createElement(react.Fragment, null,
                react.createElement("div", { className: styles$1.dropdownMenuItemMain },
                    icon && react.createElement("span", { className: css(styles$1.dropdownMenuItemIcon) }, icon),
                    children),
                react.createElement("div", { className: styles$1.dropdownMenuItemDescription }, description))) : (react.createElement(react.Fragment, null,
                icon && react.createElement("span", { className: css(styles$1.dropdownMenuItemIcon) }, icon),
                children));
            return (react.createElement(Component, Object.assign({}, additionalProps, (isDisabled || isAriaDisabled ? preventedEvents(inoperableEvents) : null), { href: href, ref: this.ref, className: classes, id: componentID }), componentContent));
        };
        return (react.createElement(DropdownContext.Consumer, null, ({ onSelect, itemClass, disabledClass, plainTextClass }) => {
            if (this.props.role !== 'separator') {
                classes = css(classes, isDisabled && disabledClass, isPlainText && plainTextClass, itemClass, description && styles$1.modifiers.description);
            }
            if (customChild) {
                return react.cloneElement(customChild, {
                    ref: this.ref,
                    onKeyDown: this.onKeyDown
                });
            }
            return (react.createElement("li", { className: listItemClassName || null, role: role, onKeyDown: this.onKeyDown, onClick: (event) => {
                    if (!isDisabled && !isAriaDisabled) {
                        onClick(event);
                        onSelect(event);
                    }
                }, id: id },
                renderWithTooltip(react.isValidElement(component)
                    ? renderClonedComponent(component)
                    : renderDefaultComponent(component)),
                additionalChild && this.extendAdditionalChildRef()));
        }));
    }
}
InternalDropdownItem.displayName = 'InternalDropdownItem';
InternalDropdownItem.defaultProps = {
    className: '',
    isHovered: false,
    component: 'a',
    role: 'none',
    isDisabled: false,
    isPlainText: false,
    tooltipProps: {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClick: (event) => undefined,
    index: -1,
    context: {
        keyHandler: () => { },
        sendRef: () => { }
    },
    enterTriggersArrowDown: false,
    icon: null,
    styleChildren: true,
    description: null,
    inoperableEvents: ['onClick', 'onKeyPress']
};

const DropdownItem = (_a) => {
    var { children, className, component = 'a', isDisabled = false, isAriaDisabled = false, isPlainText = false, isHovered = false, href, tooltip, tooltipProps = {}, listItemClassName, onClick, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref, // Types of Ref are different for React.FC vs React.Component
    additionalChild, customChild, tabIndex = -1, icon = null, autoFocus, description = null, styleChildren, ouiaId, ouiaSafe } = _a, props = __rest(_a, ["children", "className", "component", "isDisabled", "isAriaDisabled", "isPlainText", "isHovered", "href", "tooltip", "tooltipProps", "listItemClassName", "onClick", "ref", "additionalChild", "customChild", "tabIndex", "icon", "autoFocus", "description", "styleChildren", "ouiaId", "ouiaSafe"]);
    const ouiaProps = useOUIAProps(DropdownItem.displayName, ouiaId, ouiaSafe);
    return (react.createElement(DropdownArrowContext.Consumer, null, context => (react.createElement(InternalDropdownItem, Object.assign({ context: context, role: "menuitem", tabIndex: tabIndex, className: className, component: component, isDisabled: isDisabled, isAriaDisabled: isAriaDisabled, isPlainText: isPlainText, isHovered: isHovered, href: href, tooltip: tooltip, tooltipProps: tooltipProps, listItemClassName: listItemClassName, onClick: onClick, additionalChild: additionalChild, customChild: customChild, icon: icon, autoFocus: autoFocus, styleChildren: styleChildren, description: description }, ouiaProps, props), children))));
};
DropdownItem.displayName = 'DropdownItem';

var divider = createCommonjsModule(function (module, exports) {
exports.__esModule = true;

exports.default = {
  "divider": "pf-c-divider",
  "modifiers": {
    "hidden": "pf-m-hidden",
    "hiddenOnSm": "pf-m-hidden-on-sm",
    "visibleOnSm": "pf-m-visible-on-sm",
    "hiddenOnMd": "pf-m-hidden-on-md",
    "visibleOnMd": "pf-m-visible-on-md",
    "hiddenOnLg": "pf-m-hidden-on-lg",
    "visibleOnLg": "pf-m-visible-on-lg",
    "hiddenOnXl": "pf-m-hidden-on-xl",
    "visibleOnXl": "pf-m-visible-on-xl",
    "hiddenOn_2xl": "pf-m-hidden-on-2xl",
    "visibleOn_2xl": "pf-m-visible-on-2xl",
    "vertical": "pf-m-vertical",
    "insetNone": "pf-m-inset-none",
    "insetXs": "pf-m-inset-xs",
    "insetSm": "pf-m-inset-sm",
    "insetMd": "pf-m-inset-md",
    "insetLg": "pf-m-inset-lg",
    "insetXl": "pf-m-inset-xl",
    "inset_2xl": "pf-m-inset-2xl",
    "inset_3xl": "pf-m-inset-3xl",
    "insetNoneOnSm": "pf-m-inset-none-on-sm",
    "insetXsOnSm": "pf-m-inset-xs-on-sm",
    "insetSmOnSm": "pf-m-inset-sm-on-sm",
    "insetMdOnSm": "pf-m-inset-md-on-sm",
    "insetLgOnSm": "pf-m-inset-lg-on-sm",
    "insetXlOnSm": "pf-m-inset-xl-on-sm",
    "inset_2xlOnSm": "pf-m-inset-2xl-on-sm",
    "inset_3xlOnSm": "pf-m-inset-3xl-on-sm",
    "insetNoneOnMd": "pf-m-inset-none-on-md",
    "insetXsOnMd": "pf-m-inset-xs-on-md",
    "insetSmOnMd": "pf-m-inset-sm-on-md",
    "insetMdOnMd": "pf-m-inset-md-on-md",
    "insetLgOnMd": "pf-m-inset-lg-on-md",
    "insetXlOnMd": "pf-m-inset-xl-on-md",
    "inset_2xlOnMd": "pf-m-inset-2xl-on-md",
    "inset_3xlOnMd": "pf-m-inset-3xl-on-md",
    "insetNoneOnLg": "pf-m-inset-none-on-lg",
    "insetXsOnLg": "pf-m-inset-xs-on-lg",
    "insetSmOnLg": "pf-m-inset-sm-on-lg",
    "insetMdOnLg": "pf-m-inset-md-on-lg",
    "insetLgOnLg": "pf-m-inset-lg-on-lg",
    "insetXlOnLg": "pf-m-inset-xl-on-lg",
    "inset_2xlOnLg": "pf-m-inset-2xl-on-lg",
    "inset_3xlOnLg": "pf-m-inset-3xl-on-lg",
    "insetNoneOnXl": "pf-m-inset-none-on-xl",
    "insetXsOnXl": "pf-m-inset-xs-on-xl",
    "insetSmOnXl": "pf-m-inset-sm-on-xl",
    "insetMdOnXl": "pf-m-inset-md-on-xl",
    "insetLgOnXl": "pf-m-inset-lg-on-xl",
    "insetXlOnXl": "pf-m-inset-xl-on-xl",
    "inset_2xlOnXl": "pf-m-inset-2xl-on-xl",
    "inset_3xlOnXl": "pf-m-inset-3xl-on-xl",
    "insetNoneOn_2xl": "pf-m-inset-none-on-2xl",
    "insetXsOn_2xl": "pf-m-inset-xs-on-2xl",
    "insetSmOn_2xl": "pf-m-inset-sm-on-2xl",
    "insetMdOn_2xl": "pf-m-inset-md-on-2xl",
    "insetLgOn_2xl": "pf-m-inset-lg-on-2xl",
    "insetXlOn_2xl": "pf-m-inset-xl-on-2xl",
    "inset_2xlOn_2xl": "pf-m-inset-2xl-on-2xl",
    "inset_3xlOn_2xl": "pf-m-inset-3xl-on-2xl"
  }
};
});

var styles$2 = /*@__PURE__*/getDefaultExportFromCjs(divider);

var DividerVariant;
(function (DividerVariant) {
    DividerVariant["hr"] = "hr";
    DividerVariant["li"] = "li";
    DividerVariant["div"] = "div";
})(DividerVariant || (DividerVariant = {}));
const Divider = (_a) => {
    var { className, component = DividerVariant.hr, isVertical = false, inset } = _a, props = __rest(_a, ["className", "component", "isVertical", "inset"]);
    const Component = component;
    return (react.createElement(Component, Object.assign({ className: css(styles$2.divider, isVertical && styles$2.modifiers.vertical, formatBreakpointMods(inset, styles$2), className) }, (component !== 'hr' && { role: 'separator' }), props)));
};
Divider.displayName = 'Divider';

const DropdownSeparator = (_a) => {
    var { className = '', 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref, // Types of Ref are different for React.FC vs React.Component
    ouiaId, ouiaSafe } = _a, props = __rest(_a, ["className", "ref", "ouiaId", "ouiaSafe"]);
    const ouiaProps = useOUIAProps(DropdownSeparator.displayName, ouiaId, ouiaSafe);
    return (react.createElement(DropdownArrowContext.Consumer, null, context => (react.createElement(InternalDropdownItem, Object.assign({}, props, { context: context, component: react.createElement(Divider, { component: DividerVariant.div }), className: className, role: "separator" }, ouiaProps)))));
};
DropdownSeparator.displayName = 'DropdownSeparator';

const buttonVariantStyles = {
    default: '',
    primary: styles$1.modifiers.primary,
    secondary: styles$1.modifiers.secondary
};
class Toggle extends react.Component {
    constructor() {
        super(...arguments);
        this.buttonRef = react.createRef();
        this.componentDidMount = () => {
            document.addEventListener('click', this.onDocClick);
            document.addEventListener('touchstart', this.onDocClick);
            document.addEventListener('keydown', this.onEscPress);
        };
        this.componentWillUnmount = () => {
            document.removeEventListener('click', this.onDocClick);
            document.removeEventListener('touchstart', this.onDocClick);
            document.removeEventListener('keydown', this.onEscPress);
        };
        this.onDocClick = (event) => {
            const { isOpen, parentRef, onToggle, getMenuRef } = this.props;
            const menuRef = getMenuRef && getMenuRef();
            const clickedOnToggle = parentRef && parentRef.current && parentRef.current.contains(event.target);
            const clickedWithinMenu = menuRef && menuRef.contains && menuRef.contains(event.target);
            if (isOpen && !(clickedOnToggle || clickedWithinMenu)) {
                onToggle(false, event);
            }
        };
        this.onEscPress = (event) => {
            const { parentRef, getMenuRef } = this.props;
            const keyCode = event.keyCode || event.which;
            const menuRef = getMenuRef && getMenuRef();
            const escFromToggle = parentRef && parentRef.current && parentRef.current.contains(event.target);
            const escFromWithinMenu = menuRef && menuRef.contains && menuRef.contains(event.target);
            if (this.props.isOpen &&
                (keyCode === KEY_CODES.ESCAPE_KEY || event.key === 'Tab') &&
                (escFromToggle || escFromWithinMenu)) {
                this.props.onToggle(false, event);
                this.buttonRef.current.focus();
            }
        };
        this.onKeyDown = (event) => {
            if (event.key === 'Tab' && !this.props.isOpen) {
                return;
            }
            if ((event.key === 'Tab' || event.key === 'Enter' || event.key === ' ') && this.props.isOpen) {
                if (!this.props.bubbleEvent) {
                    event.stopPropagation();
                }
                event.preventDefault();
                this.props.onToggle(!this.props.isOpen, event);
            }
            else if ((event.key === 'Enter' || event.key === ' ') && !this.props.isOpen) {
                if (!this.props.bubbleEvent) {
                    event.stopPropagation();
                }
                event.preventDefault();
                this.props.onToggle(!this.props.isOpen, event);
                this.props.onEnter();
            }
        };
    }
    render() {
        const _a = this.props, { className, children, isOpen, isDisabled, isPlain, isText, isPrimary, isSplitButton, toggleVariant, onToggle, 'aria-haspopup': ariaHasPopup, 
        /* eslint-disable @typescript-eslint/no-unused-vars */
        isActive, bubbleEvent, onEnter, parentRef, getMenuRef, 
        /* eslint-enable @typescript-eslint/no-unused-vars */
        id, type } = _a, props = __rest(_a, ["className", "children", "isOpen", "isDisabled", "isPlain", "isText", "isPrimary", "isSplitButton", "toggleVariant", "onToggle", 'aria-haspopup', "isActive", "bubbleEvent", "onEnter", "parentRef", "getMenuRef", "id", "type"]);
        return (react.createElement(DropdownContext.Consumer, null, ({ toggleClass }) => (react.createElement("button", Object.assign({}, props, { id: id, ref: this.buttonRef, className: css(isSplitButton ? styles$1.dropdownToggleButton : toggleClass || styles$1.dropdownToggle, isActive && styles$1.modifiers.active, isPlain && styles$1.modifiers.plain, isText && styles$1.modifiers.text, isPrimary && styles$1.modifiers.primary, buttonVariantStyles[toggleVariant], className), type: type || 'button', onClick: event => onToggle(!isOpen, event), "aria-expanded": isOpen, "aria-haspopup": ariaHasPopup, onKeyDown: event => this.onKeyDown(event), disabled: isDisabled }), children))));
    }
}
Toggle.displayName = 'Toggle';
Toggle.defaultProps = {
    className: '',
    isOpen: false,
    isActive: false,
    isDisabled: false,
    isPlain: false,
    isText: false,
    isPrimary: false,
    isSplitButton: false,
    onToggle: () => { },
    onEnter: () => { },
    bubbleEvent: false
};

const EllipsisVIconConfig = {
  name: 'EllipsisVIcon',
  height: 512,
  width: 192,
  svgPath: 'M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z',
  yOffset: 0,
  xOffset: 0,
};

const EllipsisVIcon = createIcon(EllipsisVIconConfig);

const KebabToggle = (_a) => {
    var { id = '', 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children = null, className = '', isOpen = false, 'aria-label': ariaLabel = 'Actions', parentRef = null, getMenuRef = null, isActive = false, isPlain = false, isDisabled = false, bubbleEvent = false, onToggle = () => undefined, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref } = _a, // Types of Ref are different for React.FC vs React.Component
    props = __rest(_a, ["id", "children", "className", "isOpen", 'aria-label', "parentRef", "getMenuRef", "isActive", "isPlain", "isDisabled", "bubbleEvent", "onToggle", "ref"]);
    return (react.createElement(Toggle, Object.assign({ id: id, className: className, isOpen: isOpen, "aria-label": ariaLabel, parentRef: parentRef, getMenuRef: getMenuRef, isActive: isActive, isPlain: isPlain, isDisabled: isDisabled, onToggle: onToggle, bubbleEvent: bubbleEvent }, props),
        react.createElement(EllipsisVIcon, null)));
};
KebabToggle.displayName = 'KebabToggle';

const StarIconConfig = {
  name: 'StarIcon',
  height: 512,
  width: 576,
  svgPath: 'M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z',
  yOffset: 0,
  xOffset: 0,
};

const StarIcon = createIcon(StarIconConfig);

var TextInputTypes;
(function (TextInputTypes) {
    TextInputTypes["text"] = "text";
    TextInputTypes["date"] = "date";
    TextInputTypes["datetimeLocal"] = "datetime-local";
    TextInputTypes["email"] = "email";
    TextInputTypes["month"] = "month";
    TextInputTypes["number"] = "number";
    TextInputTypes["password"] = "password";
    TextInputTypes["search"] = "search";
    TextInputTypes["tel"] = "tel";
    TextInputTypes["time"] = "time";
    TextInputTypes["url"] = "url";
})(TextInputTypes || (TextInputTypes = {}));
class TextInputBase extends react.Component {
    constructor(props) {
        super(props);
        this.inputRef = react.createRef();
        this.observer = () => { };
        this.handleChange = (event) => {
            if (this.props.onChange) {
                this.props.onChange(event.currentTarget.value, event);
            }
        };
        this.handleResize = () => {
            const inputRef = this.props.innerRef || this.inputRef;
            if (inputRef && inputRef.current) {
                trimLeft(inputRef.current, String(this.props.value));
            }
        };
        this.restoreText = () => {
            const inputRef = this.props.innerRef || this.inputRef;
            // restore the value
            inputRef.current.value = String(this.props.value);
            // make sure we still see the rightmost value to preserve cursor click position
            inputRef.current.scrollLeft = inputRef.current.scrollWidth;
        };
        this.onFocus = (event) => {
            const { isLeftTruncated, onFocus } = this.props;
            if (isLeftTruncated) {
                this.restoreText();
            }
            onFocus && onFocus(event);
        };
        this.onBlur = (event) => {
            const { isLeftTruncated, onBlur } = this.props;
            if (isLeftTruncated) {
                this.handleResize();
            }
            onBlur && onBlur(event);
        };
        if (!props.id && !props['aria-label'] && !props['aria-labelledby']) {
            // eslint-disable-next-line no-console
            console.error('Text input:', 'Text input requires either an id or aria-label to be specified');
        }
        this.state = {
            ouiaStateId: getDefaultOUIAId(TextInputBase.displayName)
        };
    }
    componentDidMount() {
        if (this.props.isLeftTruncated) {
            const inputRef = this.props.innerRef || this.inputRef;
            this.observer = getResizeObserver(inputRef.current, this.handleResize);
            this.handleResize();
        }
    }
    componentWillUnmount() {
        if (this.props.isLeftTruncated) {
            this.observer();
        }
    }
    render() {
        const _a = this.props, { innerRef, className, type, value, validated, 
        /* eslint-disable @typescript-eslint/no-unused-vars */
        onChange, onFocus, onBlur, isLeftTruncated, 
        /* eslint-enable @typescript-eslint/no-unused-vars */
        isReadOnly, isRequired, isDisabled, iconVariant, customIconUrl, customIconDimensions, ouiaId, ouiaSafe } = _a, props = __rest(_a, ["innerRef", "className", "type", "value", "validated", "onChange", "onFocus", "onBlur", "isLeftTruncated", "isReadOnly", "isRequired", "isDisabled", "iconVariant", "customIconUrl", "customIconDimensions", "ouiaId", "ouiaSafe"]);
        const customIconStyle = {};
        if (customIconUrl) {
            customIconStyle.backgroundImage = `url('${customIconUrl}')`;
        }
        if (customIconDimensions) {
            customIconStyle.backgroundSize = customIconDimensions;
        }
        return (react.createElement("input", Object.assign({}, props, { onFocus: this.onFocus, onBlur: this.onBlur, className: css(formStyles.formControl, validated === ValidatedOptions.success && formStyles.modifiers.success, validated === ValidatedOptions.warning && formStyles.modifiers.warning, ((iconVariant && iconVariant !== 'search') || customIconUrl) && formStyles.modifiers.icon, iconVariant && formStyles.modifiers[iconVariant], className), onChange: this.handleChange, type: type, value: value, "aria-invalid": props['aria-invalid'] ? props['aria-invalid'] : validated === ValidatedOptions.error, required: isRequired, disabled: isDisabled, readOnly: isReadOnly, ref: innerRef || this.inputRef }, ((customIconUrl || customIconDimensions) && { style: customIconStyle }), getOUIAProps(TextInput.displayName, ouiaId !== undefined ? ouiaId : this.state.ouiaStateId, ouiaSafe))));
    }
}
TextInputBase.displayName = 'TextInputBase';
TextInputBase.defaultProps = {
    'aria-label': null,
    className: '',
    isRequired: false,
    validated: 'default',
    isDisabled: false,
    isReadOnly: false,
    type: TextInputTypes.text,
    isLeftTruncated: false,
    onChange: () => undefined,
    ouiaSafe: true
};
const TextInput = react.forwardRef((props, ref) => (react.createElement(TextInputBase, Object.assign({}, props, { innerRef: ref }))));
TextInput.displayName = 'TextInput';

var form = createCommonjsModule(function (module, exports) {
exports.__esModule = true;

exports.default = {
  "form": "pf-c-form",
  "formActions": "pf-c-form__actions",
  "formFieldGroup": "pf-c-form__field-group",
  "formFieldGroupBody": "pf-c-form__field-group-body",
  "formFieldGroupHeader": "pf-c-form__field-group-header",
  "formFieldGroupHeaderActions": "pf-c-form__field-group-header-actions",
  "formFieldGroupHeaderDescription": "pf-c-form__field-group-header-description",
  "formFieldGroupHeaderMain": "pf-c-form__field-group-header-main",
  "formFieldGroupHeaderTitle": "pf-c-form__field-group-header-title",
  "formFieldGroupHeaderTitleText": "pf-c-form__field-group-header-title-text",
  "formFieldGroupToggle": "pf-c-form__field-group-toggle",
  "formFieldGroupToggleButton": "pf-c-form__field-group-toggle-button",
  "formFieldGroupToggleIcon": "pf-c-form__field-group-toggle-icon",
  "formFieldset": "pf-c-form__fieldset",
  "formGroup": "pf-c-form__group",
  "formGroupControl": "pf-c-form__group-control",
  "formGroupLabel": "pf-c-form__group-label",
  "formGroupLabelHelp": "pf-c-form__group-label-help",
  "formGroupLabelInfo": "pf-c-form__group-label-info",
  "formGroupLabelMain": "pf-c-form__group-label-main",
  "formHelperText": "pf-c-form__helper-text",
  "formHelperTextIcon": "pf-c-form__helper-text-icon",
  "formLabel": "pf-c-form__label",
  "formLabelRequired": "pf-c-form__label-required",
  "formLabelText": "pf-c-form__label-text",
  "formSection": "pf-c-form__section",
  "formSectionTitle": "pf-c-form__section-title",
  "modifiers": {
    "horizontal": "pf-m-horizontal",
    "alignRight": "pf-m-align-right",
    "noPaddingTop": "pf-m-no-padding-top",
    "horizontalOnXs": "pf-m-horizontal-on-xs",
    "horizontalOnSm": "pf-m-horizontal-on-sm",
    "horizontalOnMd": "pf-m-horizontal-on-md",
    "horizontalOnLg": "pf-m-horizontal-on-lg",
    "horizontalOnXl": "pf-m-horizontal-on-xl",
    "horizontalOn_2xl": "pf-m-horizontal-on-2xl",
    "limitWidth": "pf-m-limit-width",
    "action": "pf-m-action",
    "info": "pf-m-info",
    "disabled": "pf-m-disabled",
    "inline": "pf-m-inline",
    "stack": "pf-m-stack",
    "error": "pf-m-error",
    "success": "pf-m-success",
    "warning": "pf-m-warning",
    "inactive": "pf-m-inactive",
    "hidden": "pf-m-hidden",
    "expanded": "pf-m-expanded"
  }
};
});

var formStyles$1 = /*@__PURE__*/getDefaultExportFromCjs(form);

var check = createCommonjsModule(function (module, exports) {
exports.__esModule = true;

exports.default = {
  "check": "pf-c-check",
  "checkBody": "pf-c-check__body",
  "checkDescription": "pf-c-check__description",
  "checkInput": "pf-c-check__input",
  "checkLabel": "pf-c-check__label",
  "modifiers": {
    "standalone": "pf-m-standalone",
    "disabled": "pf-m-disabled"
  }
};
});

var checkStyles = /*@__PURE__*/getDefaultExportFromCjs(check);

// tslint:disable-next-line:no-empty
const defaultOnChange = () => { };
class Checkbox extends react.Component {
    constructor(props) {
        super(props);
        this.handleChange = (event) => {
            this.props.onChange(event.currentTarget.checked, event);
        };
        this.state = {
            ouiaStateId: getDefaultOUIAId(Checkbox.displayName)
        };
    }
    render() {
        const _a = this.props, { 'aria-label': ariaLabel, className, onChange, isValid, isDisabled, isChecked, label, checked, defaultChecked, description, body, ouiaId, ouiaSafe } = _a, props = __rest(_a, ['aria-label', "className", "onChange", "isValid", "isDisabled", "isChecked", "label", "checked", "defaultChecked", "description", "body", "ouiaId", "ouiaSafe"]);
        if (!props.id) {
            // eslint-disable-next-line no-console
            console.error('Checkbox:', 'id is required to make input accessible');
        }
        const checkedProps = {};
        if ([true, false].includes(checked) || isChecked === true) {
            checkedProps.checked = checked || isChecked;
        }
        if (onChange !== defaultOnChange) {
            checkedProps.checked = isChecked;
        }
        if ([false, true].includes(defaultChecked)) {
            checkedProps.defaultChecked = defaultChecked;
        }
        checkedProps.checked = checkedProps.checked === null ? false : checkedProps.checked;
        return (react.createElement("div", { className: css(checkStyles.check, !label && checkStyles.modifiers.standalone, className) },
            react.createElement("input", Object.assign({}, props, { className: css(checkStyles.checkInput), type: "checkbox", onChange: this.handleChange, "aria-invalid": !isValid, "aria-label": ariaLabel, disabled: isDisabled, ref: elem => elem && (elem.indeterminate = isChecked === null) }, checkedProps, getOUIAProps(Checkbox.displayName, ouiaId !== undefined ? ouiaId : this.state.ouiaStateId, ouiaSafe))),
            label && (react.createElement("label", { className: css(checkStyles.checkLabel, isDisabled && checkStyles.modifiers.disabled), htmlFor: props.id }, label)),
            description && react.createElement("span", { className: css(checkStyles.checkDescription) }, description),
            body && react.createElement("span", { className: css(checkStyles.checkBody) }, body)));
    }
}
Checkbox.displayName = 'Checkbox';
Checkbox.defaultProps = {
    className: '',
    isValid: true,
    isDisabled: false,
    isChecked: false,
    onChange: defaultOnChange,
    ouiaSafe: true
};

const AngleDownIconConfig = {
  name: 'AngleDownIcon',
  height: 512,
  width: 320,
  svgPath: 'M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z',
  yOffset: 0,
  xOffset: 0,
};

const AngleDownIcon = createIcon(AngleDownIconConfig);

const GripVerticalIconConfig = {
  name: 'GripVerticalIcon',
  height: 512,
  width: 320,
  svgPath: 'M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z',
  yOffset: 0,
  xOffset: 0,
};

const GripVerticalIcon = createIcon(GripVerticalIconConfig);

var popover = createCommonjsModule(function (module, exports) {
exports.__esModule = true;

exports.default = {
  "button": "pf-c-button",
  "modifiers": {
    "noPadding": "pf-m-no-padding",
    "widthAuto": "pf-m-width-auto",
    "top": "pf-m-top",
    "topLeft": "pf-m-top-left",
    "topRight": "pf-m-top-right",
    "bottom": "pf-m-bottom",
    "bottomLeft": "pf-m-bottom-left",
    "bottomRight": "pf-m-bottom-right",
    "left": "pf-m-left",
    "leftTop": "pf-m-left-top",
    "leftBottom": "pf-m-left-bottom",
    "right": "pf-m-right",
    "rightTop": "pf-m-right-top",
    "rightBottom": "pf-m-right-bottom",
    "danger": "pf-m-danger",
    "warning": "pf-m-warning",
    "success": "pf-m-success",
    "default": "pf-m-default",
    "info": "pf-m-info",
    "icon": "pf-m-icon"
  },
  "popover": "pf-c-popover",
  "popoverArrow": "pf-c-popover__arrow",
  "popoverBody": "pf-c-popover__body",
  "popoverContent": "pf-c-popover__content",
  "popoverFooter": "pf-c-popover__footer",
  "popoverTitle": "pf-c-popover__title",
  "popoverTitleIcon": "pf-c-popover__title-icon",
  "popoverTitleText": "pf-c-popover__title-text",
  "title": "pf-c-title"
};
});

var styles$3 = /*@__PURE__*/getDefaultExportFromCjs(popover);

const PopoverContext = react.createContext({});

const PopoverContent = (_a) => {
    var { className = null, children } = _a, props = __rest(_a, ["className", "children"]);
    return (react.createElement("div", Object.assign({ className: css(styles$3.popoverContent, className) }, props), children));
};
PopoverContent.displayName = 'PopoverContent';

const PopoverBody = (_a) => {
    var { children, id, className } = _a, props = __rest(_a, ["children", "id", "className"]);
    return (react.createElement("div", Object.assign({ className: css(styles$3.popoverBody, className), id: id }, props), children));
};
PopoverBody.displayName = 'PopoverBody';

const PopoverHeaderIcon = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (react.createElement("span", Object.assign({ className: css(styles$3.popoverTitleIcon, className) }, props), children));
};
PopoverHeaderIcon.displayName = 'PopoverHeaderIcon';

const PopoverHeaderText = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (react.createElement("span", Object.assign({ className: css(styles$3.popoverTitleText, className) }, props), children));
};
PopoverHeaderText.displayName = 'PopoverHeaderText';

const PopoverHeader = (_a) => {
    var { children, icon, className, titleHeadingLevel = 'h6', alertSeverityVariant, id, alertSeverityScreenReaderText } = _a, props = __rest(_a, ["children", "icon", "className", "titleHeadingLevel", "alertSeverityVariant", "id", "alertSeverityScreenReaderText"]);
    const HeadingLevel = titleHeadingLevel;
    return icon || alertSeverityVariant ? (react.createElement("header", Object.assign({ className: css('pf-c-popover__header', className), id: id }, props),
        react.createElement(HeadingLevel, { className: css(styles$3.popoverTitle, icon && styles$3.modifiers.icon) },
            icon && react.createElement(PopoverHeaderIcon, null, icon),
            alertSeverityVariant && alertSeverityScreenReaderText && (react.createElement("span", { className: "pf-u-screen-reader" }, alertSeverityScreenReaderText)),
            react.createElement(PopoverHeaderText, null, children)))) : (react.createElement(Title, Object.assign({ headingLevel: titleHeadingLevel, size: TitleSizes.md, id: id, className: className }, props), children));
};
PopoverHeader.displayName = 'PopoverHeader';

const PopoverFooter = (_a) => {
    var { children, className = '' } = _a, props = __rest(_a, ["children", "className"]);
    return (react.createElement("footer", Object.assign({ className: css(styles$3.popoverFooter, className) }, props), children));
};
PopoverFooter.displayName = 'PopoverFooter';

const PopoverCloseButton = (_a) => {
    var { onClose = () => undefined } = _a, props = __rest(_a, ["onClose"]);
    const [closeButtonElement, setCloseButtonElement] = react.useState(null);
    react.useEffect(() => {
        closeButtonElement && closeButtonElement.addEventListener('click', onClose, false);
        return () => {
            closeButtonElement && closeButtonElement.removeEventListener('click', onClose, false);
        };
    }, [closeButtonElement]);
    return (react.createElement(FindRefWrapper, { onFoundRef: (foundRef) => setCloseButtonElement(foundRef) },
        react.createElement(Button, Object.assign({ variant: "plain", "aria-label": true }, props, { style: { pointerEvents: 'auto' } }),
            react.createElement(TimesIcon, null))));
};
PopoverCloseButton.displayName = 'PopoverCloseButton';

const PopoverArrow = (_a) => {
    var { className = '' } = _a, props = __rest(_a, ["className"]);
    return react.createElement("div", Object.assign({ className: css(styles$3.popoverArrow, className) }, props));
};
PopoverArrow.displayName = 'PopoverArrow';

const c_popover_MaxWidth = {
  "name": "--pf-c-popover--MaxWidth",
  "value": "none",
  "var": "var(--pf-c-popover--MaxWidth)"
};

const c_popover_MinWidth = {
  "name": "--pf-c-popover--MinWidth",
  "value": "auto",
  "var": "var(--pf-c-popover--MinWidth)"
};

var PopoverPosition;
(function (PopoverPosition) {
    PopoverPosition["auto"] = "auto";
    PopoverPosition["top"] = "top";
    PopoverPosition["bottom"] = "bottom";
    PopoverPosition["left"] = "left";
    PopoverPosition["right"] = "right";
    PopoverPosition["topStart"] = "top-start";
    PopoverPosition["topEnd"] = "top-end";
    PopoverPosition["bottomStart"] = "bottom-start";
    PopoverPosition["bottomEnd"] = "bottom-end";
    PopoverPosition["leftStart"] = "left-start";
    PopoverPosition["leftEnd"] = "left-end";
    PopoverPosition["rightStart"] = "right-start";
    PopoverPosition["rightEnd"] = "right-end";
})(PopoverPosition || (PopoverPosition = {}));
const alertStyle = {
    default: styles$3.modifiers.default,
    info: styles$3.modifiers.info,
    success: styles$3.modifiers.success,
    warning: styles$3.modifiers.warning,
    danger: styles$3.modifiers.danger
};
const Popover = (_a) => {
    var { children, position = 'top', enableFlip = true, className = '', isVisible = null, shouldClose = () => null, shouldOpen = () => null, 'aria-label': ariaLabel = '', bodyContent, headerContent = null, headerComponent = 'h6', headerIcon = null, alertSeverityVariant, alertSeverityScreenReaderText, footerContent = null, appendTo = () => document.body, hideOnOutsideClick = true, onHide = () => null, onHidden = () => null, onShow = () => null, onShown = () => null, onMount = () => null, zIndex = 9999, minWidth = c_popover_MinWidth && c_popover_MinWidth.value, maxWidth = c_popover_MaxWidth && c_popover_MaxWidth.value, closeBtnAriaLabel = 'Close', showClose = true, distance = 25, 
    // For every initial starting position, there are 3 escape positions
    flipBehavior = ['top', 'right', 'bottom', 'left', 'top', 'right', 'bottom'], animationDuration = 300, id, withFocusTrap: propWithFocusTrap, boundary, tippyProps, reference, hasNoPadding = false, hasAutoWidth = false } = _a, rest = __rest(_a, ["children", "position", "enableFlip", "className", "isVisible", "shouldClose", "shouldOpen", 'aria-label', "bodyContent", "headerContent", "headerComponent", "headerIcon", "alertSeverityVariant", "alertSeverityScreenReaderText", "footerContent", "appendTo", "hideOnOutsideClick", "onHide", "onHidden", "onShow", "onShown", "onMount", "zIndex", "minWidth", "maxWidth", "closeBtnAriaLabel", "showClose", "distance", "flipBehavior", "animationDuration", "id", "withFocusTrap", "boundary", "tippyProps", "reference", "hasNoPadding", "hasAutoWidth"]);
    // could make this a prop in the future (true | false | 'toggle')
    // const hideOnClick = true;
    const uniqueId = id || getUniqueId();
    const triggerManually = isVisible !== null;
    const [visible, setVisible] = react.useState(false);
    const [opacity, setOpacity] = react.useState(0);
    const [focusTrapActive, setFocusTrapActive] = react.useState(Boolean(propWithFocusTrap));
    const transitionTimerRef = react.useRef(null);
    const showTimerRef = react.useRef(null);
    const hideTimerRef = react.useRef(null);
    react.useEffect(() => {
        onMount();
    }, []);
    react.useEffect(() => {
        if (triggerManually) {
            if (isVisible) {
                show();
            }
            else {
                hide();
            }
        }
    }, [isVisible, triggerManually]);
    const show = (withFocusTrap) => {
        onShow();
        if (transitionTimerRef.current) {
            clearTimeout(transitionTimerRef.current);
        }
        if (hideTimerRef.current) {
            clearTimeout(hideTimerRef.current);
        }
        showTimerRef.current = setTimeout(() => {
            setVisible(true);
            setOpacity(1);
            propWithFocusTrap !== false && withFocusTrap && setFocusTrapActive(true);
            onShown();
        }, 0);
    };
    const hide = () => {
        onHide();
        if (showTimerRef.current) {
            clearTimeout(showTimerRef.current);
        }
        hideTimerRef.current = setTimeout(() => {
            setVisible(false);
            setOpacity(0);
            setFocusTrapActive(false);
            transitionTimerRef.current = setTimeout(() => {
                onHidden();
            }, animationDuration);
        }, 0);
    };
    const positionModifiers = {
        top: styles$3.modifiers.top,
        bottom: styles$3.modifiers.bottom,
        left: styles$3.modifiers.left,
        right: styles$3.modifiers.right,
        'top-start': styles$3.modifiers.topLeft,
        'top-end': styles$3.modifiers.topRight,
        'bottom-start': styles$3.modifiers.bottomLeft,
        'bottom-end': styles$3.modifiers.bottomRight,
        'left-start': styles$3.modifiers.leftTop,
        'left-end': styles$3.modifiers.leftBottom,
        'right-start': styles$3.modifiers.rightTop,
        'right-end': styles$3.modifiers.rightBottom
    };
    const hasCustomMinWidth = minWidth !== c_popover_MinWidth.value;
    const hasCustomMaxWidth = maxWidth !== c_popover_MaxWidth.value;
    const onDocumentKeyDown = (event) => {
        if (event.keyCode === KEY_CODES.ESCAPE_KEY && visible) {
            if (triggerManually) {
                shouldClose(null, hide, event);
            }
            else {
                hide();
            }
        }
    };
    const onDocumentClick = (event, triggerElement, popperElement) => {
        if (hideOnOutsideClick && visible) {
            // check if we clicked within the popper, if so don't do anything
            const isChild = popperElement && popperElement.contains(event.target);
            if (isChild) {
                // clicked within the popper
                return;
            }
            if (triggerManually) {
                shouldClose(null, hide, event);
            }
            else {
                hide();
            }
        }
    };
    const onTriggerClick = (event) => {
        if (triggerManually) {
            if (visible) {
                shouldClose(null, hide, event);
            }
            else {
                shouldOpen(show, event);
            }
        }
        else {
            if (visible) {
                hide();
            }
            else {
                show(true);
            }
        }
    };
    const onContentMouseDown = () => {
        if (focusTrapActive) {
            setFocusTrapActive(false);
        }
    };
    const closePopover = (event) => {
        event.stopPropagation();
        if (triggerManually) {
            shouldClose(null, hide, event);
        }
        else {
            hide();
        }
    };
    const content = (react.createElement(FocusTrap, Object.assign({ active: focusTrapActive, focusTrapOptions: {
            returnFocusOnDeactivate: true,
            clickOutsideDeactivates: true,
            fallbackFocus: () => {
                // If the popover's trigger is focused but scrolled out of view,
                // FocusTrap will throw an error when the Enter button is used on the trigger.
                // That is because the Popover is hidden when its trigger is out of view.
                // Provide a fallback in that case.
                let node = null;
                if (document && document.activeElement) {
                    node = document.activeElement;
                }
                return node;
            }
        }, preventScrollOnDeactivate: true, className: css(styles$3.popover, alertSeverityVariant && alertStyle[alertSeverityVariant], hasNoPadding && styles$3.modifiers.noPadding, hasAutoWidth && styles$3.modifiers.widthAuto, className), role: "dialog", "aria-modal": "true", "aria-label": headerContent ? undefined : ariaLabel, "aria-labelledby": headerContent ? `popover-${uniqueId}-header` : undefined, "aria-describedby": `popover-${uniqueId}-body`, onMouseDown: onContentMouseDown, style: {
            minWidth: hasCustomMinWidth ? minWidth : null,
            maxWidth: hasCustomMaxWidth ? maxWidth : null,
            opacity,
            transition: getOpacityTransition(animationDuration)
        } }, rest),
        react.createElement(PopoverArrow, null),
        react.createElement(PopoverContent, null,
            showClose && react.createElement(PopoverCloseButton, { onClose: closePopover, "aria-label": closeBtnAriaLabel }),
            headerContent && (react.createElement(PopoverHeader, { id: `popover-${uniqueId}-header`, icon: headerIcon, alertSeverityVariant: alertSeverityVariant, alertSeverityScreenReaderText: alertSeverityScreenReaderText || `${alertSeverityVariant} alert:`, titleHeadingLevel: headerComponent }, typeof headerContent === 'function' ? headerContent(hide) : headerContent)),
            react.createElement(PopoverBody, { id: `popover-${uniqueId}-body` }, typeof bodyContent === 'function' ? bodyContent(hide) : bodyContent),
            footerContent && (react.createElement(PopoverFooter, { id: `popover-${uniqueId}-footer` }, typeof footerContent === 'function' ? footerContent(hide) : footerContent)))));
    return (react.createElement(PopoverContext.Provider, { value: { headerComponent } },
        react.createElement(Popper, { trigger: children, reference: reference, popper: content, popperMatchesTriggerWidth: false, appendTo: appendTo, isVisible: visible, positionModifiers: positionModifiers, distance: distance, placement: position, onTriggerClick: onTriggerClick, onDocumentClick: onDocumentClick, onDocumentKeyDown: onDocumentKeyDown, enableFlip: enableFlip, zIndex: zIndex, flipBehavior: flipBehavior })));
};
Popover.displayName = 'Popover';

export { AngleDownIcon as A, Checkbox as C, DropdownContext as D, FocusTrap as F, GripVerticalIcon as G, KeyboardHandler as K, Popover as P, StarIcon as S, Toggle as T, DropdownSeparator as a, Divider as b, checkStyles as c, formStyles as d, TextInput as e, formStyles$1 as f, styles as g, DropdownItem as h, DropdownWithContext as i, DropdownDirection as j, Dropdown as k, KebabToggle as l, DropdownPosition as m, setTabIndex as n, handleArrows as o, styles$1 as s };
