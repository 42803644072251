import React, {useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {Controller} from "../../_snowpack/pkg/react-hook-form.js";
import {
  ActionGroup,
  Button,
  FormGroup,
  Select,
  SelectOption,
  SelectVariant
} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {FormAccess} from "../../components/form-access/FormAccess.js";
import {HelpItem} from "../../components/help-enabler/HelpItem.js";
import {useServerInfo} from "../../context/server-info/ServerInfoProvider.js";
import {sortProviders} from "../../util.js";
export const FineGrainOpenIdConnect = ({
  control,
  save,
  reset
}) => {
  const {t} = useTranslation("clients");
  const providers = useServerInfo().providers;
  const clientSignatureProviders = providers?.clientSignature.providers;
  const contentEncryptionProviders = providers?.contentencryption.providers;
  const cekManagementProviders = providers?.cekmanagement.providers;
  const signatureProviders = providers?.signature.providers;
  const [accessTokenOpen, setAccessTokenOpen] = useState(false);
  const [idTokenOpen, setIdTokenOpen] = useState(false);
  const [idTokenKeyManagementOpen, setIdTokenKeyManagementOpen] = useState(false);
  const [idTokenContentOpen, setIdTokenContentOpen] = useState(false);
  const [userInfoSignedResponseOpen, setUserInfoSignedResponseOpen] = useState(false);
  const [requestObjectSignatureOpen, setRequestObjectSignatureOpen] = useState(false);
  const [requestObjectRequiredOpen, setRequestObjectRequiredOpen] = useState(false);
  const keyOptions = [
    /* @__PURE__ */ React.createElement(SelectOption, {
      key: "empty",
      value: ""
    }, t("common:choose")),
    ...sortProviders(clientSignatureProviders).map((p) => /* @__PURE__ */ React.createElement(SelectOption, {
      key: p,
      value: p
    }))
  ];
  const cekManagementOptions = [
    /* @__PURE__ */ React.createElement(SelectOption, {
      key: "empty",
      value: ""
    }, t("common:choose")),
    ...sortProviders(cekManagementProviders).map((p) => /* @__PURE__ */ React.createElement(SelectOption, {
      key: p,
      value: p
    }))
  ];
  const signatureOptions = [
    /* @__PURE__ */ React.createElement(SelectOption, {
      key: "unsigned",
      value: ""
    }, t("unsigned")),
    ...sortProviders(signatureProviders).map((p) => /* @__PURE__ */ React.createElement(SelectOption, {
      key: p,
      value: p
    }))
  ];
  const contentOptions = [
    /* @__PURE__ */ React.createElement(SelectOption, {
      key: "empty",
      value: ""
    }, t("common:choose")),
    ...sortProviders(contentEncryptionProviders).map((p) => /* @__PURE__ */ React.createElement(SelectOption, {
      key: p,
      value: p
    }))
  ];
  const requestObjectOptions = [
    /* @__PURE__ */ React.createElement(SelectOption, {
      key: "any",
      value: "any"
    }, t("common:any")),
    /* @__PURE__ */ React.createElement(SelectOption, {
      key: "none",
      value: "none"
    }, t("common:none")),
    ...sortProviders(clientSignatureProviders).map((p) => /* @__PURE__ */ React.createElement(SelectOption, {
      key: p,
      value: p
    }))
  ];
  const requestObjectRequiredOptions = [
    "not required",
    "request or request_uri",
    "request only",
    "request_uri only"
  ].map((p) => /* @__PURE__ */ React.createElement(SelectOption, {
    key: p,
    value: p
  }, t(`requestObject.${p}`)));
  return /* @__PURE__ */ React.createElement(FormAccess, {
    role: "manage-clients",
    isHorizontal: true
  }, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("accessTokenSignatureAlgorithm"),
    fieldId: "accessTokenSignatureAlgorithm",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:accessTokenSignatureAlgorithm",
      fieldLabelId: "clients:accessTokenSignatureAlgorithm"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "attributes.access.token.signed.response.alg",
    defaultValue: "",
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "accessTokenSignatureAlgorithm",
      variant: SelectVariant.single,
      onToggle: setAccessTokenOpen,
      isOpen: accessTokenOpen,
      onSelect: (_, value2) => {
        onChange(value2);
        setAccessTokenOpen(false);
      },
      selections: value
    }, keyOptions)
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("idTokenSignatureAlgorithm"),
    fieldId: "kc-id-token-signature",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:idTokenSignatureAlgorithm",
      fieldLabelId: "clients:idTokenSignatureAlgorithm"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "attributes.id.token.signed.response.alg",
    defaultValue: "",
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "idTokenSignatureAlgorithm",
      variant: SelectVariant.single,
      onToggle: setIdTokenOpen,
      isOpen: idTokenOpen,
      onSelect: (_, value2) => {
        onChange(value2);
        setIdTokenOpen(false);
      },
      selections: value
    }, keyOptions)
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("idTokenEncryptionKeyManagementAlgorithm"),
    fieldId: "idTokenEncryptionKeyManagementAlgorithm",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:idTokenEncryptionKeyManagementAlgorithm",
      fieldLabelId: "clients:idTokenEncryptionKeyManagementAlgorithm"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "attributes.id.token.encrypted.response.alg",
    defaultValue: "",
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "idTokenEncryptionKeyManagementAlgorithm",
      variant: SelectVariant.single,
      onToggle: setIdTokenKeyManagementOpen,
      isOpen: idTokenKeyManagementOpen,
      onSelect: (_, value2) => {
        onChange(value2);
        setIdTokenKeyManagementOpen(false);
      },
      selections: value
    }, cekManagementOptions)
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("idTokenEncryptionContentEncryptionAlgorithm"),
    fieldId: "idTokenEncryptionContentEncryptionAlgorithm",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:idTokenEncryptionContentEncryptionAlgorithm",
      fieldLabelId: "clients:idTokenEncryptionContentEncryptionAlgorithm"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "attributes.id.token.encrypted.response.enc",
    defaultValue: "",
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "idTokenEncryptionContentEncryptionAlgorithm",
      variant: SelectVariant.single,
      onToggle: setIdTokenContentOpen,
      isOpen: idTokenContentOpen,
      onSelect: (_, value2) => {
        onChange(value2);
        setIdTokenContentOpen(false);
      },
      selections: value
    }, contentOptions)
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("userInfoSignedResponseAlgorithm"),
    fieldId: "userInfoSignedResponseAlgorithm",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:userInfoSignedResponseAlgorithm",
      fieldLabelId: "clients:userInfoSignedResponseAlgorithm"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "attributes.user.info.response.signature.alg",
    defaultValue: "",
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "userInfoSignedResponseAlgorithm",
      variant: SelectVariant.single,
      onToggle: setUserInfoSignedResponseOpen,
      isOpen: userInfoSignedResponseOpen,
      onSelect: (_, value2) => {
        onChange(value2);
        setUserInfoSignedResponseOpen(false);
      },
      selections: value
    }, signatureOptions)
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("requestObjectSignatureAlgorithm"),
    fieldId: "requestObjectSignatureAlgorithm",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:requestObjectSignatureAlgorithm",
      fieldLabelId: "clients:requestObjectSignatureAlgorithm"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "attributes.request_object_signature_alg",
    defaultValue: "",
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "requestObjectSignatureAlgorithm",
      variant: SelectVariant.single,
      onToggle: setRequestObjectSignatureOpen,
      isOpen: requestObjectSignatureOpen,
      onSelect: (_, value2) => {
        onChange(value2);
        setRequestObjectSignatureOpen(false);
      },
      selections: value
    }, requestObjectOptions)
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("requestObjectRequired"),
    fieldId: "requestObjectRequired",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:requestObjectRequired",
      fieldLabelId: "clients:requestObjectRequired"
    })
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "attributes.request.object.required",
    defaultValue: "",
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "requestObjectRequired",
      variant: SelectVariant.single,
      onToggle: setRequestObjectRequiredOpen,
      isOpen: requestObjectRequiredOpen,
      onSelect: (_, value2) => {
        onChange(value2);
        setRequestObjectRequiredOpen(false);
      },
      selections: value
    }, requestObjectRequiredOptions)
  })), /* @__PURE__ */ React.createElement(ActionGroup, null, /* @__PURE__ */ React.createElement(Button, {
    variant: "secondary",
    id: "fineGrainSave",
    onClick: save
  }, t("common:save")), /* @__PURE__ */ React.createElement(Button, {
    id: "fineGrainRevert",
    variant: "link",
    onClick: reset
  }, t("common:revert"))));
};
