import React, {useMemo, useState} from "../_snowpack/pkg/react.js";
import {useTranslation} from "../_snowpack/pkg/react-i18next.js";
import {
  FormGroup,
  TextInput,
  Form,
  Switch,
  TextArea,
  Select,
  SelectVariant,
  SelectOption
} from "../_snowpack/pkg/@patternfly/react-core.js";
import {Controller, useFormContext} from "../_snowpack/pkg/react-hook-form.js";
import {ScrollForm} from "../components/scroll-form/ScrollForm.js";
import {ClientDescription} from "./ClientDescription.js";
import {CapabilityConfig} from "./add/CapabilityConfig.js";
import {MultiLineInput} from "../components/multi-line-input/MultiLineInput.js";
import {FormAccess} from "../components/form-access/FormAccess.js";
import {HelpItem} from "../components/help-enabler/HelpItem.js";
import {useServerInfo} from "../context/server-info/ServerInfoProvider.js";
import {SaveReset} from "./advanced/SaveReset.js";
import {SamlConfig} from "./add/SamlConfig.js";
import {SamlSignature} from "./add/SamlSignature.js";
export const ClientSettings = ({
  client,
  save,
  reset
}) => {
  const {register, control, watch} = useFormContext();
  const {t} = useTranslation("clients");
  const [loginThemeOpen, setLoginThemeOpen] = useState(false);
  const loginThemes = useServerInfo().themes["login"];
  const consentRequired = watch("consentRequired");
  const displayOnConsentScreen = watch("attributes.display.on.consent.screen");
  const protocol = watch("protocol");
  const sections = useMemo(() => {
    let result = ["generalSettings"];
    if (protocol === "saml") {
      result = [...result, "samlCapabilityConfig", "signatureAndEncryption"];
    } else if (!client.bearerOnly) {
      result = [...result, "capabilityConfig"];
    }
    return [...result, "accessSettings", "loginSettings"];
  }, [protocol, client]);
  return /* @__PURE__ */ React.createElement(ScrollForm, {
    className: "pf-u-px-lg",
    sections: sections.map((section) => t(section))
  }, /* @__PURE__ */ React.createElement(Form, {
    isHorizontal: true
  }, /* @__PURE__ */ React.createElement(ClientDescription, null)), protocol === "saml" ? /* @__PURE__ */ React.createElement(SamlConfig, null) : !client.bearerOnly && /* @__PURE__ */ React.createElement(CapabilityConfig, null), protocol === "saml" && /* @__PURE__ */ React.createElement(SamlSignature, null), /* @__PURE__ */ React.createElement(FormAccess, {
    isHorizontal: true,
    role: "manage-clients"
  }, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("rootUrl"),
    fieldId: "kc-root-url",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:rootUrl",
      fieldLabelId: "clients:rootUrl"
    })
  }, /* @__PURE__ */ React.createElement(TextInput, {
    type: "text",
    id: "kc-root-url",
    name: "rootUrl",
    ref: register
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("validRedirectUri"),
    fieldId: "kc-redirect",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:validRedirectURIs",
      fieldLabelId: "clients:validRedirectUri"
    })
  }, /* @__PURE__ */ React.createElement(MultiLineInput, {
    name: "redirectUris",
    "aria-label": t("validRedirectUri"),
    addButtonLabel: "clients:addRedirectUri"
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("homeURL"),
    fieldId: "kc-home-url",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:homeURL",
      fieldLabelId: "clients:homeURL"
    })
  }, /* @__PURE__ */ React.createElement(TextInput, {
    type: "text",
    id: "kc-home-url",
    name: "baseUrl",
    ref: register
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("webOrigins"),
    fieldId: "kc-web-origins",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:webOrigins",
      fieldLabelId: "clients:webOrigins"
    })
  }, /* @__PURE__ */ React.createElement(MultiLineInput, {
    name: "webOrigins",
    "aria-label": t("webOrigins"),
    addButtonLabel: "clients:addWebOrigins"
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("adminURL"),
    fieldId: "kc-admin-url",
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:adminURL",
      fieldLabelId: "clients:adminURL"
    })
  }, /* @__PURE__ */ React.createElement(TextInput, {
    type: "text",
    id: "kc-admin-url",
    name: "adminUrl",
    ref: register
  }))), /* @__PURE__ */ React.createElement(FormAccess, {
    isHorizontal: true,
    role: "manage-clients"
  }, /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("loginTheme"),
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:loginTheme",
      fieldLabelId: "clients:loginTheme"
    }),
    fieldId: "loginTheme"
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "attributes.login_theme",
    defaultValue: "",
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: "loginTheme",
      onToggle: setLoginThemeOpen,
      onSelect: (_, value2) => {
        onChange(value2.toString());
        setLoginThemeOpen(false);
      },
      selections: value || t("common:choose"),
      variant: SelectVariant.single,
      "aria-label": t("loginTheme"),
      isOpen: loginThemeOpen
    }, /* @__PURE__ */ React.createElement(SelectOption, {
      key: "empty",
      value: ""
    }, t("common:choose")), /* @__PURE__ */ React.createElement(React.Fragment, null, loginThemes.map((theme) => /* @__PURE__ */ React.createElement(SelectOption, {
      selected: theme.name === value,
      key: theme.name,
      value: theme.name
    }))))
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("consentRequired"),
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:consentRequired",
      fieldLabelId: "clients:consentRequired"
    }),
    fieldId: "kc-consent",
    hasNoPaddingTop: true
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "consentRequired",
    defaultValue: false,
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "kc-consent-switch",
      label: t("common:on"),
      labelOff: t("common:off"),
      isChecked: value,
      onChange
    })
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("displayOnClient"),
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:displayOnClient",
      fieldLabelId: "clients:displayOnClient"
    }),
    fieldId: "kc-display-on-client",
    hasNoPaddingTop: true
  }, /* @__PURE__ */ React.createElement(Controller, {
    name: "attributes.display.on.consent.screen",
    defaultValue: false,
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Switch, {
      id: "kc-display-on-client-switch",
      label: t("common:on"),
      labelOff: t("common:off"),
      isChecked: value === "true",
      onChange: (value2) => onChange("" + value2),
      isDisabled: !consentRequired
    })
  })), /* @__PURE__ */ React.createElement(FormGroup, {
    label: t("consentScreenText"),
    labelIcon: /* @__PURE__ */ React.createElement(HelpItem, {
      helpText: "clients-help:consentScreenText",
      fieldLabelId: "clients:consentScreenText"
    }),
    fieldId: "kc-consent-screen-text"
  }, /* @__PURE__ */ React.createElement(TextArea, {
    id: "kc-consent-screen-text",
    name: "attributes.consent.screen.text",
    ref: register,
    isDisabled: !(consentRequired && displayOnConsentScreen === "true")
  })), /* @__PURE__ */ React.createElement(SaveReset, {
    className: "keycloak__form_actions",
    name: "settings",
    save,
    reset
  })));
};
