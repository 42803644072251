import {lazy} from "../../_snowpack/pkg/react.js";
import {generatePath} from "../../_snowpack/pkg/react-router-dom.js";
export const JavaKeystoreSettingsRoute = {
  path: "/:realm/realm-settings/keys/providers/:id/java-keystore/settings",
  component: lazy(() => import("../key-providers/java-keystore/JavaKeystoreForm.js")),
  breadcrumb: (t) => t("realm-settings:editProvider"),
  access: "view-realm"
};
export const toJavaKeystoreSettings = (params) => ({
  pathname: generatePath(JavaKeystoreSettingsRoute.path, params)
});
