import React, {useState} from "../../_snowpack/pkg/react.js";
import {useTranslation} from "../../_snowpack/pkg/react-i18next.js";
import {Controller, useFormContext} from "../../_snowpack/pkg/react-hook-form.js";
import {Select, SelectOption, SelectVariant} from "../../_snowpack/pkg/@patternfly/react-core.js";
import {useAdminClient, useFetch} from "../../context/auth/AdminClient.js";
export const ResourcesPolicySelect = ({
  name,
  searchFunction,
  clientId
}) => {
  const {t} = useTranslation("clients");
  const adminClient = useAdminClient();
  const {control} = useFormContext();
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  useFetch(async () => (await adminClient.clients[searchFunction](Object.assign({id: clientId, first: 0, max: 10}, search === "" ? null : {name: search}))).map((p) => ({
    id: "_id" in p ? p._id : "id" in p ? p.id : void 0,
    name: p.name
  })), (policies) => setItems(policies.map((p) => /* @__PURE__ */ React.createElement(SelectOption, {
    key: p.id,
    value: p.id
  }, p.name))), [search]);
  return /* @__PURE__ */ React.createElement(Controller, {
    name,
    defaultValue: [],
    control,
    render: ({onChange, value}) => /* @__PURE__ */ React.createElement(Select, {
      toggleId: name,
      variant: SelectVariant.typeaheadMulti,
      onToggle: setOpen,
      onFilter: (_, filter) => {
        setSearch(filter);
        return items;
      },
      onClear: () => {
        onChange([]);
        setSearch("");
      },
      selections: value,
      onSelect: (_, selectedValue) => {
        const option = selectedValue.toString();
        const changedValue = value.find((p) => p === option) ? value.filter((p) => p !== option) : [...value, option];
        onChange(changedValue);
        setSearch("");
      },
      isOpen: open,
      "aria-labelledby": t("policies")
    }, items)
  });
};
